import {
  Form,
  Input,
  InputNumber,
  Modal,
  Spin,
  Tag,
  Image,
  Row,
  Col,
} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OrderData } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import { getDataWithAuthToken } from '../../../utils/axiosRequest';
import { FALLBACK_IMG } from '../../../constants/styles';

type OrderShippingInfoModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  orderInfo?: OrderData;
};

type DriverData = {
  id: number;
  orderSn: string;
  routeId: number;
  arrival: number;
  distance: string;
  driverRemark?: string;
  countSequence: number;
  sequenceIndex: number;
  sequenceLabel: string;
  etc?: number;
  etcEndTime?: string;
  etcStartTime?: string;
  createdAt?: string;
  updateAt?: string;
  deliveryAt?: string;
  completeAt?: string;
  status: {
    status: number;
    description: string;
  };
  route: {
    driver?: {
      driverName: string;
      phone: string;
    };
    name: string;
    routeName: string;
    shippingDate: string;
    shortName: string;
    startAt?: string;
  };

  orderImages?: {
    id: number;
    imgUrl: string;
    orderId: number;
  }[];
};

const OrderShippingInfoModal = ({
  visible,
  setVisible,
  orderInfo,
}: OrderShippingInfoModalProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [driverOrderData, setDriverOrderData] = useState<DriverData>();

  const getDriverInfo = useCallback(() => {
    setIsLoading(true);
    getDataWithAuthToken('order/driver', {
      params: { orderSn: orderInfo && orderInfo.orderSn },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          const orderData: DriverData = response.data;
          setDriverOrderData(orderData);
          form.setFieldsValue({
            ...orderData,
            status: orderData.status ? orderData.status.description : '',
            routeName: orderData.route ? orderData.route.routeName : '',
            shippingDate: orderData.route ? orderData.route.shippingDate : '',
            startAt: orderData.route ? orderData.route.startAt : '',
            driverName:
              orderData.route && orderData.route.driver
                ? orderData.route.driver.driverName
                : '',
            driverPhone:
              orderData.route && orderData.route.driver
                ? orderData.route.driver.phone
                : '',
          });
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, [t, orderInfo, form]);

  useEffect(() => {
    if (visible) {
      form.resetFields();
      getDriverInfo();
    }
  }, [visible, getDriverInfo, form]);

  const onClose = () => {
    setVisible(false);
  };

  return (
    <Modal
      title={t('order.orderDetail.shippingInfo.title')}
      width={860}
      visible={visible}
      onCancel={onClose}
      footer={null}
    >
      <Spin spinning={isLoading}>
        <Row>
          <Col span={12}>
            <Form form={form} labelCol={{ span: 11 }}>
              <Form.Item
                name="status"
                label={t('order.orderDetail.shippingInfo.status')}
                style={{ marginBottom: 0 }}
              >
                <Input bordered={false} readOnly />
              </Form.Item>
              <Form.Item
                name="etc"
                label={t('order.orderDetail.shippingInfo.etc')}
                style={{ marginBottom: 0 }}
              >
                <InputNumber
                  bordered={false}
                  readOnly
                  formatter={(value) => `${value} ${value ? 'min' : ''}`}
                />
              </Form.Item>
              <Form.Item
                name="etcStartTime"
                label={t('order.orderDetail.shippingInfo.etcStartTime')}
                style={{ marginBottom: 0 }}
              >
                <Input bordered={false} readOnly />
              </Form.Item>
              <Form.Item
                name="etcEndTime"
                label={t('order.orderDetail.shippingInfo.etcEndTime')}
                style={{ marginBottom: 0 }}
              >
                <Input bordered={false} readOnly />
              </Form.Item>
              <Form.Item
                name="distance"
                label={t('order.orderDetail.shippingInfo.distance')}
                style={{ marginBottom: 0 }}
              >
                <InputNumber
                  bordered={false}
                  readOnly
                  formatter={(value) => `${value} ${value ? 'km' : ''}`}
                />
              </Form.Item>
              <Form.Item
                name="arrival"
                label={t('order.orderDetail.shippingInfo.arrival')}
                style={{ marginBottom: 0 }}
              >
                <InputNumber
                  bordered={false}
                  readOnly
                  formatter={(value) => `${value} ${value ? 'min' : ''}`}
                />
              </Form.Item>
              <Form.Item
                name="sequenceIndex"
                label={t('order.orderDetail.shippingInfo.sequenceIndex')}
                style={{ marginBottom: 0 }}
              >
                <Input bordered={false} readOnly />
              </Form.Item>
              <Form.Item
                name="sequenceLabel"
                label={t('order.orderDetail.shippingInfo.sequenceLabel')}
                style={{ marginBottom: 0 }}
              >
                <Tag color="#800020" style={{ marginLeft: 11 }}>
                  {visible && form.getFieldValue('sequenceLabel')}
                </Tag>
              </Form.Item>
              <Form.Item
                name="countSequence"
                label={t('order.orderDetail.shippingInfo.countSequence')}
                style={{ marginBottom: 0 }}
              >
                <Input bordered={false} readOnly />
              </Form.Item>
              <Form.Item
                name="routeName"
                label={t('order.orderDetail.shippingInfo.routeName')}
                style={{ marginBottom: 0 }}
              >
                <Input bordered={false} readOnly />
              </Form.Item>
              <Form.Item
                name="shippingDate"
                label={t('order.orderDetail.shippingInfo.shippingDate')}
                style={{ marginBottom: 0 }}
              >
                <Input bordered={false} readOnly />
              </Form.Item>
              <Form.Item
                name="startAt"
                label={t('order.orderDetail.shippingInfo.startAt')}
                style={{ marginBottom: 0 }}
              >
                <Input bordered={false} readOnly />
              </Form.Item>
              <Form.Item
                name="driverName"
                label={t('order.orderDetail.shippingInfo.driverName')}
                style={{ marginBottom: 0 }}
              >
                <Input bordered={false} readOnly />
              </Form.Item>
              <Form.Item
                name="driverPhone"
                label={t('order.orderDetail.shippingInfo.driverPhone')}
                style={{ marginBottom: 0 }}
              >
                <Input bordered={false} readOnly />
              </Form.Item>
              <Form.Item
                name="driverRemark"
                label={t('order.orderDetail.shippingInfo.driverRemark')}
                style={{ marginBottom: 0 }}
              >
                <Input bordered={false} readOnly />
              </Form.Item>
              <Form.Item
                name="createdAt"
                label={t('order.orderDetail.shippingInfo.createdAt')}
                style={{ marginBottom: 0 }}
              >
                <Input bordered={false} readOnly />
              </Form.Item>
              <Form.Item
                name="deliveryAt"
                label={t('order.orderDetail.shippingInfo.deliveryAt')}
                style={{ marginBottom: 0 }}
              >
                <Input bordered={false} readOnly />
              </Form.Item>
              <Form.Item
                name="completeAt"
                label={t('order.orderDetail.shippingInfo.completeAt')}
                style={{ marginBottom: 0 }}
              >
                <Input bordered={false} readOnly />
              </Form.Item>
              <Form.Item
                name="updateAt"
                label={t('order.orderDetail.shippingInfo.updateAt')}
                style={{ marginBottom: 0 }}
              >
                <Input bordered={false} readOnly />
              </Form.Item>
            </Form>
          </Col>
          <Col span={12}>
          {driverOrderData?.orderImages &&
            driverOrderData?.orderImages.length > 0 && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
              <Image.PreviewGroup>
                {driverOrderData.orderImages.map((image) => (
                  <Image
                    src={image.imgUrl}
                    fallback={FALLBACK_IMG}
                    style={{ maxWidth: 100, margin: 10 }}
                  />
                ))}
              </Image.PreviewGroup>
              </div>
            )}
          </Col>
        </Row>
      </Spin>
    </Modal>
  );
};

export default OrderShippingInfoModal;
