import { EditOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Empty,
  Form,
  Row,
  Select,
  Space,
  Spin,
  Tag,
  Typography,
} from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { actionPermissions } from '../../../constants/actionPermissions';
import { PRIMARY, RED1 } from '../../../constants/color';
import { SHIPPING_CODE } from '../../../constants/orderConstants';
import { dashboardRoute } from '../../../constants/pathname';
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
} from '../../../constants/systemConstants';
import { useTab } from '../../../hooks/useTab';
import {
  OrderData,
  OrderEnum,
  PaymentMethod,
  ShippingData,
} from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../../utils/axiosRequest';
import getDashboardStyle from '../../../utils/getDashboardStyle';
import { hasPermission } from '../../../utils/hasPermission';
import OrderShippingInfoModal from './OrderShippingInfoModal';

type OrderBasicInfoProps = {
  orderInfo?: OrderData;
  callBack?: () => void;
  enums?: OrderEnum;
  isCurrentEditing?: boolean;
  setIsCurrentEditing?: React.Dispatch<React.SetStateAction<boolean>>;
};

const OrderBasicInfo = ({
  orderInfo,
  callBack,
  enums,
  isCurrentEditing,
  setIsCurrentEditing,
}: OrderBasicInfoProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { addTab } = useTab();
  const [editing, setEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [shippingList, setShippingList] = useState<ShippingData[]>([]);
  const [shippingCode, setShippingCode] = useState('');
  const [shippingInfoModalVisible, setShippingInfModalVisible] =
    useState(false);

  useEffect(() => {
    if (
      orderInfo &&
      orderInfo.orderShipping &&
      orderInfo.orderShipping.shippingCode
    ) {
      setShippingCode(orderInfo.orderShipping.shippingCode);
    }
  }, [orderInfo]);

  /** Switch ShippingCode with default expectedShippingTime */
  useEffect(() => {
    if (shippingCode !== '') {
      // 门店自提 默认 无自取时间
      if (shippingCode === SHIPPING_CODE['cac']) {
        if (orderInfo?.orderShipping.shippingCode !== SHIPPING_CODE['cac']) {
          form.setFieldsValue({
            expectShippingTime: undefined,
          });
        }
      } else {
        form.setFieldsValue({
          expectShippingTime: moment(
            orderInfo?.orderShipping.expectShippingTime
          ),
        });
      }
    }
  }, [shippingCode, orderInfo, form]);

  const handleOnEdit = () => {
    if (editing) {
      form
        .validateFields()
        .then((values) => {
          setIsLoading(true);
          postDataWithAuthToken('order/edit', {
            orderId: orderInfo?.orderId,
            shippingId: values.shipping.value,
            shippingName: values.shipping.label,
            shippingCode: values.shipping.key,
            payId: values.payment.value,
            payName: values.payment.label,
            addTime: getDashboardStyle().isWholeSaleAppType
              ? values.addTime
                ? values.addTime.format(DATE_TIME_FORMAT)
                : undefined
              : undefined,
            expectShippingTimeInterval:
              values.expectShippingTimeInterval || undefined,
            expectShippingTime: values.expectShippingTime
              ? values.expectShippingTime.format(DATE_FORMAT)
              : '',
          })
            .then((response) => {
              if (response && response.goodStatus) {
                alertMessage('success', t('order.alerts.orderEdited'));
                handleOnCancel();
                if (callBack) callBack();
              } else {
                alertMessage(
                  'error',
                  response?.msg || t('general.noResponse'),
                  response?.data || undefined
                );
              }
              setIsLoading(false);
            })
            .catch((err) => {
              setIsLoading(false);
              console.log(err);
            });
        })
        .catch((err) => console.log(err));
    } else {
      if (isCurrentEditing) {
        alertMessage('warning', t('order.alerts.saveWarning'));
      } else {
        setEditing(true);
        if (setIsCurrentEditing) setIsCurrentEditing(true);
      }
    }
  };

  const handleOnCancel = () => {
    form.resetFields();
    if (
      orderInfo &&
      orderInfo.orderShipping &&
      orderInfo.orderShipping.shippingCode
    ) {
      setShippingCode(orderInfo.orderShipping.shippingCode);
    }
    setEditing(false);
    if (setIsCurrentEditing) setIsCurrentEditing(false);
  };

  const getPaymentMethodList = () => {
    getDataWithAuthToken('payment/enabled_list', {
      params: { isSeller: orderInfo?.seller.sellerId },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          setPaymentMethods(response.data.list);
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getShippingList = () => {
    getDataWithAuthToken('shipping/list')
      .then((response) => {
        if (response && response.goodStatus) {
          setShippingList(response.data.list);
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return orderInfo ? (
    <Spin spinning={isLoading}>
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          shipping: {
            label: orderInfo.orderShipping
              ? orderInfo.orderShipping.shippingName
              : undefined,
            value: orderInfo.orderShipping
              ? orderInfo.orderShipping.shippingId
              : undefined,
          },
          payment: {
            label: orderInfo.payName,
            value: orderInfo.payId,
          },
          addTime: moment(orderInfo.addTime),
          expectShippingTime: orderInfo.orderShipping
            ? orderInfo.orderShipping.shippingCode === SHIPPING_CODE['flat']
              ? moment(orderInfo.orderShipping.expectShippingTime)
              : orderInfo.orderShipping.expectShippingTime
              ? moment(orderInfo.orderShipping.expectShippingTime)
              : undefined
            : undefined,
          expectShippingTimeInterval:
            orderInfo.orderShipping &&
            orderInfo.orderShipping.expectShippingTimeInterval
              ? orderInfo.orderShipping.expectShippingTimeInterval.code
              : undefined,
        }}
      >
        <Row gutter={[16, 24]}>
          <Col
            span={24}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography.Text strong style={{ fontSize: 16 }}>
              {t('order.orderDetail.basicInfo')}
            </Typography.Text>
            {editing ? (
              <Space>
                <Button onClick={handleOnCancel}>
                  {t('order.orderDetail.cancel')}
                </Button>
                <Button
                  disabled={
                    !hasPermission(actionPermissions.orderGroup.orderManage)
                  }
                  type="primary"
                  onClick={handleOnEdit}
                >
                  {t('order.orderDetail.ok')}
                </Button>
              </Space>
            ) : (
              <Button
                disabled={
                  !hasPermission(actionPermissions.orderGroup.orderManage)
                }
                type="text"
                icon={<EditOutlined />}
                onClick={handleOnEdit}
              />
            )}
          </Col>
          <Col span={24} md={12} lg={8} xl={6}>
            <Form.Item
              label={`${t('order.orderDetail.orderSn')}: `}
              style={{ marginBottom: 0 }}
            >
              <Space style={{ flexDirection: 'column' }}>
                <Typography.Text>{orderInfo.orderSn}</Typography.Text>
                {orderInfo.extension && (
                  <Tag color={orderInfo.extension.tagColor}>
                    {orderInfo.extension.description}
                  </Tag>
                )}
              </Space>
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8} xl={6}>
            <Form.Item
              label={`${t('order.orderDetail.invoiceSn')}: `}
              style={{ marginBottom: 0 }}
            >
              <Space style={{ flexDirection: 'column' }}>
                <Typography.Text>{orderInfo.invoiceSn}</Typography.Text>
              </Space>
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8} xl={6}>
            <Form.Item
              label={`${t('order.orderDetail.user')}: `}
              style={{ marginBottom: 0 }}
            >
              <Space style={{ flexDirection: 'column' }}>
                <Button
                  type="link"
                  style={{ padding: 0 }}
                  disabled={
                    !hasPermission(actionPermissions.userGroup.userView)
                  }
                  onClick={() =>
                    addTab(
                      '',
                      `${dashboardRoute.users.detail}?user_id=${
                        orderInfo.user ? orderInfo.user.userId : ''
                      }`
                    )
                  }
                >
                  {orderInfo.user ? orderInfo.user.userName : ''}
                </Button>
                {(orderInfo.user?.userRank || orderInfo.user?.member) && (
                  <Space>
                    {orderInfo.user?.userRank && (
                      <Tag color="geekblue">
                        {orderInfo.user?.userRank.rankName}
                      </Tag>
                    )}
                    {orderInfo.user?.member && (
                      <Tag color="red">{orderInfo.user?.member.rankName}</Tag>
                    )}
                  </Space>
                )}
              </Space>
            </Form.Item>
          </Col>
         
          <Col span={24} md={12} lg={8} xl={6}>
            <Form.Item
              label={`${t('order.orderDetail.orderStatus')}: `}
              style={{ marginBottom: 0 }}
            >
              <div>
                {orderInfo.orderStatus && (
                  <Tag color={orderInfo.orderStatus.tagColor}>
                    {orderInfo.orderStatus.description}
                  </Tag>
                )}
                <Divider type="vertical" />
                <Typography.Text>
                  {orderInfo.payStatus && orderInfo.payStatus.description}
                </Typography.Text>
                <Divider type="vertical" />
                <Typography.Text>
                  {orderInfo.shippingStatus &&
                    orderInfo.shippingStatus.description}
                </Typography.Text>
              </div>
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8} xl={6}>
            <Form.Item
              label={`${t('order.orderDetail.payName')}: `}
              name={editing ? 'payment' : undefined}
              rules={[{ required: true }]}
              style={{ marginBottom: 0 }}
            >
              {editing ? (
                <Select
                  labelInValue
                  onFocus={() => {
                    if (!paymentMethods.length) getPaymentMethodList();
                  }}
                >
                  {paymentMethods.map((payment) => (
                    <Select.Option key={payment.payName} value={payment.payId}>
                      {payment.payName}
                    </Select.Option>
                  ))}
                </Select>
              ) : (
                <Typography.Text>{orderInfo.payName}</Typography.Text>
              )}
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8} xl={6}>
            <Form.Item
              label={`${t('order.orderDetail.addTime')}: `}
              style={{ marginBottom: 0 }}
              name={
                editing && getDashboardStyle().isWholeSaleAppType
                  ? 'addTime'
                  : undefined
              }
            >
              {/***只有批发才能修改下单时间 */}
              {editing && getDashboardStyle().isWholeSaleAppType ? (
                <DatePicker
                  showTime
                  allowClear={false}
                  onChange={(value) => {}}
                />
              ) : (
                <Typography.Text>{orderInfo.addTime}</Typography.Text>
              )}
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8} xl={6}>
            <Form.Item
              label={`${t('order.orderDetail.payTime')}: `}
              style={{ marginBottom: 0 }}
            >
              <Typography.Text>{orderInfo.payTime}</Typography.Text>
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8} xl={6}>
            <Form.Item
              label={`${t('order.orderDetail.printTime')}: `}
              style={{ marginBottom: 0 }}
            >
              <Typography.Text>
                {orderInfo.printTime ? orderInfo.printTime : ''}
              </Typography.Text>
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8} xl={6}>
            <Form.Item
              label={`${t('order.orderDetail.shippingTime')}: `}
              style={{ marginBottom: 0 }}
            >
              <Typography.Text>{orderInfo.shippingTime}</Typography.Text>
            </Form.Item>
          </Col>

          <Col span={24} md={12} lg={8} xl={6}>
            <Form.Item
              label={`${t('order.orderDetail.shippingName')}: `}
              style={{ marginBottom: 0 }}
              name={editing ? 'shipping' : undefined}
              rules={[{ required: true }]}
            >
              {editing ? (
                <Select
                  labelInValue
                  onSelect={(value: number, option: any) => {
                    setShippingCode(option.key);
                  }}
                  onFocus={() => {
                    if (!shippingList.length) getShippingList();
                  }}
                >
                  {shippingList.map((shipping) => (
                    <Select.Option
                      key={shipping.shippingCode}
                      value={shipping.shippingId}
                    >
                      {shipping.shippingName}
                    </Select.Option>
                  ))}
                </Select>
              ) : (
                <Typography.Text>
                  {orderInfo.orderShipping
                    ? orderInfo.orderShipping.shippingName
                    : ''}
                </Typography.Text>
              )}
            </Form.Item>
          </Col>

          <Col span={24} md={12} lg={8} xl={6}>
            <Space align="end" wrap>
              <Form.Item
                label={
                  shippingCode === SHIPPING_CODE['flat']
                    ? `${t('order.orderDetail.expectedShippingTime')}: `
                    : `${t('order.orderDetail.expectedPickupTime')}: `
                }
                style={{ marginBottom: 0 }}
                name={editing ? 'expectShippingTime' : undefined}
              >
                {editing ? (
                  <DatePicker
                    onChange={(value) => {
                      if (!value)
                        form.setFieldsValue({
                          expectShippingTimeInterval: 0,
                        });
                    }}
                  />
                ) : (
                  <Typography.Text>
                    {orderInfo.orderShipping
                      ? orderInfo.orderShipping.expectShippingTimeName
                      : ''}
                  </Typography.Text>
                )}
              </Form.Item>
              {editing && shippingCode === SHIPPING_CODE['flat'] && (
                <Form.Item
                  style={{ marginBottom: 0, minWidth: 110 }}
                  name="expectShippingTimeInterval"
                >
                  <Select>
                    <Select.Option key={0} value={0}>
                      {t('general.pleaseSelect')}
                    </Select.Option>
                    {enums &&
                      enums.timeInterval.map((interval) => (
                        <Select.Option
                          value={interval.code}
                          key={interval.code}
                        >
                          {interval.description}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              )}
            </Space>
          </Col>
          {!getDashboardStyle().isSalesForceAppType && (
            <Col span={24} md={12} lg={8} xl={6}>
              <Form.Item
                label={`${t('order.orderDetail.taskInfo')}: `}
                style={{ marginBottom: 0 }}
              >
                <Button
                  type="link"
                  style={{ padding: 0 }}
                  disabled={
                    !hasPermission(actionPermissions.sortationGroup.taskView)
                  }
                  onClick={() =>
                    addTab(
                      '',
                      `${dashboardRoute.sortationSystem.taskOrder}?order_id=${orderInfo.orderId}`
                    )
                  }
                >
                  {t('actionsColumn.view')}
                </Button>
              </Form.Item>
            </Col>
          )}
          <Col span={24} md={12} lg={8} xl={6}>
            <Form.Item
              label={`${t('order.orderDetail.shippingInfo.title')}: `}
              style={{ marginBottom: 0 }}
            >
              <Button
                type="link"
                style={{ padding: 0 }}
                disabled={
                  !hasPermission(actionPermissions.orderGroup.orderView)
                }
                onClick={() => setShippingInfModalVisible(true)}
              >
                {t('actionsColumn.view')}
              </Button>
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8} xl={6}>
            <Form.Item
              label={`${t('order.orderDetail.confirmDeliveredTime')}: `}
              style={{ marginBottom: 0 }}
            >
              <Typography.Text>
                {orderInfo.confirmDeliveredTime}
              </Typography.Text>
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8} xl={6}>
            <Form.Item
              label={`${t('order.orderListColumns.seller')}: `}
              style={{ marginBottom: 0 }}
            >
              <Typography.Text style={{ color: PRIMARY }}>
                {orderInfo.seller ? orderInfo.seller.shopName : ''}
              </Typography.Text>
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8} xl={6}>
            <Form.Item
              label={`${t('order.orderDetail.orderFrom')}: `}
              style={{ marginBottom: 0 }}
            >
              <Typography.Text>
                {orderInfo.orderExtend && orderInfo.orderExtend.platform
                  ? orderInfo.orderExtend.platform.description
                  : ''}
                <Divider type="vertical" />
                {orderInfo.orderExtend ? orderInfo.orderExtend.ipAddress : ''}
              </Typography.Text>
            </Form.Item>
          </Col>
          {orderInfo.userUnpaidOrders && (
            <Col span={24} md={12} lg={8} xl={12}>
              <Form.Item
                label={`${t('order.orderDetail.unpaidMessage')}: `}
                style={{ marginBottom: 0 }}
              >
                <Typography.Text strong style={{ color: RED1, fontSize: 18 }}>
                  {orderInfo.userUnpaidOrders}
                </Typography.Text>
              </Form.Item>
            </Col>
          )}
        </Row>
      </Form>
      <OrderShippingInfoModal
        visible={shippingInfoModalVisible}
        setVisible={setShippingInfModalVisible}
        orderInfo={orderInfo}
      />
    </Spin>
  ) : (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <Typography.Text strong style={{ fontSize: 16 }}>
          {t('order.orderDetail.basicInfo')}
        </Typography.Text>
      </Col>
      <Col span={24}>
        <Empty />
      </Col>
    </Row>
  );
};

export default OrderBasicInfo;
