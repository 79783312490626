import { Col, Input, Row, Typography } from 'antd';
import { BLACK, GRAY4 } from '../../../../constants/color';
import {
  DATE_FORMAT,
  DEFAULT_FONT_SIZE,
} from '../../../../constants/systemConstants';
import { useTranslation } from 'react-i18next';
import { OrderClaimPrintoutData } from '../../../../types';
import { useEffect, useState } from 'react';
import moment from 'moment';

type OrderClaimPrintoutBodyProps = {
  claim: OrderClaimPrintoutData;
};

const OrderClaimPrintoutBody = ({ claim }: OrderClaimPrintoutBodyProps) => {
  const { t } = useTranslation();
  const [field, setField] = useState<{ [key: string]: string }>({});
  const [isEditing, setIsEditing] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    const { billName, billAddress, billPhone } = claim;
    setField({ billName, billAddress, billPhone });
  }, [claim]);

  return (
    <div style={{ flexDirection: 'row' }}>
      <Row
        style={{
          marginTop: 20,
          justifyContent: 'space-between',
        }}
      >
        <Col /**Bill To */
          span={7}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <div
            style={{
              marginTop: 5,
              marginBottom: 5,
              height: 2,
              backgroundColor: GRAY4,
            }}
          />
          <Typography.Paragraph
            strong
            style={{
              fontSize: DEFAULT_FONT_SIZE,
              color: BLACK,
            }}
          >
            {t('order.printoutComponents.billTo')}
          </Typography.Paragraph>
          {field &&
            Object.keys(field).length > 0 &&
            Object.keys(field).map((key) => (
              <div key={key}>
                {isEditing[key] ? (
                  <Input
                    autoFocus
                    defaultValue={field[key]}
                    onChange={(e) =>
                      setField((prev) => ({
                        ...prev,
                        [key]:
                          e.target.value ||
                          claim[key as keyof typeof OrderClaimPrintoutBody],
                      }))
                    }
                    onBlur={() => setIsEditing({ [key]: false })}
                  />
                ) : (
                  <Typography.Text
                    style={{ fontSize: DEFAULT_FONT_SIZE - 2, color: BLACK }}
                    onClick={() => setIsEditing({ [key]: true })}
                  >
                    {field[key]}
                  </Typography.Text>
                )}
              </div>
            ))}
        </Col>
        <Col /**Details */
          span={7}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <div
            style={{
              marginTop: 5,
              marginBottom: 5,
              height: 2,
              backgroundColor: GRAY4,
            }}
          />
          <Typography.Paragraph
            strong
            style={{
              fontSize: DEFAULT_FONT_SIZE,
              color: BLACK,
            }}
          >
            {t('order.printoutComponents.details')}
          </Typography.Paragraph>
          <Typography.Text
            style={{ fontSize: DEFAULT_FONT_SIZE - 2, color: BLACK }}
          >
            {`${t('order.printoutColumns.invoiceId')} : ${
              claim.orderInfo.invoiceSn
            }`}
          </Typography.Text>

          <Typography.Text style={{ fontSize: DEFAULT_FONT_SIZE - 2 }}>
            {`${t('order.claimListColumns.orderSn')} : ${
              claim.orderInfo.orderSn
            }`}
          </Typography.Text>
          {claim.orderInfo.orderShipping.expectShippingTime && (
            <Typography.Text style={{ fontSize: DEFAULT_FONT_SIZE - 2 }}>
              {`${t('order.print.expectShippingTimeName')} : ${moment(
                claim.orderInfo.orderShipping.expectShippingTime
              ).format(DATE_FORMAT)}`}
            </Typography.Text>
          )}

          <Typography.Text style={{ fontSize: DEFAULT_FONT_SIZE - 2 }}>
            {`${t('order.claimListColumns.claimSn')} : ${claim.claimSn}`}
          </Typography.Text>

          <Typography.Text style={{ fontSize: DEFAULT_FONT_SIZE - 2 }}>
            {`${t('order.claimListColumns.claimType')} : ${
              claim.claimType.description
            }`}
          </Typography.Text>
        </Col>
        <Col /**More Detail */
          span={7}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <div
            style={{
              marginTop: 5,
              marginBottom: 5,
              height: 2,
              backgroundColor: GRAY4,
            }}
          />
          <Typography.Paragraph
            strong
            style={{
              fontSize: DEFAULT_FONT_SIZE,
              color: BLACK,
            }}
          >
            {t('order.printoutComponents.more')}
          </Typography.Paragraph>
          <Typography.Text
            style={{ fontSize: DEFAULT_FONT_SIZE - 2, color: BLACK }}
          >
            {`${t('order.claimListColumns.reviewStatus')}: ${
              claim.reviewStatus.description
            }`}
          </Typography.Text>

          <Typography.Text
            style={{ fontSize: DEFAULT_FONT_SIZE - 2, color: BLACK }}
          >
            {`${t('order.claimListColumns.refundMethod')}: ${
              claim.refundMethod.description
            }`}
          </Typography.Text>
          {claim.isUsed && (
            <Typography.Text
              style={{ fontSize: DEFAULT_FONT_SIZE - 2, color: BLACK }}
            >
              {`${t('order.claimListColumns.isUsedStatus')}: ${t('order.claimListColumns.isUsed')}`}
            </Typography.Text>
          )}
          {claim.remark && (
            <Typography.Text
              style={{ fontSize: DEFAULT_FONT_SIZE - 2, color: BLACK }}
            >
              {`${t('order.claimListColumns.remark')}: ${claim.remark}`}
            </Typography.Text>
          )}
          {/* 售后原因只在没有商品表格的时候出现 */}
          {claim.claimReason && !claim.claimGoodsList && (
            <Typography.Text
              style={{ fontSize: DEFAULT_FONT_SIZE - 2, color: BLACK }}
            >
              {`${t('order.claimListColumns.claimReason')}: ${
                claim.claimReason
              }`}
            </Typography.Text>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default OrderClaimPrintoutBody;
