import { UpOutlined } from '@ant-design/icons';
import { Button, Grid, Popover, Space, Typography, Form } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../app/hooks';
import { OrderEnum } from '../../types';
import { onExportToExcel } from './TableExport';
import BatchGoodsTableFooterToolbar from '../goods/BatchGoodsTableFooterToolbar';
import BatchOrderTableFooterToolbar from '../order/BatchOrderTableFooterToolbar';
import MismatchGoodsTableFooterToolbar from '../goods/MismatchTableFooterToolbar';
import { WHITE } from '../../constants/color';
import BatchTaskTableFooterToolbar from '../sortationSystem/BatchTaskTableFooterToolbar';
import BatchClaimTableFooterToolbar from '../order/BatchClaimTableFooterToolbar';

type TableFooterToolbarProps = {
  orderInfo?: {
    userId?: string;
    actionList?: string[];
    routeId?: number | undefined;
    orderEnumList?: OrderEnum;
  };
  userInfo?: {};
  goodInfo?: { navType?: string };
  funct: {
    refresh?: Function;
    deleteFunc?: () => void;
    exportConfig?: { fileName: string; url?: string };
  };
  general?: {
    advance?: any;
    setAdvance?: React.Dispatch<React.SetStateAction<any>>;
  };
  selectedRows: any[];
  selectedRowKeys?: any[];
  setSelectedRowKeys?: React.Dispatch<React.SetStateAction<React.Key[]>>;
  columns?: ColumnsType<any>;
  typeSpecificActions?:
    | 'goods'
    | 'order'
    | 'orderClaim'
    | 'goodsSync'
    | 'relatedGoods'
    | 'users'
    | 'task';
};

const TableFooterToolbar = ({
  selectedRowKeys,
  selectedRows,
  setSelectedRowKeys,
  columns,
  typeSpecificActions,
  orderInfo,
  goodInfo,
  userInfo,
  general,
  funct,
}: TableFooterToolbarProps) => {
  //General Components
  const { t } = useTranslation();
  const collapsed = useAppSelector((state) => state.appConfig.sidebarCollapsed);
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [form] = Form.useForm();
  const [modalLoading, setModalLoading] = useState(false);
  //Visible/Statuses Components
  const [deleteFunctVisible, setDeleteFunctVisible] = useState(false);

  const menu = (
    <Form form={form} layout="vertical">
      {general && general.setAdvance && general.advance ? ( //Pass in Advance and setAdvance if it is BATCh
        (typeSpecificActions === 'goods' ||
          typeSpecificActions === 'relatedGoods') &&
        goodInfo ? (
          <BatchGoodsTableFooterToolbar
            mode={typeSpecificActions}
            form={form}
            goodInfo={goodInfo}
            general={general}
            selectedRows={selectedRows}
            columns={columns}
            funct={funct}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
          />
        ) : typeSpecificActions === 'order' && orderInfo ? (
          <BatchOrderTableFooterToolbar
            orderInfo={orderInfo}
            form={form}
            columns={columns}
            general={{
              advance: general.advance,
              setAdvance: general.setAdvance,
              modalLoading: modalLoading,
              setModalLoading: setModalLoading,
            }}
            selectedRows={selectedRows}
            funct={funct}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
          />
        ) : typeSpecificActions === 'goodsSync' ? (
          <MismatchGoodsTableFooterToolbar
            columns={columns}
            selectedRows={selectedRows}
            funct={funct}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
            form={form}
          />
        ) : typeSpecificActions === 'task' ? (
          <BatchTaskTableFooterToolbar
            form={form}
            columns={columns}
            selectedRows={selectedRows}
            funct={funct}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
          />
        ) :  typeSpecificActions === 'orderClaim' ? (
          <BatchClaimTableFooterToolbar
            form={form}
            columns={columns}
            selectedRows={selectedRows}
            funct={funct}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
          />
        ) : (
          <></>
        )
      ) : (
        //Standard selections:export and delete funct
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {selectedRows && columns && funct.exportConfig && (
            <Button
              type="text"
              onClick={() =>
                onExportToExcel(columns, selectedRows, funct.exportConfig)
              }
              block
              style={{ textAlign: 'left' }}
            >
              {t('actionsColumn.exportSelected')}
            </Button>
          )}
          {funct.deleteFunc && (
            <Popover
              overlayStyle={{ width: 195 }}
              placement="rightTop"
              trigger={'click'}
              getPopupContainer={(triggerNode) =>
                triggerNode.parentNode as HTMLElement
              }
              visible={deleteFunctVisible}
              content={
                <div
                  style={{
                    padding: 10,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Typography.Text style={{ fontWeight: 500 }}>
                    {t('actionsColumn.groupDeleteWarning')}
                  </Typography.Text>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      size="small"
                      onClick={() => setDeleteFunctVisible(false)}
                    >
                      {t('actionsColumn.confirmation.no')}
                    </Button>
                    <Button
                      type="primary"
                      size="small"
                      style={{ marginLeft: 5 }}
                      onClick={() => {
                        funct && funct.deleteFunc && funct.deleteFunc();
                        setDeleteFunctVisible(false);
                      }}
                    >
                      {t('actionsColumn.confirmation.yes')}
                    </Button>
                  </div>
                </div>
              }
            >
              <Button
                type="text"
                danger
                onClick={() => setDeleteFunctVisible(true)}
                block
                style={{ textAlign: 'left' }}
              >
                {t('actionsColumn.groupDelete')}
              </Button>
            </Popover>
          )}
        </div>
      )}
    </Form>
  );

  return (
    <Space
      style={{
        position: 'fixed',
        bottom: 0,
        left: screens.sm ? (collapsed ? 55 : 200) : 0,
        right: 0,
        backgroundColor: WHITE,
        padding: '8px 24px 8px 24px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        zIndex: 3,
        boxShadow:
          '0 -6px 16px -8px rgb(0 0 0 / 8%), 0 -9px 28px 0 rgb(0 0 0 / 5%), 0 -12px 48px 16px rgb(0 0 0 / 3%)',
      }}
    >
      <Space>
        <Typography>
          {t('general.selected', {
            number: selectedRows.length,
          })}
        </Typography>
        {setSelectedRowKeys && (
          <Button
            type="link"
            style={{ padding: 0 }}
            onClick={() => setSelectedRowKeys([])}
          >
            {t('general.unselect')}
          </Button>
        )}
        {
          <Popover
            trigger="click"
            placement="topLeft"
            content={menu}
            overlayClassName="tableFooterToolbar"
            getPopupContainer={(triggerNode) =>
              triggerNode.parentNode as HTMLElement
            }
          >
            <Button style={{ padding: 0 }} icon={<UpOutlined />} type="text">
              {t('goods.goodsBatch.title')}
            </Button>
          </Popover>
        }
      </Space>
    </Space>
  );
};

export default TableFooterToolbar;
