import { QuestionCircleOutlined, RightOutlined } from '@ant-design/icons';
import {
  AutoComplete,
  Button,
  DatePicker,
  Form,
  FormInstance,
  Input,
  Popconfirm,
  Popover,
  Select,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { actionPermissions } from '../../constants/actionPermissions';
import { BATCH_ORDER_BUTTON_PERMISSIONS } from '../../constants/orderConstants';
import { dashboardRoute } from '../../constants/pathname';
import { DATE_FORMAT } from '../../constants/systemConstants';
import { useTab } from '../../hooks/useTab';
import {
  BasicEnumInfoType,
  ExpressOrderList,
  OrderData,
  OrderEnum,
  OrderPrintOutContents,
  OrderRouteData,
  PrintType,
  PrintTypeMode,
  PrintoutSelectedOrderObj,
  SpecialSummaryList,
  StatementPrintoutOrderData,
  TaskEnum,
} from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import getDashboardStyle from '../../utils/getDashboardStyle';
import { hasPermission } from '../../utils/hasPermission';
import BatchFailedModal from '../BatchFailedModal';
import PrintModal from '../PrintModal';
import { onExportToExcel } from '../table/TableExport';
import BuildOrderData from './ToPrintoutData';

type BatchOrderTableFooterToolbarProps = {
  form: FormInstance<any>;
  orderInfo: {
    userId?: string;
    actionList?: string[];
    routeId?: number | undefined;
    orderEnumList?: OrderEnum;
  };
  general: {
    advance?: any;
    setAdvance?: React.Dispatch<React.SetStateAction<any>>;
    modalLoading?: boolean;
    setModalLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  };
  selectedRows: any[];
  columns?: ColumnsType<any>;
  selectedRowKeys?: any[];
  setSelectedRowKeys?: React.Dispatch<React.SetStateAction<React.Key[]>>;
  funct: {
    refresh?: Function;
    deleteFunc?: () => void;
    exportConfig?: { fileName: string; url?: string };
  };
};

const BatchOrderTableFooterToolbar = ({
  columns,
  orderInfo,
  general,
  selectedRows,
  selectedRowKeys,
  setSelectedRowKeys,
  form,
  funct,
}: BatchOrderTableFooterToolbarProps) => {
  //General Components
  const { addTab } = useTab();
  const { t } = useTranslation();
  //Visible/Statuses Components
  const [statementModalVisible, setStatementModalVisible] = useState(false);
  const [driverPrintModalVisible, setDriverPrintModalVisible] = useState(false);
  const [orderPrintModalVisible, setOrderPrintModalVisible] = useState(false);
  const [invoicePrintModalVisible, setInvoicePrintModalVisible] =
    useState(false);
  const [orderGoodsModalVisible, setOrderGoodsModalVisible] = useState(false);
  const [batchFailedVis, setBatchFailedVis] = useState(false);
  const [routeId2Vis, setRouteId2Vis] = useState<boolean>();
  const [isFullVersion, setIsFullVersion] = useState<boolean>();
  const [routeData, setRouteData] = useState<OrderRouteData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //Data Components
  const [printoutData, setPrintoutData] = useState<PrintType | undefined>(
    undefined
  );
  const [batchFailObjs, setBatchFailObjs] = useState<{
    dataType?: 'GOODS' | 'ORDER' | 'TASK';
    data?: any[];
    status?: string;
  }>({});
  const [taskEnum, setTaskEnum] = useState<TaskEnum>();
  const taskLabel = Form.useWatch('taskLabel', form);
  const [taskLabelData, setTaskLabelData] = useState<{ value: string }[]>([]);
  const [goodsTags, setGoodsTags] = useState<BasicEnumInfoType[]>([]);

  const sendOrderBatchRequest = async (
    operate: string,
    needRefresh?: boolean
  ) => {
    let resStatus = false;
    setIsLoading(true);
    setBatchFailedVis(false);
    await postDataWithAuthToken(
      operate === 'IMPORT'
        ? 'order/import'
        : operate === 'IMPORT_TASK'
        ? 'task/add_batch'
        : 'order/batch',
      {
        routeId:
          operate === 'IMPORT' && form.getFieldValue('routeId1')
            ? form.getFieldValue('routeId1')
            : form.getFieldValue('routeId2')
            ? parseInt(form.getFieldValue('routeId2'))
            : undefined,
        orderIds: selectedRowKeys ? selectedRowKeys : undefined,
        operate:
          operate !== 'IMPORT' && operate !== 'IMPORT_TASK'
            ? operate
            : undefined,
        expectShippingTime:
          operate === 'EXPECT_SHIPPING_TIME' &&
          form.getFieldValue('expectShippingTime')
            ? form.getFieldValue('expectShippingTime').format(DATE_FORMAT)
            : undefined,
        expectShippingTimeInterval:
          operate === 'EXPECT_SHIPPING_TIME'
            ? form.getFieldValue('expectShippingTimeInterval')
              ? form.getFieldValue('expectShippingTimeInterval')
              : orderInfo &&
                orderInfo.orderEnumList &&
                orderInfo.orderEnumList.timeInterval[0].code
            : undefined,
        remark:
          operate !== 'IMPORT_TASK' && form.getFieldValue('remark')
            ? form.getFieldValue('remark')
            : undefined,
        taskType:
          operate === 'IMPORT_TASK' && form.getFieldValue('taskType')
            ? form.getFieldValue('taskType')
            : undefined,
        taskStageGroup:
          operate === 'IMPORT_TASK' && form.getFieldValue('taskStageGroup')
            ? form.getFieldValue('taskStageGroup')
            : undefined,
        taskLabel:
          operate === 'IMPORT_TASK' && form.getFieldValue('taskLabel')
            ? form.getFieldValue('taskLabel')
            : undefined,
        taskDate:
          operate === 'IMPORT_TASK' && form.getFieldValue('taskDate')
            ? moment(form.getFieldValue('taskDate')).format(DATE_FORMAT)
            : undefined,
      },
      { timeout: 0 }
    )
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            alertMessage('success', t('order.alerts.batchEdited'));
            if (operate !== 'IMPORT_TASK') {
              needRefresh && setSelectedRowKeys && setSelectedRowKeys([]);
              needRefresh && funct.refresh && funct.refresh();
            }
            resStatus = true;
          } else if (!response.goodStatus && !response.batchStatus) {
            setBatchFailedVis(true);
            alertMessage('error', t('general.fourFourFour'));
            setBatchFailObjs({
              dataType: response.data.errorType,
              data: response?.data.list,
            });
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });

    return resStatus;
  };

  const getPrintoutContents = (
    orderIds: number[],
    printMode: PrintTypeMode,
    isFull: boolean = true
  ) => {
    general.setModalLoading && general.setModalLoading(true);
    getDataWithAuthToken(
      printMode === 'order' || printMode === 'invoice'
        ? 'order/print'
        : 'order/print_express',
      {
        params: {
          routeId:
            general && general.advance && general.advance.routeId
              ? general.advance.routeId
              : undefined,
          orderIds: orderIds.toString(),
        },
      }
    )
      .then((response) => {
        if (response && response.goodStatus) {
          if (printMode === 'order' || printMode === 'invoice') {
            let responseData: OrderPrintOutContents[] = response.data.list;
            //each traversing thru selected order and building it info sep objects.
            //each key in the obj will be used for a specific component
            if (responseData.length > 0) {
              const OrderDataObjects: PrintoutSelectedOrderObj = BuildOrderData(
                responseData,
                isFull
              );
              setPrintoutData({ ...OrderDataObjects });
              setOrderPrintModalVisible(printMode === 'order');
              setInvoicePrintModalVisible(printMode === 'invoice');
            } else {
              alertMessage(
                'error',
                response?.msg || t('general.noResponse'),
                response?.data || undefined
              );
            }
          } else {
            let expressResponseData: ExpressOrderList[] =
              response.data.expressOrderList;
            let specialResponseData: SpecialSummaryList[] =
              response.data.specialSummaryList;
            let totalOrder: number = response.data.totalOrder;
            let sumTipsFee: number = response.data.sumTipsFee;
            let totalSpecialSummer: string = response.data.totalSpecialSummary;
            let routeName: string = response.data.routeName;
            setPrintoutData({
              driverData: {
                expressList: expressResponseData,
                specialList: specialResponseData,
                totalOrder: totalOrder,
                sumTipsFee: sumTipsFee,
                totalSpecialSummary: totalSpecialSummer,
                routeName: routeName,
                routeId:
                  general && general.advance && general.advance.routeId
                    ? general.advance.routeId
                    : undefined,
              },
            });
          }
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }

        general.setModalLoading && general.setModalLoading(false);
      })
      .catch((err) => {
        console.log(err);
        general.setModalLoading && general.setModalLoading(false);
      });
  };

  const getOrderButtonComponents = (isFull: boolean) => {
    let content = (
      <Button
        disabled={!hasPermission(actionPermissions.orderGroup.orderPrint)}
        type="link"
        style={{ textAlign: 'left' }}
        onClick={() => {
          setIsFullVersion(isFull);
          getPrintoutContents(
            selectedRows.map((row: OrderData) => row.orderId),
            'order',
            isFull
          );
          setOrderPrintModalVisible(true);
        }}
      >
        {`${t('actionsColumn.print')} ${
          isFull ? t('actionsColumn.fullVersion') : ''
        } (${t('actionsColumn.orderFormat')})`}
      </Button>
    );

    // 如果其中一个已打印过，要提示
    if (!selectedRows.every((order) => order.printTime === null)) {
      content = (
        <Popconfirm
          title={t('actionsColumn.printWarning')}
          onConfirm={() => {
            setIsFullVersion(isFull);
            getPrintoutContents(
              selectedRows.map((row: OrderData) => row.orderId),
              'order',
              isFull
            );
            setOrderPrintModalVisible(true);
          }}
          okText={t('actionsColumn.confirmation.yes')}
          cancelText={t('actionsColumn.confirmation.no')}
          disabled={!hasPermission(actionPermissions.orderGroup.orderPrint)}
          placement="leftBottom"
        >
          <Button
            disabled={!hasPermission(actionPermissions.orderGroup.orderPrint)}
            type="link"
            style={{ textAlign: 'left' }}
          >
            {`${t('actionsColumn.print')} ${
              isFull ? t('actionsColumn.fullVersion') : ''
            } (${t('actionsColumn.orderFormat')})`}
          </Button>
        </Popconfirm>
      );
    }

    return content;
  };

  const getStatementPrintoutContents = (orderIds: number[]) => {
    general.setModalLoading && general.setModalLoading(true);
    getDataWithAuthToken('order/print_statement', {
      params: {
        orderIds: orderIds.join(),
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          const statementDataRes : StatementPrintoutOrderData = response.data;
          if (
            general &&
            general.advance &&
            general.advance.startExpectShippingTime &&
            general.advance.endExpectShippingTime
          ) {
            setPrintoutData({
              statementData: {
                ...statementDataRes,
                startDate: general.advance.startExpectShippingTime,
                endDate: general.advance.endExpectShippingTime,
              },
              orderClaimsData: statementDataRes.orderClaims ? statementDataRes.orderClaims : undefined
            });
          } else {
            setPrintoutData({
              statementData: {
                ...statementDataRes,
              },
              orderClaimsData: statementDataRes.orderClaims ? statementDataRes.orderClaims : undefined
            });
          }
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }

        general.setModalLoading && general.setModalLoading(false);
      })
      .catch((err) => {
        console.log(err);
        general.setModalLoading && general.setModalLoading(false);
      });
  };

  const getOrderGoodsListDetail = (orderIds: number[], goodsTag?: string) => {
    general.setModalLoading && general.setModalLoading(true);
    getDataWithAuthToken('order_goods/list', {
      params: {
        orderIds: orderIds.join(),
        goodsTag: goodsTag ? goodsTag : undefined,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          setPrintoutData({
            orderGoodsData: response.data.list,
          });
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }

        general.setModalLoading && general.setModalLoading(false);
      })
      .catch((err) => {
        console.log(err);
        general.setModalLoading && general.setModalLoading(false);
      });
  };

  //Makes a request and set route list data
  const getRouteData = (date: string) => {
    getDataWithAuthToken('order/route_list', {
      params: {
        shippingDate: date,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          setRouteData(response.data.list);
          if (response.data.list.length === 0)
            alertMessage('warning', t('order.alerts.noRouteWithDate'));
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTaskLabelList = () => {
    getDataWithAuthToken('task/task_label')
      .then((response) => {
        if (response && response.goodStatus) {
          setTaskLabelData(
            response.data
              ? response.data.map((taskLabel: string) => ({ value: taskLabel }))
              : []
          );
        } else
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTaskEnum = () => {
    getDataWithAuthToken('task/enum_list')
      .then((response) => {
        if (response && response.goodStatus) {
          setTaskEnum(response.data);
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getGoodsTag = () => {
    getDataWithAuthToken('goods/enum_list')
      .then((response) => {
        if (response && response.goodStatus) {
          setGoodsTags(response.data.goodsTag);
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const exportOrderGoods = (orderIds: number[]) => {
    getDataWithAuthToken('order_goods/export/excel', {
      params: {
        orderIds: orderIds.join(),
      },
    })
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            alertMessage(
              'success',
              t('fileManager.alerts.requestReceived'),
              <Space direction="vertical">
                <Typography>ID: {response.data.id}</Typography>
                <Typography>
                  {t('fileManager.fileManagerColumns.fileName')}:{' '}
                  {response.data.fileName}
                </Typography>
                <Button
                  type="link"
                  onClick={() =>
                    addTab(
                      t('fileManager.title'),
                      dashboardRoute.settings.fileManager
                    )
                  }
                  style={{ padding: 0 }}
                >
                  {t('fileManager.fileManagerActions.go')}
                </Button>
              </Space>
            );
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || ''
            );
            general.setModalLoading && general.setModalLoading(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        general.setModalLoading && general.setModalLoading(false);
      });
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        {orderInfo &&
          orderInfo.actionList?.map((operate, index) => {
            return (
              <Popover
                key={index}
                trigger="click"
                placement="rightBottom"
                getPopupContainer={(triggerNode) =>
                  triggerNode.parentNode as HTMLElement
                }
                onVisibleChange={() => {
                  setRouteId2Vis(undefined);
                  form.resetFields();
                  setRouteData([]);
                }}
                content={
                  <div
                    style={{
                      width: 350,
                      padding: 10,
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    {operate === 'IMPORT' ? (
                      <>
                        <Form.Item
                          name="routeDate"
                          label={t(
                            'order.orderListColumns.advancedSearch.route'
                          )}
                          initialValue={moment()}
                        >
                          <DatePicker
                            disabled={
                              routeId2Vis === undefined ? false : routeId2Vis
                            }
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode as HTMLElement
                            }
                            placeholder={t(
                              'searchPlaceholders.selectShippingDate'
                            )}
                            style={{ width: '100%' }}
                            onBlur={() => {
                              if (
                                form.getFieldsValue(['routeDate'])['routeDate']
                              ) {
                                setRouteId2Vis(false);
                              } else {
                                setRouteId2Vis(undefined);
                              }
                            }}
                            onFocus={() => {
                              if (
                                form.getFieldsValue(['routeDate'])['routeDate']
                              ) {
                                setRouteId2Vis(false);
                              } else {
                                setRouteId2Vis(undefined);
                              }
                            }}
                            onChange={(date, dateString) => {
                              setRouteData([]);
                              if (dateString === '') {
                                setRouteId2Vis(undefined);
                              } else {
                                setRouteId2Vis(false);
                                if (
                                  hasPermission(
                                    actionPermissions.orderGroup.orderView
                                  )
                                )
                                  getRouteData(dateString);
                              }
                            }}
                          />
                        </Form.Item>

                        {routeData && routeData.length > 0 && (
                          <Form.Item
                            name="routeId1"
                            label={t(
                              'order.orderListColumns.advancedSearch.routeId'
                            )}
                          >
                            <Select
                              disabled={routeData.length > 0 ? false : true}
                              style={{ width: '100%' }}
                              placeholder={t(
                                'searchPlaceholders.searchRouteId'
                              )}
                              getPopupContainer={(triggerNode) =>
                                triggerNode.parentNode
                              }
                            >
                              {routeData.map((route, i) => (
                                <Select.Option
                                  key={`${route.routeName}@${i}`}
                                  value={route.id}
                                >
                                  {route.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        )}
                        <Form.Item
                          name="routeId2"
                          label={t(
                            'order.orderListColumns.advancedSearch.routeId'
                          )}
                        >
                          <Input
                            type={'number'}
                            allowClear
                            disabled={
                              routeId2Vis === undefined ? false : !routeId2Vis
                            }
                            onBlur={() => {
                              if (
                                form.getFieldsValue(['routeId2'])['routeId2']
                              ) {
                                setRouteId2Vis(true);
                              } else {
                                setRouteId2Vis(undefined);
                              }
                            }}
                            onFocus={() => {
                              if (
                                form.getFieldsValue(['routeId2'])['routeId2']
                              ) {
                                setRouteId2Vis(true);
                              } else {
                                setRouteId2Vis(undefined);
                              }
                            }}
                            onChange={(e) => {
                              let target = e.target as HTMLInputElement;
                              if (target.value === '') {
                                setRouteId2Vis(undefined);
                              } else {
                                setRouteId2Vis(true);
                              }
                            }}
                            min={0}
                          />
                        </Form.Item>
                      </>
                    ) : operate === 'IMPORT_TASK' ? (
                      <>
                        <Form.Item
                          label={t('sortationSystem.taskListColumns.taskType')}
                          name="taskType"
                          initialValue="NORMAL"
                        >
                          <Select
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                          >
                            {taskEnum &&
                              taskEnum.taskType &&
                              taskEnum.taskType.length > 0 &&
                              taskEnum.taskType.map((item) => (
                                <Select.Option
                                  key={item.code}
                                  value={item.code}
                                >
                                  {item.description}
                                </Select.Option>
                              ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          label={t('sortationSystem.taskListColumns.taskStage')}
                          name="taskStageGroup"
                          initialValue="SORT"
                        >
                          <Select
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                          >
                            {taskEnum &&
                              taskEnum.taskStageGroup &&
                              taskEnum.taskStageGroup.length > 0 &&
                              taskEnum.taskStageGroup.map((item) => (
                                <Select.Option
                                  key={item.code}
                                  value={item.code}
                                >
                                  {item.description}
                                </Select.Option>
                              ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          label={t('sortationSystem.taskListColumns.taskLabel')}
                          name="taskLabel"
                          initialValue={
                            general &&
                            general.advance &&
                            general.advance.routeName
                          }
                        >
                          <AutoComplete
                            options={taskLabelData}
                            allowClear
                            filterOption
                            onFocus={() => {
                              getTaskLabelList();
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          label={
                            <Space>
                              {t('sortationSystem.taskListColumns.taskTime')}
                              <Tooltip
                                title={t(
                                  'sortationSystem.alerts.taskDateNotice'
                                )}
                              >
                                <QuestionCircleOutlined />
                              </Tooltip>
                            </Space>
                          }
                          name="taskDate"
                        >
                          <DatePicker />
                        </Form.Item>
                      </>
                    ) : (
                      <Form.Item //Remark field
                        name="remark"
                        label={t('order.printoutColumns.batch.remark')}
                      >
                        <Input allowClear />
                      </Form.Item>
                    )}
                    {operate === 'EXPECT_SHIPPING_TIME' && (
                      <Form.Item //Expect Shipping time field
                        name={'expectShippingTime'}
                        label={t(
                          'order.printoutColumns.batch.expectShippingTime'
                        )}
                        initialValue={moment()}
                      >
                        <DatePicker
                          style={{ width: '100%' }}
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode as HTMLElement
                          }
                          onChange={(date, dateString) => {
                            if (dateString)
                              general.setAdvance &&
                                general.setAdvance(() => ({
                                  expectShippingTime: dateString,
                                }));
                          }}
                        />
                      </Form.Item>
                    )}
                    {operate === 'EXPECT_SHIPPING_TIME' && (
                      <Form.Item //Expect Shipping time interval field
                        name="expectShippingTimeInterval"
                        label={t(
                          'order.printoutColumns.batch.expectShippingTimeInterval'
                        )}
                        initialValue={
                          orderInfo.orderEnumList &&
                          orderInfo.orderEnumList.timeInterval &&
                          orderInfo.orderEnumList.timeInterval.length > 0 &&
                          orderInfo.orderEnumList.timeInterval[0].code
                        }
                      >
                        <Select
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode as HTMLElement
                          }
                          style={{ width: '100%' }}
                          placeholder={t(
                            'searchPlaceholders.searchIntervalType'
                          )}
                          optionLabelProp="key"
                        >
                          {orderInfo.orderEnumList &&
                            orderInfo.orderEnumList.timeInterval.map((type) => (
                              <Select.Option
                                key={type.description}
                                value={type.code}
                              >
                                {type.description}
                              </Select.Option>
                            ))}
                        </Select>
                      </Form.Item>
                    )}
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Popconfirm // PopConfirm only shows when importing tasks
                        getPopupContainer={(trigger) =>
                          trigger.parentNode as HTMLElement
                        }
                        showArrow={false}
                        overlayInnerStyle={{ padding: 12 }}
                        placement="topLeft"
                        title={t('order.alerts.preparingWarning')}
                        disabled={
                          isLoading ||
                          operate !== 'IMPORT_TASK' ||
                          (operate === 'IMPORT_TASK' && !taskLabel) ||
                          !hasPermission(
                            actionPermissions.sortationGroup.taskManage ||
                              BATCH_ORDER_BUTTON_PERMISSIONS['PREPARE']
                          )
                        }
                        okText={t('actionsColumn.confirmation.yes')}
                        cancelText={t('actionsColumn.confirmation.no')}
                        okButtonProps={{
                          disabled: !hasPermission(
                            actionPermissions.sortationGroup.taskManage ||
                              BATCH_ORDER_BUTTON_PERMISSIONS['PREPARE']
                          ),
                        }}
                        cancelButtonProps={{
                          disabled: !hasPermission(
                            actionPermissions.sortationGroup.taskManage
                          ),
                        }}
                        onConfirm={() => {
                          sendOrderBatchRequest('PREPARE').then((res) => {
                            res && sendOrderBatchRequest('IMPORT_TASK');
                          });
                        }}
                        onCancel={() => {
                          sendOrderBatchRequest('IMPORT_TASK');
                        }}
                      >
                        <Button
                          loading={isLoading}
                          disabled={
                            isLoading ||
                            (operate === 'IMPORT_TASK' && !taskLabel) ||
                            !hasPermission(
                              operate === 'IMPORT_TASK'
                                ? actionPermissions.sortationGroup.taskManage
                                : BATCH_ORDER_BUTTON_PERMISSIONS[operate]
                            )
                          }
                          type="primary"
                          size="small"
                          onClick={() => {
                            setRouteId2Vis(undefined);
                            setRouteData([]);
                            operate !== 'IMPORT_TASK' &&
                              sendOrderBatchRequest(operate, true);
                          }}
                        >
                          {t('general.ok')}
                        </Button>
                      </Popconfirm>
                      <Button
                        size="small"
                        style={{ marginLeft: 5 }}
                        onClick={() => {
                          setRouteId2Vis(undefined);
                          form.resetFields();
                          setRouteData([]);
                        }}
                      >
                        {t('general.reset')}
                      </Button>
                    </div>
                  </div>
                }
              >
                <Button
                  block
                  type="text"
                  style={{
                    display:
                      hasPermission(BATCH_ORDER_BUTTON_PERMISSIONS[operate]) ||
                      (operate === 'IMPORT_TASK' &&
                        hasPermission(
                          actionPermissions.sortationGroup.taskManage
                        ))
                        ? 'flex'
                        : 'none',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                  onClick={() => {
                    operate === 'IMPORT_TASK' && !taskEnum && getTaskEnum();
                  }}
                >
                  {t(`order.printoutColumns.batch.${operate}`)}
                  <RightOutlined />
                </Button>
              </Popover>
            );
          })}

        {/** 打印订单商品 - 特殊商品 */}
        <Popover
          trigger="click"
          placement="rightBottom"
          getPopupContainer={(triggerNode) =>
            triggerNode.parentNode as HTMLElement
          }
          onVisibleChange={() => {
            form.resetFields();
          }}
          content={
            <div
              style={{
                width: 350,
                padding: 10,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Form.Item
                label={t('goods.goodsBatch.goodsTag.title')}
                name="goodsTag"
                initialValue="SPECIAL"
              >
                <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                >
                  {goodsTags &&
                    goodsTags.map((item) => (
                      <Select.Option key={item.code} value={item.code}>
                        {item.description}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Button
                  loading={isLoading}
                  disabled={isLoading}
                  type="primary"
                  size="small"
                  onClick={() => {
                    getOrderGoodsListDetail(
                      selectedRows.map((row: OrderData) => row.orderId),
                      form.getFieldValue('goodsTag')
                    );
                    setOrderGoodsModalVisible(true);
                  }}
                >
                  {t('general.ok')}
                </Button>
                <Button
                  size="small"
                  style={{ marginLeft: 5 }}
                  onClick={() => {
                    form.resetFields();
                  }}
                >
                  {t('general.reset')}
                </Button>
              </div>
            </div>
          }
        >
          <Button
            block
            type="text"
            style={{
              display: hasPermission(actionPermissions.orderGroup.orderPrint)
                ? 'flex'
                : 'none',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            onClick={() => {
              getGoodsTag();
            }}
          >
            {`${t('actionsColumn.print')}${t(
              'actionsColumn.orderGoodsFormat'
            )}`}
            <RightOutlined />
          </Button>
        </Popover>

        {/* 打印订单不含商品 */}
        {!getDashboardStyle().isWholeSaleAppType &&
          getOrderButtonComponents(false)}
        {/* 打印订单含商品 */}
        {getOrderButtonComponents(true)}
        {/* 打印司机单 */}
        <Button
          block
          disabled={
            !hasPermission(actionPermissions.orderGroup.orderPrintExpress)
          }
          type="link"
          style={{ textAlign: 'left' }}
          onClick={() => {
            getPrintoutContents(
              selectedRows.map((row: OrderData) => row.orderId),
              'driver'
            );
            setDriverPrintModalVisible(true);
          }}
        >
          {`${t('actionsColumn.print')} (${t('actionsColumn.driverFormat')})`}
        </Button>
        {/* 打印Invoice */}
        {!getDashboardStyle().isWholeSaleAppType && (
          <Button
            disabled={!hasPermission(actionPermissions.orderGroup.orderPrint)}
            type="link"
            style={{ textAlign: 'left' }}
            onClick={() => {
              getPrintoutContents(
                selectedRows.map((row: OrderData) => row.orderId),
                'invoice'
              );
              setInvoicePrintModalVisible(true);
            }}
          >
            {`${t('actionsColumn.print')} (${t(
              'actionsColumn.invoiceFormat'
            )})`}
          </Button>
        )}

        {orderInfo && orderInfo.userId && (
          <Button
            block
            type="link"
            style={{ textAlign: 'left' }}
            onClick={() => {
              getStatementPrintoutContents(
                selectedRows.map((row: OrderData) => row.orderId)
              );
              setStatementModalVisible(true);
            }}
          >
            {`${t('actionsColumn.exportStatement')}`}
          </Button>
        )}
        {orderInfo && (
          <Button
            block
            type="text"
            style={{ textAlign: 'left' }}
            onClick={() => {
              exportOrderGoods(
                selectedRows.map((row: OrderData) => row.orderId)
              );
            }}
          >{`${t('order.printoutColumns.batch.exportOrderGoods')}`}</Button>
        )}
        {selectedRows && columns && funct.exportConfig && (
          <Button
            block
            type="text"
            onClick={() => {
              onExportToExcel(columns, selectedRows, funct.exportConfig);
            }}
            style={{ textAlign: 'left' }}
          >
            {t('actionsColumn.exportSelected')}
          </Button>
        )}
      </div>
      <PrintModal
        modalLoading={general.modalLoading}
        mode="order"
        isFullVersion={isFullVersion}
        setIsFullVersion={setIsFullVersion}
        data={printoutData}
        setData={setPrintoutData}
        visible={orderPrintModalVisible}
        setVisible={setOrderPrintModalVisible}
      />
      <PrintModal
        modalLoading={general.modalLoading}
        mode="driver"
        data={printoutData}
        setData={setPrintoutData}
        visible={driverPrintModalVisible}
        setVisible={setDriverPrintModalVisible}
      />
      <PrintModal
        modalLoading={general.modalLoading}
        mode="invoice"
        data={printoutData}
        setData={setPrintoutData}
        visible={invoicePrintModalVisible}
        setVisible={setInvoicePrintModalVisible}
      />
      <PrintModal
        modalLoading={general.modalLoading}
        mode="statement"
        data={printoutData}
        setData={setPrintoutData}
        visible={statementModalVisible}
        setVisible={setStatementModalVisible}
      />
      <PrintModal
        modalLoading={general.modalLoading}
        mode="orderGoods"
        data={printoutData}
        setData={setPrintoutData}
        visible={orderGoodsModalVisible}
        setVisible={setOrderGoodsModalVisible}
      />
      <BatchFailedModal
        failedObjects={batchFailObjs}
        visible={batchFailedVis}
        setVisible={setBatchFailedVis}
        selectedRowKeys={selectedRowKeys}
        loading={isLoading}
      />
    </>
  );
};

export default BatchOrderTableFooterToolbar;
