import {
  CloseCircleOutlined,
  FilterOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Badge,
  Button,
  Col,
  DatePicker,
  Divider,
  Drawer,
  Dropdown,
  Form,
  Grid,
  Input,
  InputNumber,
  Menu,
  Popconfirm,
  Popover,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Tabs,
  Tag,
  Typography,
} from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import {
  ColumnsType,
  FilterValue,
  SortOrder,
  SorterResult,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useVT } from 'virtualizedtableforantd4';
import { useAppDispatch } from '../../../app/hooks';
import FourZeroThree from '../../../components/FourZeroThree';
import BuildOrderData from '../../../components/order/ToPrintoutData';
import TableFooterToolbar from '../../../components/table/TableFooterToolbar';
import TableToolbar from '../../../components/table/TableToolbar';
import { actionPermissions } from '../../../constants/actionPermissions';
import { PRIMARY } from '../../../constants/color';
import { dashboardRoute } from '../../../constants/pathname';
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
  EXTENDED_TIMEOUT,
  GENERAL_TIMEOUT,
} from '../../../constants/systemConstants';
import { addPage } from '../../../features/pageHistory/pageHistorySlice';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { useTab } from '../../../hooks/useTab';
import {
  FontSizeType,
  GoodData,
  OrderAmount,
  OrderData,
  OrderEnum,
  OrderGood,
  OrderPrintOutContents,
  OrderRouteData,
  PaymentMethod,
  PrintType,
  PrintoutSelectedOrderObj,
  ShippingData,
} from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import { getDataWithAuthToken } from '../../../utils/axiosRequest';
import { setFont } from '../../../utils/colComponents';
import getDashboardStyle from '../../../utils/getDashboardStyle';
import { hasPermission } from '../../../utils/hasPermission';
import {
  mergeParamsToString,
  tableScrollToTop,
} from '../../../utils/helperFunction';
import FiveHundred from '../../FiveHundred';
import PrintModal from '../../PrintModal';
import SelectGoodsModal from '../../goods/selectGoods/SelectGoodsModal';
import OrderModal from '../../order/OrderModal';
import OrderGoods from '../../order/orderDetail/OrderGoodsTable';
import SalespersonDropdown from '../../salesperson/SalespersonDropdown';
import SellersDropdown from '../../sellers/SellersDropdown';
import RegionDropdown from '../../settings/common/RegionDropdown';
import UserRankDropdown from '../common/UserRankDropdown';
import BatchOrderModal from '../../order/BatchOrderModal';

type UserOrderTableProps = {
  id?: string;
};
const UserOrderTable = ({ id }: UserOrderTableProps) => {
  //General Components
  const queryString = window.location.search;
  // orderIds from url
  const [orderIds] = useState(
    new URLSearchParams(queryString).get('order_ids')
  );
  const { TabPane } = Tabs;
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const { addTab } = useTab();
  const { t } = useTranslation();
  const isSubscribed = useRef(true);
  const dispatch = useAppDispatch();
  const [fourZeroThree, setFourZeroThree] = useState(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const screens = Grid.useBreakpoint();
  const [isOrderGoodsLoading, setIsOrderGoodsLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [advance, setAdvance] = useState<{
    [key: string]: any;
  }>({});
  const { RangePicker } = DatePicker;
  const [isSeller, setIsSeller] = useState(
    new URLSearchParams(queryString).get('is_seller') === 'true' ? true : false
  );
  const [showDrawer, setShowDrawer] = useState(false);
  const [navType, setNavType] = useState('ALL');
  const [keyword, setKeyword] = useState('');
  const [printModalVisible, setPrintModalVisible] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [orderModalVisible, setOrderModalVisible] = useState(false);
  const [orderBatchModalVisible, setOrderBatchModalVisible] = useState(false);
  const [selectGoodsVisible, setSelectGoodsVisible] = useState<boolean>(false);
  const [isFullVersion, setIsFullVersion] = useState<boolean>();
  //Data Components
  const [selectedGoodObjs, setSelectedGoodObjs] = useState<GoodData>();
  const [good, setGood] = useState<GoodData>();
  const [allOrderPrintoutData, setAllOrderPrintoutData] = useState<
    PrintType | undefined
  >(undefined);
  const [orderGoodsData, setOrderGoodsData] = useState<OrderGood[]>([]);
  const [orderData, setOrderData] = useState<OrderData[]>([]);
  const [shippingData, setShippingData] = useState<ShippingData[]>([]);
  const [orderEnumObj, setOrderEnumObj] = useState<OrderEnum>();
  const [paymentData, setPaymentData] = useState<PaymentMethod[]>([]);
  const [routeData, setRouteData] = useState<OrderRouteData[]>([]);
  //Text Components
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  //Table Components
  const ellipsis = useState(true);
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);
  const orderAmountLabels: Array<keyof OrderAmount> = [
    'goodsAmount',
    'tipsFee',
    'packFee',
    'couponsAmount',
    'totalAmount',
    'paidAmount',
    'orderAmount',
  ];
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useLocalStorage('orderPageSize', '5');
  const [total, setTotal] = useState(0);
  const [sortOrder, setSortOrder] = useState<SortOrder | undefined>(null);
  const [sortValue, setSortValue] = useState<React.Key>('');
  const columnKeys = [
    'orderId',
    'orderSn|shopName|extensions|sequenceLabel',
    'userName|orderStatus|payStatus|shippingStatus|addTime|printTime',
    'consignee|mobile|address',
    'payName|shippingName|expectShippingTimeName',
    'goodsAmount|tipsFee|packFee|couponsAmount|totalAmount|paidAmount|orderAmount',
    'buyerRemark|sellerRemark|deliveryRemark',
    'action',
  ];
  const [selectedColumns, setSelectedColumns] = useState(
    columnKeys.filter((col: string) => {
      return !getDashboardStyle().isWholeSaleAppType ? col !== 'orderId' : true;
    })
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<OrderData[]>([]);

  // Generate export config url
  const getExportUrl = (params: { [key: string]: any }) => {
    return mergeParamsToString('order/export/excel?', params);
  };

  const getPrintoutContents = (orderIds: number[]) => {
    setModalLoading(true);
    getDataWithAuthToken('order/print', {
      params: {
        orderIds: orderIds.toString(),
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          let responseData: OrderPrintOutContents[] = response.data.list;
          if (responseData.length > 0) {
            //each traversing thru selected order and building it info sep objects.
            //each key in the obj will be used for a specific component
            const OrderDataObjects: PrintoutSelectedOrderObj = BuildOrderData(
              responseData,
              true
            );
            setAllOrderPrintoutData({ ...OrderDataObjects });
            setModalLoading(false);
            setPrintModalVisible(true);
          } else {
            setModalLoading(false);
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setModalLoading(false);
      });
  };

  //as soon as the user selects a good from the SelectGoods modal, it triggers this useEffect
  useEffect(() => {
    if (selectedGoodObjs) {
      form.setFieldsValue({ goodsId: selectedGoodObjs.goodsId });
      setGood(selectedGoodObjs);
      setAdvance((prev) => ({
        ...prev,
        goodsId: selectedGoodObjs.goodsId.toString(),
      }));
      setSelectedGoodObjs(undefined);
    }
  }, [selectedGoodObjs, form]);

  // Get Params from form (get/export list params)
  const getFormParams = useCallback(() => {
    const country: number[] = [];
    const prov: number[] = [];
    const city: number[] = [];
    const district: number[] = [];
    const street: number[] = [];
    if (formRef.current && form.getFieldValue('regions')) {
      form.getFieldValue('regions').forEach((array: string[]) => {
        let x = array.map((i) => Number(i));
        if (x.length === 1) {
          country.push(x[0]);
        } else if (x.length === 2) {
          prov.push(x[1]);
        } else if (x.length === 3) {
          city.push(x[2]);
        } else if (x.length === 4) {
          district.push(x[3]);
        } else {
          street.push(x[4]);
        }
      });
    }

    const params = {
      expectShippingTime: formRef.current
        ? form.getFieldValue('expectShippingTime')
          ? moment(form.getFieldValue('expectShippingTime')).format(DATE_FORMAT)
          : undefined
        : id === undefined && !orderIds // !orderIds will ignore null or undefined
        ? moment().format(DATE_FORMAT)
        : undefined, // 在用户详情或获取指定订单列表时
      keyword: keyword ? keyword : undefined,
      // 指定订单IDs (根据urlPath or orderId input)
      orderIds:
        formRef.current && form.getFieldValue('orderIdKeyword')
          ? form.getFieldValue('orderIdKeyword')
          : orderIds
          ? orderIds
          : undefined,
      routeId:
        formRef.current && form.getFieldValue('routeId')
          ? form.getFieldValue('routeId')
          : undefined,
      excludeRouteIds:
        formRef.current && form.getFieldValue('excludeRouteIds')
          ? form.getFieldValue('excludeRouteIds').join()
          : undefined,
      goodsId:
        formRef.current && form.getFieldValue('goodsId')
          ? form.getFieldValue('goodsId')
          : undefined,
      goodsName:
        formRef.current && form.getFieldValue('goodsName')
          ? form.getFieldValue('goodsName')
          : undefined,
      countryIds: country.length ? country.join(',') : undefined,
      provinceIds: prov.length ? prov.join(',') : undefined,
      cityIds: city.length ? city.join(',') : undefined,
      districtIds: district.length ? district.join(',') : undefined,
      streetIds: street.length ? street.join(',') : undefined,
      page: page,
      size: pageSize,
      sortOrder:
        sortOrder === 'ascend'
          ? 'ASC'
          : sortOrder === 'descend'
          ? 'DESC'
          : undefined,
      payId:
        formRef.current && form.getFieldValue('payId')
          ? form.getFieldValue('payId')
          : undefined,
      sortValue: (sortOrder && sortValue) || undefined,
      navType: navType || undefined,
      isSeller: isSeller,
      sellerId:
        formRef.current && form.getFieldValue('sellerId')
          ? form.getFieldValue('sellerId')
          : undefined,
      shippingId:
        formRef.current && form.getFieldValue('shippingId')
          ? form.getFieldValue('shippingId')
          : undefined,
      rankId:
        formRef.current && form.getFieldValue('rankId')
          ? form.getFieldValue('rankId')
          : undefined,
      orderSn:
        formRef.current && form.getFieldValue('orderSn')
          ? form.getFieldValue('orderSn').toString()
          : undefined,
      consignee:
        formRef.current && form.getFieldValue('consignee')
          ? form.getFieldValue('consignee')
          : undefined,
      address:
        formRef.current && form.getFieldValue('address')
          ? form.getFieldValue('address')
          : undefined,
      postalCode:
        formRef.current && form.getFieldValue('postalCode')
          ? form.getFieldValue('postalCode')
          : undefined,
      buzzerCode:
        formRef.current && form.getFieldValue('buzzerCode')
          ? form.getFieldValue('buzzerCode')
          : undefined,
      mobile:
        formRef.current && form.getFieldValue('mobile')
          ? form.getFieldValue('mobile')
          : undefined,
      email:
        formRef.current && form.getFieldValue('email')
          ? form.getFieldValue('email')
          : undefined,
      startAmount:
        formRef.current && form.getFieldValue('startAmount')
          ? form.getFieldValue('startAmount')
          : undefined,
      endAmount:
        formRef.current && form.getFieldValue('endAmount')
          ? form.getFieldValue('endAmount')
          : undefined,
      expectShippingTimeInterval:
        formRef.current && form.getFieldValue('expectShippingTimeIntervalType')
          ? form.getFieldValue('expectShippingTimeIntervalType') === 'all'
            ? undefined
            : form.getFieldValue('expectShippingTimeIntervalType')
          : undefined,
      startExpectShippingTime:
        formRef.current && form.getFieldValue('expectShippingTimeInterval')
          ? moment(form.getFieldValue('expectShippingTimeInterval')[0]).format(
              DATE_FORMAT
            )
          : undefined,
      endExpectShippingTime:
        formRef.current && form.getFieldValue('expectShippingTimeInterval')
          ? moment(form.getFieldValue('expectShippingTimeInterval')[1]).format(
              DATE_FORMAT
            )
          : undefined,
      startAddTime:
        formRef.current && form.getFieldValue('addTimeInterval')
          ? moment(form.getFieldValue('addTimeInterval')[0]).format(
              DATE_TIME_FORMAT
            )
          : undefined,
      endAddTime:
        formRef.current && form.getFieldValue('addTimeInterval')
          ? moment(form.getFieldValue('addTimeInterval')[1]).format(
              DATE_TIME_FORMAT
            )
          : undefined,
      startPrintTime:
        formRef.current && form.getFieldValue('printTimeInterval')
          ? moment(form.getFieldValue('printTimeInterval')[0]).format(
              DATE_TIME_FORMAT
            )
          : undefined,
      endPrintTime:
        formRef.current && form.getFieldValue('printTimeInterval')
          ? moment(form.getFieldValue('printTimeInterval')[1]).format(
              DATE_TIME_FORMAT
            )
          : undefined,
      orderStatus:
        formRef.current && form.getFieldValue('orderStatus')
          ? form.getFieldValue('orderStatus').join()
          : undefined,
      shippingStatus:
        formRef.current && form.getFieldValue('shippingStatus')
          ? form.getFieldValue('shippingStatus').join()
          : undefined,
      payStatus:
        formRef.current && form.getFieldValue('payStatus')
          ? form.getFieldValue('payStatus').join()
          : undefined,
      extension:
        formRef.current && form.getFieldValue('extension')
          ? form.getFieldValue('extension') === 'all'
            ? undefined
            : form.getFieldValue('extension')
          : undefined,
      platform:
        formRef.current && form.getFieldValue('platform')
          ? form.getFieldValue('platform') === 'all'
            ? undefined
            : form.getFieldValue('platform')
          : undefined,
      isTipsFee:
        formRef.current && typeof form.getFieldValue('isTipsFee') === 'boolean'
          ? form.getFieldValue('isTipsFee')
          : undefined,
      userId: id ? id : undefined,
      salesPersonId:
        formRef.current && form.getFieldValue('salesPersonId')
          ? form.getFieldValue('salesPersonId')
          : undefined,
    };
    return params;
  }, [
    page,
    pageSize,
    isSeller,
    form,
    navType,
    sortOrder,
    sortValue,
    id,
    orderIds,
    keyword,
  ]);

  //Makes a request and set the order list data
  const getData = useCallback(
    (request: string = 'order/list') => {
      if (isSubscribed.current) setIsLoading(true);
      getDataWithAuthToken(request, {
        params: getFormParams(),
      })
        .then((response) => {
          if (response && response.goodStatus) {
            if (isSubscribed.current) {
              setOrderData(response.data.list);
              setTotal(response.data.totalItem);
              setSelectedRowKeys([]);
              // Scroll to top when data changes
              tableScrollToTop();
            }
          } else if (response && response.returnCode === 403) {
            if (isSubscribed.current) setFourZeroThree(true);
          } else {
            isSubscribed.current && setFiveHundred(true);
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
          if (isSubscribed.current) setIsLoading(false);
        })
        .catch((err) => {
          if (isSubscribed.current) setIsLoading(false);
          console.log(err);
        });
    },
    [t, getFormParams]
  );

  useEffect(() => {
    if (
      !formRef.current &&
      isSubscribed.current &&
      id === undefined &&
      !orderIds
    ) {
      setAdvance((prev) => ({
        ...prev,
        expectShippingTime: moment().format(DATE_FORMAT),
      }));
    }

    if (orderIds) {
      document.title = `${t('order.orderList')} [${orderIds}] - ${t(
        getDashboardStyle().title
      )} ${t('dashboard.title')}`;

      dispatch(
        addPage({
          title: document.title,
          path: `${dashboardRoute.order.list}?order_ids=${orderIds}&is_seller=${isSeller}`,
        })
      );
    }
    getData();
  }, [t, getData, dispatch, id, orderIds, isSeller]);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  const getPaymentData = (value?: string) => {
    getDataWithAuthToken('payment/list', {
      params: {
        isSeller: isSeller,
        sellerId:
          formRef.current && form.getFieldValue('sellerId')
            ? form.getFieldValue('sellerId')
            : undefined,
        keyword: value || undefined,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            setPaymentData(response.data.list);
          }
        } else
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * Sends a request and returns the Shipping List Data based on the typed keywords
   */
  const getShippingData = (value?: string) => {
    getDataWithAuthToken('shipping/list', {
      params: {
        keyword: value || undefined,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            setShippingData(response.data.list);
          }
        } else
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * Sends a request and returns Order Goods Data based on orderId
   */
  const getOrderGoodsData = (orderId: number) => {
    if (isSubscribed.current) setIsOrderGoodsLoading(true);
    getDataWithAuthToken('order/goods_list', {
      params: {
        orderId: orderId,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            setOrderGoodsData(response.data);
          }
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setIsOrderGoodsLoading(false);
      })
      .catch((err) => {
        if (isSubscribed.current) setIsOrderGoodsLoading(false);
        console.log(err);
      });
  };

  //Makes a request and set route list data
  const getRouteData = (date: string) => {
    getDataWithAuthToken('order/route_list', {
      params: {
        shippingDate: date,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            setRouteData(response.data.list);
          }
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //Makes a request and set the order enum list
  const getOrderEnumList = useCallback(() => {
    getDataWithAuthToken('order/enum_list')
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            if (isSubscribed.current) {
              setOrderEnumObj(response.data);
            }
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [t]);

  const columns: ColumnsType<OrderData> = [
    {
      title: setFont(t('order.orderListColumns.orderId'), fontSize),
      key: 'orderId',
      dataIndex: 'orderId',
      fixed: screens.lg ? 'left' : undefined,
      width: 90,
      sorter: true,
      render: (text: string) => (
        <Button
          style={{ padding: 0, fontSize: fontSize }}
          type="link"
          disabled={!hasPermission(actionPermissions.orderGroup.orderView)}
          onClick={() => {
            addTab('', `${dashboardRoute.order.detail}?order_id=${text}`);
          }}
        >
          <Typography.Text
            style={
              ellipsis
                ? { width: 75, textAlign: 'left', color: PRIMARY }
                : undefined
            }
            ellipsis={ellipsis ? { tooltip: text } : false}
          >
            {text}
          </Typography.Text>
        </Button>
      ),
    },
    //Column for: SN num, shop name, extensions and sequenceLabel(optional)
    {
      title: setFont(
        `${t('order.orderListColumns.orderInfo')}`,
        fontSize,
        `${t('order.orderListColumns.orderSn')}|${t(
          'order.orderListColumns.shopName'
        )}|${t('order.orderListColumns.extensions')}|${t(
          'order.orderListColumns.sequenceLabel'
        )}`
      ),
      key: 'orderSn|shopName|extensions|sequenceLabel',
      width: 200,
      render: (value: any, record: OrderData) => (
        <Space direction="vertical" size={0}>
          <Popover
            mouseEnterDelay={0.5}
            overlayInnerStyle={{
              width: screens.lg ? 800 : screens.md ? 'auto' : undefined,
            }}
            // getPopupContainer={(triggerNode) =>
            //   triggerNode.parentNode as HTMLElement
            // }
            placement="right"
            onVisibleChange={(visible: boolean) => {
              if (
                visible &&
                hasPermission(actionPermissions.orderGroup.orderView)
              ) {
                getOrderGoodsData(record.orderId);
              } else {
                setOrderGoodsData([]);
              }
            }}
            content={
              <OrderGoods
                isLoading={isOrderGoodsLoading}
                orderGoodsData={orderGoodsData}
                isRegionSale={
                  record && record.seller && record.seller.isRegionSale
                }
              />
            }
          >
            <Button
              disabled={!hasPermission(actionPermissions.orderGroup.orderView)}
              onClick={() => {
                addTab(
                  '',
                  `${dashboardRoute.order.detail}?order_id=${record.orderId}`
                );
              }}
              type="link"
              style={{ padding: 0, fontSize: fontSize }}
            >
              <Typography.Text
                style={
                  ellipsis
                    ? { width: 180, textAlign: 'left', color: PRIMARY }
                    : undefined
                }
                ellipsis={ellipsis ? { tooltip: record.orderSn } : false}
              >
                {record.orderSn}
              </Typography.Text>
            </Button>
          </Popover>
          {record.seller && (
            <Button
              disabled={
                !hasPermission(actionPermissions.sellerGroup.sellerMerchants)
              }
              type="link"
              style={{ padding: 0, fontSize: fontSize }}
              onClick={() =>
                addTab(
                  '',
                  `${dashboardRoute.sellers.detail}?seller_id=${record.seller.sellerId}`
                )
              }
            >
              <Typography.Text
                style={ellipsis ? { width: 180, textAlign: 'left' } : undefined}
                ellipsis={
                  ellipsis ? { tooltip: record.seller.shopName } : false
                }
              >
                {record.seller.shopName}
              </Typography.Text>
            </Button>
          )}
          <Space size={0} wrap>
            <Tag
              color={record.extension ? record.extension.tagColor : undefined}
              style={{ fontSize: fontSize }}
            >
              {record.extension ? record.extension.description : undefined}
            </Tag>
            {advance.routeId && record.sequenceLabel && (
              <Tag color="#800020" style={{ fontSize: fontSize }}>
                {record.sequenceLabel}
              </Tag>
            )}
          </Space>
        </Space>
      ),
    },
    //Column for: username, order/pay/shipping status and addTime, printTime
    {
      title: setFont(
        t('order.orderListColumns.userDetail'),
        fontSize,
        `${t('order.orderListColumns.user')}|${t(
          'order.orderListColumns.orderStatus'
        )}|${t('order.orderListColumns.payStatus')}|${t(
          'order.orderListColumns.shippingStatus'
        )}|${t('order.orderListColumns.addTime')}|${t(
          'order.orderListColumns.printTime'
        )}`
      ),
      key: 'userName|orderStatus|payStatus|shippingStatus|addTime|printTime',
      width: 300,
      sorter: true,
      render: (text: string, record: OrderData) =>
        record && (
          <Space direction="vertical" size={0}>
            {record.user && (
              <Button
                type="link"
                style={{ padding: 0, fontSize: fontSize }}
                disabled={!hasPermission(actionPermissions.userGroup.userView)}
                onClick={() =>
                  addTab(
                    '',
                    `${dashboardRoute.users.detail}?user_id=${record.user.userId}`
                  )
                }
              >
                {record.user.userName}
              </Button>
            )}
            <div>
              {record.orderStatus && (
                <Tag
                  color={record.orderStatus.tagColor}
                  style={{ margin: 0, fontSize: fontSize }}
                >
                  {record.orderStatus.description}
                </Tag>
              )}
              <Divider type="vertical" />
              {record.payStatus && (
                <Tag
                  color={record.payStatus.tagColor}
                  style={{ margin: 0, fontSize: fontSize }}
                >
                  {record.payStatus.description}
                </Tag>
              )}
              <Divider type="vertical" />
              {record.shippingStatus && (
                <Tag
                  color={record.shippingStatus.tagColor}
                  style={{ margin: 0, fontSize: fontSize }}
                >
                  {record.shippingStatus.description}
                </Tag>
              )}
            </div>
            <Typography.Text style={{ fontSize: fontSize }}>
              {`${t('order.orderListColumns.add')}: ${record.addTime}`}
            </Typography.Text>
            {record.printTime !== null && (
              <Typography.Text style={{ fontSize: fontSize }}>
                {`${t('order.orderListColumns.print')}: ${record.printTime}`}
              </Typography.Text>
            )}
          </Space>
        ),
    },
    //Column for: consignee, unit number and address
    {
      title: setFont(
        t('order.orderListColumns.orderAddress.title'),
        fontSize,
        `${t('order.orderListColumns.orderAddress.consignee')}|${t(
          'order.orderListColumns.orderAddress.mobile'
        )}|${t('order.orderListColumns.orderAddress.address')}`
      ),
      key: 'consignee|mobile|address',
      width: 250,
      render: (value: any, record: OrderData) =>
        record &&
        record.orderAddress && (
          <Space direction="vertical" size={0}>
            <Typography.Text
              style={{ fontSize: fontSize }}
            >{`${record.orderAddress.consignee} [${record.orderAddress.mobile}]`}</Typography.Text>
            <Typography.Text
              style={{
                fontSize: fontSize,
                wordBreak: 'break-word',
              }}
            >
              {record.orderAddress.fullAddress}
            </Typography.Text>
          </Space>
        ),
    },
    //Column for: payName,shippingName, expectShippingTimeName
    {
      title: setFont(
        t('order.orderListColumns.shippingInfo'),
        fontSize,
        `${t('order.orderListColumns.payName')}|${t(
          'order.orderListColumns.orderAddress.shippingName'
        )}|${t('order.orderListColumns.orderAddress.expectShippingTimeName')}`
      ),
      key: 'payName|shippingName|expectShippingTimeName',
      width: 250,
      render: (record: OrderData) => (
        <Space direction="vertical" size={0}>
          <Typography.Text
            style={{ fontSize: fontSize }}
          >{`${record.payName}`}</Typography.Text>
          <Typography.Text
            style={{ fontSize: fontSize }}
          >{`${record.orderShipping.shippingName}`}</Typography.Text>
          {record.orderShipping &&
            record.orderShipping.expectShippingTimeName && (
              <Tag
                color={
                  record.orderShipping
                    ? record.orderShipping.tagColor
                    : undefined
                }
                style={{ fontSize: fontSize }}
              >
                {`${
                  record.orderShipping
                    ? record.orderShipping.expectShippingTimeName
                    : undefined
                }`}
              </Tag>
            )}
        </Space>
      ),
    },
    //Column for: Goods amount, tips fee, coupon value, amount paid, order amount, totalAmount
    {
      title: setFont(
        t('order.orderListColumns.orderAmount.title'),
        fontSize,
        `${t('order.orderListColumns.orderAmount.goodsAmount')}|${t(
          'order.orderListColumns.orderAmount.tipsFee'
        )}|${t('order.orderListColumns.orderAmount.packFee')}|${t(
          'order.orderListColumns.orderAmount.couponsAmount'
        )}|${t('order.orderListColumns.orderAmount.totalAmount')}|${t(
          'order.orderListColumns.orderAmount.paidAmount'
        )}|${t('order.orderListColumns.orderAmount.orderAmount')}`
      ),
      key: 'goodsAmount|tipsFee|packFee|couponsAmount|totalAmount|paidAmount|orderAmount',
      width: 150,
      sorter: true,
      render: (record: OrderData) => (
        <Space direction="vertical" size={0}>
          {orderAmountLabels.map((label) => {
            return (
              <Typography.Text key={label} style={{ fontSize: fontSize }}>
                {`${t(`order.orderListColumns.orderAmount.${label}`)}: ${
                  record.orderAmount[label]
                }`}
              </Typography.Text>
            );
          })}
        </Space>
      ),
    },
    // Column for: buyer remark, seller remark, delivery remark
    {
      title: setFont(
        t('order.orderDetail.remark'),
        fontSize,
        `${t('order.orderDetail.buyerRemark')}|${t(
          'order.orderDetail.sellerRemark'
        )}|${t('order.orderDetail.deliveryRemark')}`
      ),
      width: 160,
      key: 'buyerRemark|sellerRemark|deliveryRemark',
      render: (record: OrderData) => {
        return (
          record.orderExtend && (
            <Space direction="vertical" wrap>
              {record.orderExtend.buyerRemark && (
                <Typography.Text
                  style={{ fontSize: fontSize, wordBreak: 'break-word' }}
                >
                  {`${t('order.orderDetail.buyerRemark')}: ${
                    record.orderExtend.buyerRemark
                  }`}
                </Typography.Text>
              )}
              {record.orderExtend.sellerRemark && (
                <Typography.Text
                  style={{ fontSize: fontSize, wordBreak: 'break-word' }}
                >
                  {`${t('order.orderDetail.sellerRemark')}: ${
                    record.orderExtend.sellerRemark
                  }`}
                </Typography.Text>
              )}
              {record.orderExtend.deliveryRemark && (
                <Typography.Text
                  style={{ fontSize: fontSize, wordBreak: 'break-word' }}
                >
                  {`${t('order.orderDetail.deliveryRemark')}: ${
                    record.orderExtend.deliveryRemark
                  }`}
                </Typography.Text>
              )}
            </Space>
          )
        );
      },
    },
    {
      title: setFont(t('actionsColumn.title'), fontSize),
      width: 120,
      key: 'action',
      fixed: screens.lg ? 'right' : undefined,
      render: (record: OrderData) => (
        <Space>
          <Button
            disabled={!hasPermission(actionPermissions.orderGroup.orderView)}
            type="link"
            style={{ padding: 0, fontSize: fontSize }}
            onClick={() => {
              addTab(
                '',
                `${dashboardRoute.order.detail}?order_id=${record.orderId}`
              );
            }}
          >
            {t('actionsColumn.view')}
          </Button>
          {!getDashboardStyle().isSalesForceAppType &&
            (record.printTime ? (
              <Popconfirm
                title={setFont(t('actionsColumn.printWarning'), fontSize)}
                onConfirm={() => {
                  setIsFullVersion(true);
                  getPrintoutContents([record.orderId]);
                  setPrintModalVisible(true);
                }}
                okText={t('actionsColumn.confirmation.yes')}
                cancelText={t('actionsColumn.confirmation.no')}
                disabled={
                  !hasPermission(actionPermissions.orderGroup.orderPrint)
                }
                placement="leftBottom"
              >
                <Button
                  disabled={
                    !hasPermission(actionPermissions.orderGroup.orderPrint)
                  }
                  type="link"
                  style={{ padding: 0, fontSize: fontSize }}
                >
                  {t('actionsColumn.print')}
                </Button>
              </Popconfirm>
            ) : (
              <Button
                disabled={
                  !hasPermission(actionPermissions.orderGroup.orderPrint)
                }
                type="link"
                style={{ padding: 0, fontSize: fontSize }}
                onClick={() => {
                  setIsFullVersion(true);
                  getPrintoutContents([record.orderId]);
                  setPrintModalVisible(true);
                }}
              >
                {t('actionsColumn.print')}
              </Button>
            ))}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (hasPermission(actionPermissions.orderGroup.orderView))
      getOrderEnumList();
  }, [getOrderEnumList]);

  /**
   * This popover displays, and handles the filters should the user need
   * It takes in inputs and formats it into parameters so that it can be used by getData
   */
  const advancedSearch = (
    <Drawer
      visible={showDrawer}
      closable={true}
      onClose={() => setShowDrawer(false)}
      placement="right"
      headerStyle={{ padding: '10px 3px 10px 3px' }}
      width={screens.sm ? 600 : 350}
      bodyStyle={{ padding: 10 }}
      title={t('order.orderListColumns.advancedSearch.title')}
    >
      <Form
        layout="vertical"
        form={form}
        ref={formRef}
        initialValues={{
          expectShippingTime: id || orderIds ? undefined : moment(),
          expectShippingTimeIntervalType: 'all',
          isTipsFee: 'all',
          platform: 'all',
          extension: 'all',
          rankId: 0,
        }}
      >
        <Form.Item style={{ marginBottom: 12 }}>
          <Space>
            <Button
              htmlType="submit"
              type="primary"
              onClick={() => {
                if (page !== 1) setPage(1);
                else {
                  if (typingTimeout) clearTimeout(typingTimeout);
                  setTypingTimeout(
                    setTimeout(() => getData(), GENERAL_TIMEOUT)
                  );
                }
              }}
            >
              {t('order.orderListColumns.advancedSearch.search')}
            </Button>
            <Button
              disabled={Object.values(advance).every(
                (item) =>
                  item === '' ||
                  item === moment().format(DATE_FORMAT) ||
                  item === undefined
              )}
              onClick={() => {
                setGood(undefined);
                form.resetFields();
                if (id || orderIds) {
                  setAdvance({});
                } else {
                  form.setFieldsValue({
                    expectShippingTime: moment(),
                  });
                  setAdvance(() => ({
                    expectShippingTime: moment().format(DATE_FORMAT),
                  }));
                }
                setRouteData([]);
                if (page !== 1) setPage(1);
                else {
                  if (typingTimeout) clearTimeout(typingTimeout);
                  setTypingTimeout(
                    setTimeout(() => getData(), GENERAL_TIMEOUT)
                  );
                }
              }}
            >
              {t('order.orderListColumns.advancedSearch.reset')}
            </Button>
          </Space>
        </Form.Item>
        {isSeller && (
          <Form.Item
            name="sellerId"
            label={t('order.orderListColumns.advancedSearch.sellerId')}
            style={{ marginBottom: 12 }}
          >
            <SellersDropdown
              onChange={(value: string) => {
                if (value === undefined) {
                  // 选择商品 - 清空
                  setAdvance((prev) => ({
                    ...prev,
                    sellerId: value,
                    goodsId: undefined,
                  }));

                  form.resetFields(['goodsId']);
                  setGood(undefined);
                } else {
                  setAdvance((prev) => ({
                    ...prev,
                    sellerId: value,
                  }));
                  setPaymentData([]);
                }
              }}
            />
          </Form.Item>
        )}
        <Form.Item
          name="orderSn"
          label={t('order.orderListColumns.advancedSearch.orderSn')}
          style={{ marginBottom: 12 }}
        >
          <Input
            allowClear
            onChange={(e) => {
              setAdvance((prev) => ({
                ...prev,
                orderSn: e.target.value,
              }));
            }}
          />
        </Form.Item>
        <Form.Item
          name="mobile"
          label={t('order.orderListColumns.advancedSearch.mobile')}
          style={{ marginBottom: 12 }}
        >
          <Input
            allowClear
            onChange={(e) => {
              setAdvance((prev) => ({
                ...prev,
                mobile: e.target.value,
              }));
            }}
          />
        </Form.Item>
        <Form.Item
          name="orderIdKeyword"
          label={t('order.orderListColumns.advancedSearch.orderId')}
          style={{ marginBottom: 12 }}
        >
          <InputNumber
            style={{ width: '100%' }}
            min={1}
            step="1"
            formatter={(value) => `${value}`.replace(/\D/g, '')} // Ensures only digits are displayed
            onChange={(value) => {
              setAdvance((prev) => ({
                ...prev,
                orderIdKeyword: value,
              }));
            }}
          />
        </Form.Item>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Form.Item
              name="expectShippingTime"
              label={t(
                'order.orderListColumns.advancedSearch.expectShippingTime'
              )}
              style={{ marginBottom: 12 }}
            >
              <DatePicker
                getPopupContainer={(triggerNode) =>
                  triggerNode.parentNode as HTMLElement
                }
                placeholder={t('searchPlaceholders.selectShippingDate')}
                style={{ width: '100%' }}
                onChange={(date, dateString) => {
                  setAdvance((prev) => ({
                    ...prev,
                    expectShippingTime: date ? dateString : undefined,
                  }));
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="expectShippingTimeIntervalType"
              label=" "
              style={{ marginBottom: 12 }}
            >
              <Select
                style={{ width: '100%' }}
                placeholder={t('searchPlaceholders.searchIntervalType')}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                onSelect={(value: string) => {
                  setAdvance((prev) => ({
                    ...prev,
                    expectShippingTimeInterval:
                      value === 'all' ? undefined : value,
                  }));
                }}
                optionLabelProp="key"
              >
                <Select.Option key={`${t('general.all')}`} value="all">
                  {t('general.all')}
                </Select.Option>
                {orderEnumObj &&
                  orderEnumObj['timeInterval'].map((type) => (
                    <Select.Option key={type.description} value={type.code}>
                      {type.description}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="regions"
          label={t('order.orderListColumns.advancedSearch.regions')}
          style={{ marginBottom: 12 }}
        >
          <RegionDropdown
            isMultiple={true}
            onFocusFetch={true}
            columnWidth={250}
            onChange={(values) => {
              if (values && values.length) {
                setAdvance((prev) => ({
                  ...prev,
                  countryId: values,
                }));
                const country: number[] = [];
                const prov: number[] = [];
                const city: number[] = [];
                const district: number[] = [];
                const street: number[] = [];
                if (formRef.current && form.getFieldValue('regions')) {
                  form.getFieldValue('regions').forEach((array: string[]) => {
                    let x = array.map((i) => Number(i));
                    if (x.length === 1) {
                      country.push(x[0]);
                    } else if (x.length === 2) {
                      prov.push(x[1]);
                    } else if (x.length === 3) {
                      city.push(x[2]);
                    } else if (x.length === 4) {
                      district.push(x[3]);
                    } else {
                      street.push(x[4]);
                    }
                  });
                }
                let finalURL = '';
                street.length &&
                  street.forEach((val: number) => {
                    finalURL += '&streetIds=' + val;
                  });
                district.length &&
                  district.forEach((val: number) => {
                    finalURL += '&districtIds=' + val;
                  });
                city.length &&
                  city.forEach((val: number) => {
                    finalURL += '&cityIds=' + val;
                  });
                prov.length &&
                  prov.forEach((val: number) => {
                    finalURL += '&provinceIds=' + val;
                  });
                country.length &&
                  country.forEach((val: number) => {
                    finalURL += '&countryIds=' + val;
                  });
                setAdvance((prev) => ({
                  ...prev,
                  regionURL: finalURL,
                }));
              }
            }}
          />
        </Form.Item>
        <Form.Item
          name="shippingStatus"
          label={t('order.orderListColumns.advancedSearch.shippingStatus')}
          style={{ marginBottom: 12 }}
        >
          <Select
            placeholder={t('searchPlaceholders.selectStatus')}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            mode="multiple"
            maxTagCount="responsive"
            onChange={(value: string[]) => {
              setAdvance((prev) => ({
                ...prev,
                shippingStatus: value,
              }));
            }}
          >
            {orderEnumObj &&
              orderEnumObj['shippingStatus'].map((type) => (
                <Select.Option key={type.code} value={type.code}>
                  {type.description}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="payStatus"
          label={t('order.orderListColumns.advancedSearch.payStatus')}
          style={{ marginBottom: 12 }}
        >
          <Select
            allowClear={true}
            placeholder={t('searchPlaceholders.selectStatus')}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            mode="multiple"
            maxTagCount="responsive"
            onChange={(value: string[]) => {
              setAdvance((prev) => ({
                ...prev,
                payStatus: value,
              }));
            }}
          >
            {orderEnumObj &&
              orderEnumObj['payStatus'].map((type) => (
                <Select.Option key={type.code} value={type.code}>
                  {type.description}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Row gutter={[8, 8]}>
          <Col span={8}>
            <Form.Item
              name="routeDate"
              label={t('order.orderListColumns.advancedSearch.route')}
              style={{ marginBottom: 12 }}
            >
              <DatePicker
                getPopupContainer={(triggerNode) =>
                  triggerNode.parentNode as HTMLElement
                }
                placeholder={t('searchPlaceholders.selectShippingDate')}
                style={{ width: '100%' }}
                onChange={(date, dateString) => {
                  setAdvance((prev) => ({
                    ...prev,
                    routeDate: date ? dateString : undefined,
                  }));
                  if (dateString === '') {
                    setAdvance((prev) => ({
                      routeId: undefined,
                      routeDate: undefined,
                      excludeRouteIds: undefined,
                    }));
                    form.resetFields(['routeId', 'excludeRouteIds']);
                    setRouteData([]);
                  }
                  dateString && getRouteData(dateString);
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            {routeData && routeData.length > 0 && (
              <Form.Item name="routeId" label=" " style={{ marginBottom: 12 }}>
                <Select
                  style={{ width: '100%' }}
                  placeholder={t('searchPlaceholders.searchRouteId')}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  onSelect={(value: number, option: any) => {
                    setAdvance((prev) => ({
                      ...prev,
                      routeId: value,
                      routeName: option.key.split('@')[0],
                    }));
                  }}
                >
                  {routeData.map((route, i) => (
                    <Select.Option
                      key={`${route.routeName}@${i}`}
                      value={route.id}
                    >
                      {route.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Col>

          <Col span={8}>
            {routeData && routeData.length > 0 && (
              <Form.Item
                name="excludeRouteIds"
                label=" "
                style={{ marginBottom: 12 }}
              >
                <Select
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder={t('searchPlaceholders.searchExcludeRouteIds')}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  onSelect={(value: number, option: any) => {
                    setAdvance((prev) => ({
                      ...prev,
                      excludeRouteIds: value,
                    }));
                  }}
                >
                  {routeData.map((route, i) => (
                    <Select.Option
                      key={`${route.routeName}@${i}`}
                      value={route.id}
                    >
                      {route.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Col>
        </Row>
        <Form.Item
          name="shippingId"
          label={t('order.orderListColumns.advancedSearch.shippingId')}
          style={{ marginBottom: 12 }}
        >
          <Select
            placeholder={t('searchPlaceholders.searchShippingId')}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            onFocus={() => {
              if (!shippingData.length) getShippingData();
            }}
            showSearch
            onSearch={(value) => {
              if (typingTimeout) clearTimeout(typingTimeout);
              setTypingTimeout(
                setTimeout(() => getShippingData(value), EXTENDED_TIMEOUT)
              );
            }}
            allowClear
            onChange={(value: string) => {
              setAdvance((prev) => ({
                ...prev,
                shippingId: value,
              }));
            }}
            optionLabelProp="key"
            filterOption={false}
          >
            {shippingData.map((shipping) => (
              <Select.Option
                key={`${shipping.shippingName} (${shipping.shippingId})`}
                value={String(shipping.shippingId)}
              >
                <div>
                  <span
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography.Text type="secondary">
                      {t('order.orderListColumns.advancedSearch.shippingId')}
                    </Typography.Text>
                    <Typography.Text type="secondary">
                      {t('order.orderListColumns.advancedSearch.shippingName')}
                    </Typography.Text>
                  </span>
                  <span
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography>{shipping.shippingId}</Typography>
                    <Typography>{shipping.shippingName}</Typography>
                  </span>
                </div>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="rankId"
          label={t('order.orderListColumns.advancedSearch.userRank')}
          style={{ marginBottom: 12 }}
        >
          <UserRankDropdown
            onChange={(value: string) => {
              setAdvance((prev) => ({
                ...prev,
                rankId: value || undefined,
              }));
            }}
          />
        </Form.Item>
        {!getDashboardStyle().isSalesForceAppType && (
          <Form.Item
            name="salesPersonId"
            label={t('salesperson.title')}
            style={{ marginBottom: 12 }}
          >
            <SalespersonDropdown
              onChange={(value: string) => {
                setAdvance((prev) => ({
                  ...prev,
                  salesPersonId: value || undefined,
                }));
              }}
            />
          </Form.Item>
        )}
        <Form.Item
          name="consignee"
          label={t('order.orderListColumns.advancedSearch.consignee')}
          style={{ marginBottom: 12 }}
        >
          <Input
            allowClear
            onChange={(e) => {
              setAdvance((prev) => ({
                ...prev,
                consignee: e.target.value,
              }));
            }}
          />
        </Form.Item>
        <Form.Item
          name="goodsName"
          label={t('order.orderListColumns.advancedSearch.goodsName')}
          style={{ marginBottom: 12 }}
        >
          <Input
            allowClear
            onChange={(e) => {
              setAdvance((prev) => ({
                ...prev,
                goodsName: e.target.value,
              }));
            }}
          />
        </Form.Item>
        {(!isSeller || (isSeller && advance.sellerId)) && (
          <Form.Item
            name="goodsId"
            label={t('order.orderListColumns.advancedSearch.goods')}
            style={{ marginBottom: 12 }}
          >
            <div>
              {good ? (
                <>
                  <Typography.Text
                    ellipsis={{ tooltip: good.fullGoodsName }}
                    style={{ width: 250 }}
                  >
                    {good.fullGoodsName}
                  </Typography.Text>
                  <CloseCircleOutlined
                    style={{ padding: 10 }}
                    onClick={() => {
                      form.resetFields(['goodsId']);
                      setGood(undefined);
                    }}
                  />
                </>
              ) : (
                <></>
              )}
              {
                <Button onClick={() => setSelectGoodsVisible(true)}>
                  {t('actionsColumn.selectGood')}
                </Button>
              }
            </div>
          </Form.Item>
        )}
        <Form.Item
          name="address"
          label={t('order.orderListColumns.advancedSearch.address')}
          style={{ marginBottom: 12 }}
        >
          <Input
            allowClear
            onChange={(e) => {
              setAdvance((prev) => ({
                ...prev,
                address: e.target.value,
              }));
            }}
          />
        </Form.Item>
        <Form.Item
          name="postalCode"
          label={t('order.orderListColumns.advancedSearch.postalCode')}
          style={{ marginBottom: 12 }}
        >
          <Input
            onChange={(e) => {
              setAdvance((prev) => ({
                ...prev,
                postalCode: e.target.value,
              }));
            }}
          />
        </Form.Item>
        <Form.Item
          name="buzzerCode"
          label={t('order.orderListColumns.advancedSearch.buzzerCode')}
          style={{ marginBottom: 12 }}
        >
          <Input
            allowClear
            onChange={(e) => {
              setAdvance((prev) => ({
                ...prev,
                buzzerCode: e.target.value,
              }));
            }}
          />
        </Form.Item>
        <Form.Item
          name="email"
          label={t('order.orderListColumns.advancedSearch.email')}
          style={{ marginBottom: 12 }}
        >
          <Input
            allowClear
            onChange={(e) => {
              setAdvance((prev) => ({
                ...prev,
                email: e.target.value,
              }));
            }}
          />
        </Form.Item>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Form.Item
              name="startAmount"
              label={t('order.orderListColumns.advancedSearch.orderAmount')}
              style={{ marginBottom: 12 }}
            >
              <InputNumber
                type="number"
                min={0}
                placeholder={t('general.min')}
                style={{ width: '100%' }}
                onChange={(value) => {
                  setAdvance((prev) => ({
                    ...prev,
                    startAmount: value || undefined,
                  }));
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="endAmount" label=" " style={{ marginBottom: 12 }}>
              <InputNumber
                type="number"
                min={0}
                placeholder={t('general.max')}
                style={{ width: '100%' }}
                onChange={(value) => {
                  setAdvance((prev) => ({
                    ...prev,
                    endAmount: value || undefined,
                  }));
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="expectShippingTimeInterval"
          label={t(
            'order.orderListColumns.advancedSearch.expectShippingTimeInterval'
          )}
          style={{ marginBottom: 12 }}
        >
          <RangePicker
            getPopupContainer={(triggerNode) =>
              triggerNode.parentNode as HTMLElement
            }
            style={{ width: '100%' }}
            format={DATE_FORMAT}
            onChange={(values) => {
              values
                ? setAdvance((prev) => ({
                    ...prev,
                    startExpectShippingTime: moment(values[0]).format(
                      DATE_FORMAT
                    ),
                    endExpectShippingTime: moment(values[1]).format(
                      DATE_FORMAT
                    ),
                  }))
                : setAdvance((prev) => ({
                    ...prev,
                    startExpectShippingTime: undefined,
                    endExpectShippingTime: undefined,
                  }));
            }}
            placeholder={[
              t('order.orderListColumns.advancedSearch.startDate'),
              t('order.orderListColumns.advancedSearch.endDate'),
            ]}
          />
        </Form.Item>
        <Form.Item
          name="addTimeInterval"
          label={t('order.orderListColumns.advancedSearch.addTimeInterval')}
          style={{ marginBottom: 12 }}
        >
          <RangePicker
            getPopupContainer={(triggerNode) =>
              triggerNode.parentNode as HTMLElement
            }
            style={{ width: '100%' }}
            showTime={{
              format: 'HH:mm:ss',
              hideDisabledOptions: true,
              defaultValue: [
                moment('00:00:00', 'HH:mm:ss'),
                moment('00:00:00', 'HH:mm:ss'),
              ],
            }}
            format={DATE_TIME_FORMAT}
            onChange={(values) => {
              values
                ? setAdvance((prev) => ({
                    ...prev,
                    startAddTime: moment(values[0]).format(DATE_TIME_FORMAT),
                    endAddTime: moment(values[1]).format(DATE_TIME_FORMAT),
                  }))
                : setAdvance((prev) => ({
                    ...prev,
                    startAddTime: undefined,
                    endAddTime: undefined,
                  }));
            }}
            placeholder={[
              t('order.orderListColumns.advancedSearch.startDate'),
              t('order.orderListColumns.advancedSearch.endDate'),
            ]}
          />
        </Form.Item>
        <Form.Item
          name="printTimeInterval"
          label={t('order.orderListColumns.advancedSearch.printTimeInterval')}
          style={{ marginBottom: 12 }}
        >
          <RangePicker
            getPopupContainer={(triggerNode) =>
              triggerNode.parentNode as HTMLElement
            }
            style={{ width: '100%' }}
            showTime={{
              format: 'HH:mm:ss',
              hideDisabledOptions: true,
              defaultValue: [
                moment('00:00:00', 'HH:mm:ss'),
                moment('00:00:00', 'HH:mm:ss'),
              ],
            }}
            format={DATE_TIME_FORMAT}
            onChange={(values) => {
              values
                ? setAdvance((prev) => ({
                    ...prev,
                    startPrintTime: moment(values[0]).format(DATE_TIME_FORMAT),
                    endPrintTime: moment(values[1]).format(DATE_TIME_FORMAT),
                  }))
                : setAdvance((prev) => ({
                    ...prev,
                    startPrintTime: undefined,
                    endPrintTime: undefined,
                  }));
            }}
            placeholder={[
              t('order.orderListColumns.advancedSearch.startDate'),
              t('order.orderListColumns.advancedSearch.endDate'),
            ]}
          />
        </Form.Item>
        <Form.Item
          name="isTipsFee"
          label={t('order.orderListColumns.advancedSearch.isTipsFee')}
          style={{ marginBottom: 12 }}
        >
          <Select
            style={{ width: '100%' }}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            onChange={(value: string) => {
              setAdvance((prev) => ({
                ...prev,
                isTipsFee: value === 'all' ? undefined : value.toString(),
              }));
            }}
            filterOption={false}
            optionLabelProp="key"
            placeholder={`${t('general.pleaseSelect')}`}
          >
            <Select.Option key={`${t('general.all')}`} value="all">
              {t('general.all')}
            </Select.Option>
            <Select.Option
              key={`${t('general.booleanToStatus.true')}`}
              value={true}
            >
              {t('general.booleanToStatus.true')}
            </Select.Option>
            <Select.Option
              key={`${t('general.booleanToStatus.false')}`}
              value={false}
            >
              {t('general.booleanToStatus.false')}
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="payId"
          label={t('order.orderListColumns.advancedSearch.payId')}
          style={{ marginBottom: 12 }}
        >
          <Select
            placeholder={t('general.pleaseSelect')}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            onFocus={() => {
              if (paymentData.length === 0) getPaymentData();
            }}
            showSearch
            onSearch={(value) => {
              if (typingTimeout) clearTimeout(typingTimeout);
              setTypingTimeout(
                setTimeout(() => getPaymentData(value), EXTENDED_TIMEOUT)
              );
            }}
            onChange={(value: string) => {
              setAdvance((prev) => ({
                ...prev,
                payId: value,
              }));
            }}
            filterOption={false}
          >
            {paymentData.map((payment) => (
              <Select.Option key={payment.payId} value={payment.payId}>
                {payment.payName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="orderStatus"
          label={t('order.orderListColumns.advancedSearch.orderStatus')}
          style={{ marginBottom: 12 }}
        >
          <Select
            placeholder={t('searchPlaceholders.selectStatus')}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            mode="multiple"
            maxTagCount="responsive"
            onChange={(value: string[]) => {
              setAdvance((prev) => ({
                ...prev,
                orderStatus: value,
              }));
            }}
          >
            {orderEnumObj &&
              orderEnumObj['orderStatus'].map((type) => (
                <Select.Option key={type.code} value={type.code}>
                  {type.description}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="extension"
          label={t('order.orderListColumns.advancedSearch.extension')}
          style={{ marginBottom: 12 }}
        >
          <Select
            placeholder={t('searchPlaceholders.searchExtension')}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            onSelect={(value: string) => {
              setAdvance((prev) => ({
                ...prev,
                extension: value === 'all' ? undefined : value,
              }));
            }}
            optionLabelProp="key"
          >
            <Select.Option key={`${t('general.all')}`} value="all">
              {t('general.all')}
            </Select.Option>
            {orderEnumObj &&
              orderEnumObj['extension'].map((type) => (
                <Select.Option key={type.description} value={type.code}>
                  {type.description}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="platform"
          label={t('order.orderListColumns.advancedSearch.platform')}
          style={{ marginBottom: 12 }}
        >
          <Select
            placeholder={t('searchPlaceholders.searchPlatform')}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            onSelect={(value: string) => {
              setAdvance((prev) => ({
                ...prev,
                platform: value === 'all' ? undefined : value,
              }));
            }}
            optionLabelProp="key"
          >
            <Select.Option key={`${t('general.all')}`} value="all">
              {t('general.all')}
            </Select.Option>
            {orderEnumObj &&
              orderEnumObj['platform'].map((type) => (
                <Select.Option key={type.description} value={type.code}>
                  {type.description}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <Space>
            <Button
              htmlType="submit"
              type="primary"
              onClick={() => {
                if (page !== 1) setPage(1);
                else {
                  if (typingTimeout) clearTimeout(typingTimeout);
                  setTypingTimeout(
                    setTimeout(() => getData(), GENERAL_TIMEOUT)
                  );
                }
              }}
            >
              {t('order.orderListColumns.advancedSearch.search')}
            </Button>
            <Button
              disabled={Object.values(advance).every(
                (item) =>
                  item === '' ||
                  item === moment().format(DATE_FORMAT) ||
                  item === undefined
              )}
              onClick={() => {
                setGood(undefined);
                form.resetFields();
                if (id || orderIds) {
                  setAdvance({});
                } else {
                  form.setFieldsValue({
                    expectShippingTime: moment(),
                  });
                  setAdvance(() => ({
                    expectShippingTime: moment().format(DATE_FORMAT),
                  }));
                }
                setRouteData([]);
                if (page !== 1) setPage(1);
                else {
                  if (typingTimeout) clearTimeout(typingTimeout);
                  setTypingTimeout(
                    setTimeout(() => getData(), GENERAL_TIMEOUT)
                  );
                }
              }}
            >
              {t('order.orderListColumns.advancedSearch.reset')}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Drawer>
  );

  /**
   * @param pagination Current pagination state
   * @param filters    Current filter state
   * @param sorter     Current sorter state
   * @param extra      Current data source and action
   */
  const onTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<OrderData> | SorterResult<OrderData>[],
    extra: {
      currentDataSource: OrderData[];
      action: 'paginate' | 'sort' | 'filter';
    }
  ) => {
    if (extra.action === 'sort' && !Array.isArray(sorter)) {
      setSortOrder(
        sorter.order === 'ascend'
          ? 'ascend'
          : sorter.order === 'descend'
          ? 'descend'
          : undefined
      );
      setSortValue(
        sorter.columnKey === 'orderId'
          ? 'ORDER_ID'
          : sorter.columnKey ===
            'userName|orderStatus|payStatus|shippingStatus|addTime|printTime'
          ? 'USER_ID'
          : sorter.columnKey ===
            'goodsAmount|tipsFee|packFee|couponsAmount|totalAmount|paidAmount|orderAmount'
          ? 'GOODS_AMOUNT'
          : ''
      );
    }
  };

  return fourZeroThree ? (
    <FourZeroThree />
  ) : fiveHundred ? (
    <FiveHundred />
  ) : (
    <>
      <Space>
        {id ? (
          <Typography.Title level={4} style={{ marginBottom: 12 }}>
            {t('users.userDetailPage.orders')}
          </Typography.Title>
        ) : (
          <Typography.Title level={3} style={{ fontWeight: 500 }}>
            {t('order.orderList')}
          </Typography.Title>
        )}
        {getDashboardStyle().isSellerSwitch && (
          <Switch
            defaultChecked={isSeller}
            loading={isLoading}
            onChange={(checked) => {
              setPaymentData([]);
              // reset Good
              setGood(undefined);
              form.resetFields(['goodsId', 'sellerId']);

              setAdvance((prev) => ({
                ...prev,
                sellerId: undefined,
                goodsId: undefined,
              }));
              if (typingTimeout) clearTimeout(typingTimeout);
              setTypingTimeout(
                setTimeout(() => {
                  setIsSeller(checked);
                  setPage(1);
                }, EXTENDED_TIMEOUT)
              );
            }}
            style={{ marginBottom: 12 }}
            checkedChildren={t('goods.goodsListColumns.seller')}
            unCheckedChildren={t('goods.goodsListColumns.self')}
          />
        )}
      </Space>
      <Tabs
        onTabClick={(key) => {
          if (key !== navType) {
            setSelectedRowKeys([]);
            setSelectedRows([]);
            setNavType(key);
            setPage(1);
          }
        }}
      >
        {orderEnumObj &&
          orderEnumObj['navType'].length &&
          orderEnumObj['navType'].map((type) => (
            <TabPane key={type.code} tab={type.description} />
          ))}
      </Tabs>
      <TableToolbar
        leftElement={
          <Dropdown
            trigger={['click']}
            overlay={
              <Menu>
                <Menu.Item key="add" onClick={() => setOrderModalVisible(true)}>
                  {isSeller
                    ? t('order.add/editOrder.addSellerOrderTitle')
                    : t('order.add/editOrder.addTitle')}
                </Menu.Item>
                {/*** 用户详情页面不显示 */}
                {!id && (
                  <Menu.Item
                    key="batchAdd"
                    onClick={() => setOrderBatchModalVisible(true)}
                  >
                    {isSeller
                      ? t('order.add/editOrder.addBatchSellerOrderTitle')
                      : t('order.add/editOrder.addBatchTitle')}
                  </Menu.Item>
                )}
              </Menu>
            }
          >
            <Button
              disabled={
                isLoading ||
                !hasPermission(actionPermissions.orderGroup.orderManage)
              }
              icon={<PlusOutlined />}
            >
              {isSeller
                ? t('order.add/editOrder.addSellerOrderTitle')
                : t('order.add/editOrder.addTitle')}
            </Button>
          </Dropdown>
        }
        search={(keyword) => {
          setKeyword(keyword);
          setPage(1);
        }}
        searchPlaceholder={t('searchPlaceholders.searchOrder')}
        setFontSize={setFontSize}
        fontSize={fontSize}
        tableSize={tableSize}
        setTableSize={setTableSize}
        refresh={() => getData()}
        totalItems={total}
        columns={columns}
        columnKeys={columnKeys}
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        rows={orderData.map((order) => ({
          orderId: order.orderId ? order.orderId : '',
          orderSn: order.orderSn ? order.orderSn : '',
          shopName:
            order.seller && order.seller.shopName ? order.seller.shopName : '',
          extensions:
            order.extension && order.extension.code ? order.extension.code : '',
          sequenceLabel: order.sequenceLabel ? order.sequenceLabel : '',
          orderStatus: order.orderStatus ? order.orderStatus.code : '',
          payStatus: order.payStatus ? order.payStatus.code : '',
          shippingStatus: order.shippingStatus ? order.shippingStatus.code : '',
          addTime: order.addTime ? order.addTime : '',
          printTime: order.printTime ? order.printTime : '',
          consignee:
            order.orderAddress && order.orderAddress.consignee
              ? order.orderAddress.consignee
              : '',
          mobile:
            order.orderAddress && order.orderAddress.mobile
              ? order.orderAddress.mobile
              : '',
          address:
            order.orderAddress && order.orderAddress.address
              ? order.orderAddress.address
              : '',

          payName: order.payName ? order.payName : '',
          expectShippingTimeName:
            order.orderShipping && order.orderShipping.expectShippingTimeName
              ? order.orderShipping.expectShippingTimeName
              : '',
          shippingName:
            order.orderAddress && order.orderShipping.shippingName
              ? order.orderShipping.shippingName
              : '',
          goodsAmount:
            order.orderAmount && order.orderAmount.goodsAmount
              ? order.orderAmount.goodsAmount
              : '',
          totalAmount:
            order.orderAmount && order.orderAmount.totalAmount
              ? order.orderAmount.totalAmount
              : '',
          tipsFee:
            order.orderAmount && order.orderAmount.tipsFee
              ? order.orderAmount.tipsFee
              : '',
          packFee:
            order.orderAmount && order.orderAmount.packFee
              ? order.orderAmount.packFee
              : '',
          couponsAmount:
            order.orderAmount && order.orderAmount.couponsAmount
              ? order.orderAmount.couponsAmount
              : '',
          paidAmount:
            order.orderAmount && order.orderAmount.paidAmount
              ? order.orderAmount.paidAmount
              : '',
          orderAmount:
            order.orderAmount && order.orderAmount.orderAmount
              ? order.orderAmount.orderAmount
              : '',
          sellerRemark:
            order.orderExtend && order.orderExtend.sellerRemark
              ? order.orderExtend.sellerRemark
              : '',
          buyerRemark:
            order.orderExtend && order.orderExtend.buyerRemark
              ? order.orderExtend.buyerRemark
              : '',
          userName: order.user ? order.user.userName : '',
        }))}
        exportConfig={
          hasPermission(actionPermissions.orderGroup.orderView)
            ? {
                fileName: 'ORDER_LIST',
                url: getDashboardStyle().isSalesForceAppType
                  ? undefined
                  : getExportUrl(getFormParams()),
              }
            : undefined
        }
        advancedSearch={
          <Badge
            count={Object.keys(advance).reduce((accumulator, obj) => {
              if (advance[obj as keyof typeof advance]) {
                return accumulator + 1;
              }

              return accumulator;
            }, 0)}
          >
            <Button
              icon={<FilterOutlined />}
              onClick={() => setShowDrawer(showDrawer ? false : true)}
            >
              {t('order.orderListColumns.advancedSearch.title')}
            </Button>
            {advancedSearch}
          </Badge>
        }
      />
      <Table<OrderData>
        dataSource={orderData}
        rowKey={(order) => order.orderId}
        columns={columns.filter((x) =>
          selectedColumns.includes(x.key?.toString() ?? '')
        )}
        components={vt}
        scroll={{
          y: 600,
          x: 1200,
        }}
        size={tableSize}
        loading={isLoading}
        pagination={{
          total: total,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total, range) =>
            t('general.paginationTotal', {
              start: range[0],
              end: range[1],
              total: total,
            }),
          size: 'small',
          defaultPageSize: pageSize,
          onChange: (page, pSize) => {
            setPage(page);
            setPageSize(pSize || pageSize);
          },
          current: page,
          pageSizeOptions: ['10', '25', '50', '100', '150', '200'],
        }}
        rowSelection={{
          selectedRowKeys,
          onChange: (
            selectedRowKeys: React.Key[],
            selectedRows: OrderData[]
          ) => {
            setSelectedRowKeys(selectedRowKeys);
            setSelectedRows(selectedRows);
          },
        }}
        onChange={onTableChange}
      />
      {selectedRowKeys.length > 0 && (
        <TableFooterToolbar
          orderInfo={{
            userId: id,
            actionList: [
              'EXPECT_SHIPPING_TIME',
              'IMPORT',
              'IMPORT_TASK',
              'CONFIRM',
              'PAY',
              'PREPARE',
              'CANCEL_PREPARE',
              'SHIP',
              'RECEIVE',
              'CANCEL_RECEIVE',
            ],
            routeId: advance.routeId ? advance.routeId : undefined,
            orderEnumList: orderEnumObj,
          }}
          selectedRows={selectedRows.map((order) => ({
            orderId: order.orderId ? order.orderId : '',
            orderSn: order.orderSn ? order.orderSn : '',
            shopName:
              order.seller && order.seller.shopName
                ? order.seller.shopName
                : '',
            extensions:
              order.extension && order.extension.code
                ? order.extension.code
                : '',
            sequenceLabel: order.sequenceLabel ? order.sequenceLabel : '',
            orderStatus: order.orderStatus ? order.orderStatus.code : '',
            payStatus: order.payStatus ? order.payStatus.code : '',
            shippingStatus: order.shippingStatus
              ? order.shippingStatus.code
              : '',
            addTime: order.addTime ? order.addTime : '',
            printTime: order.printTime ? order.printTime : null,
            consignee:
              order.orderAddress && order.orderAddress.consignee
                ? order.orderAddress.consignee
                : '',
            mobile:
              order.orderAddress && order.orderAddress.mobile
                ? order.orderAddress.mobile
                : '',
            address:
              order.orderAddress && order.orderAddress.address
                ? order.orderAddress.address
                : '',

            payName: order.payName ? order.payName : '',
            expectShippingTimeName:
              order.orderShipping && order.orderShipping.expectShippingTimeName
                ? order.orderShipping.expectShippingTimeName
                : '',
            totalAmount:
              order.orderAmount && order.orderAmount.totalAmount
                ? order.orderAmount.totalAmount
                : '',
            shippingName:
              order.orderAddress && order.orderShipping.shippingName
                ? order.orderShipping.shippingName
                : '',
            goodsAmount:
              order.orderAmount && order.orderAmount.goodsAmount
                ? order.orderAmount.goodsAmount
                : '',
            tipsFee:
              order.orderAmount && order.orderAmount.tipsFee
                ? order.orderAmount.tipsFee
                : '',
            couponsAmount:
              order.orderAmount && order.orderAmount.couponsAmount
                ? order.orderAmount.couponsAmount
                : '',
            paidAmount:
              order.orderAmount && order.orderAmount.paidAmount
                ? order.orderAmount.paidAmount
                : '',
            orderAmount:
              order.orderAmount && order.orderAmount.orderAmount
                ? order.orderAmount.orderAmount
                : '',
            sellerRemark:
              order.orderExtend && order.orderExtend.sellerRemark
                ? order.orderExtend.sellerRemark
                : '',
            buyerRemark:
              order.orderExtend && order.orderExtend.buyerRemark
                ? order.orderExtend.buyerRemark
                : '',
            userName: order.user ? order.user.userName : '',
          }))}
          funct={{
            refresh: () => getData(),
            exportConfig: hasPermission(actionPermissions.orderGroup.orderView)
              ? { fileName: 'ORDER_LIST' }
              : undefined,
          }}
          typeSpecificActions="order"
          general={
            getDashboardStyle().isSalesForceAppType
              ? undefined
              : { advance: advance, setAdvance: setAdvance }
          }
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          columns={columns.filter((x) =>
            selectedColumns.includes(x.key?.toString() ?? '')
          )}
        />
      )}
      <SelectGoodsModal
        firstLoad={true}
        newGoodObjs={selectedGoodObjs}
        setNewGoodObjs={setSelectedGoodObjs}
        visible={selectGoodsVisible}
        setVisible={setSelectGoodsVisible}
        sellerId={advance.sellerId ? parseInt(advance.sellerId) : 0}
        multiSelectFeature={false}
      />
      <PrintModal
        modalLoading={modalLoading}
        mode="order"
        isFullVersion={isFullVersion}
        setIsFullVersion={setIsFullVersion}
        data={allOrderPrintoutData}
        setData={setAllOrderPrintoutData}
        visible={printModalVisible}
        setVisible={setPrintModalVisible}
      />
      <OrderModal
        visible={orderModalVisible}
        setVisible={setOrderModalVisible}
        isSeller={isSeller}
        enums={orderEnumObj}
        callBack={() => getData()}
        userId={id}
      />
      <BatchOrderModal
        visible={orderBatchModalVisible}
        setVisible={setOrderBatchModalVisible}
        isSeller={isSeller}
        enums={orderEnumObj}
        callBack={() => getData()}
      />
    </>
  );
};

export default UserOrderTable;
