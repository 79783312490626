import { Button, Form, FormInstance, Popover, Switch } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { hasPermission } from '../../utils/hasPermission';
import { actionPermissions } from '../../constants/actionPermissions';
import PrintModal from '../PrintModal';
import { OrderClaimPrintoutData, PrintType } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import { onExportToExcel } from '../table/TableExport';
import { RightOutlined } from '@ant-design/icons';
import getDashboardStyle from '../../utils/getDashboardStyle';

type BatchClaimTableFooterToolbarProps = {
  form: FormInstance<any>;
  columns?: ColumnsType<any>;
  selectedRows: any[];
  selectedRowKeys?: any[];
  setSelectedRowKeys?: React.Dispatch<React.SetStateAction<React.Key[]>>;
  funct: {
    refresh?: Function;
    deleteFunc?: () => void;
    exportConfig?: { fileName: string; url?: string };
  };
};

const BatchClaimTableFooterToolbar = ({
  form,
  columns,
  selectedRows,
  selectedRowKeys,
  setSelectedRowKeys,
  funct,
}: BatchClaimTableFooterToolbarProps) => {
  //General Components
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<{
    [key: string]: any;
  }>({});
  const [printModalVisible, setPrintModalVisible] = useState(false);
  const [printoutData, setPrintoutData] = useState<PrintType | undefined>(
    undefined
  );

  //Makes a request and get claim printout list
  const getPrintoutContents = () => {
    setIsLoading(true);
    getDataWithAuthToken('claim/print', {
      params: {
        claimIds: selectedRowKeys?.toString(),
      },
    }).then((response: any) => {
      if (response && response.goodStatus) {
        let responseData: OrderClaimPrintoutData[] = response.data.list;
        if (responseData.length > 0) {
          setPrintoutData({ orderClaimsData: responseData });
          setIsLoading(false);
        } else {
          setIsLoading(false);
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      } else {
        setIsLoading(false);
        alertMessage(
          'error',
          response?.msg || t('general.noResponse'),
          response?.data || undefined
        );
      }
    });
  };

  const handleUseClaim = () => {
    setIsLoading(true);
    postDataWithAuthToken(`claim/batch/edit`, {
      claimIds: selectedRowKeys,
      isUsed: form.getFieldValue('isUsed'),
    })
      .then((response) => {
        if (response && response.goodStatus) {
          alertMessage('success', t('order.alerts.orderClaimUpdateUseStatus'));
          funct.refresh && funct.refresh();
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        {getDashboardStyle().isWholeSaleAppType && (
          <Popover
            getPopupContainer={(triggerNode) =>
              triggerNode.parentNode as HTMLElement
            }
            trigger="click"
            placement="rightTop"
            onVisibleChange={() => {
              form.resetFields();
            }}
            content={
              <div
                style={{
                  padding: 10,
                  display: 'flex',
                  flexDirection: 'column',
                  width: 'max-content',
                }}
              >
                <Form.Item
                  name="isUsed"
                  label={t('order.claimListColumns.isUsedStatus')}
                  initialValue={true}
                  valuePropName="checked"
                >
                  <Switch
                    onChange={(checked) => {
                      checked &&
                        setFormData((prev: any) => ({
                          ...prev,
                          isOnSale: checked,
                        }));
                    }}
                  />
                </Form.Item>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Button
                    loading={isLoading}
                    disabled={isLoading}
                    size="small"
                    type="primary"
                    onClick={() => {
                      handleUseClaim();
                      form.resetFields();
                    }}
                  >
                    {t('goods.goodsBatch.ok')}
                  </Button>
                  <Button
                    style={{ marginLeft: 5 }}
                    size="small"
                    disabled={!formData.isOnSale}
                    onClick={() => {
                      form.resetFields();
                      setFormData({});
                    }}
                  >
                    {t('general.reset')}
                  </Button>
                </div>
              </div>
            }
          >
            <Button
              block
              type="text"
              style={{
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              {t('order.claimListColumns.isUsedStatus')}
              <RightOutlined />
            </Button>
          </Popover>
        )}
        <Button
          block
          type="link"
          loading={isLoading}
          disabled={!hasPermission(actionPermissions.orderGroup.orderClaimView)}
          onClick={() => {
            getPrintoutContents();
            setPrintModalVisible(true);
          }}
        >
          {t('actionsColumn.print')}
        </Button>
        {selectedRows && columns && funct.exportConfig && (
          <Button
            block
            type="text"
            onClick={() => {
              onExportToExcel(columns, selectedRows, funct.exportConfig);
            }}
            style={{ textAlign: 'left' }}
          >
            {t('actionsColumn.exportSelected')}
          </Button>
        )}
      </div>
      <PrintModal
        modalLoading={isLoading}
        mode="orderClaim"
        data={printoutData}
        setData={setPrintoutData}
        visible={printModalVisible}
        setVisible={setPrintModalVisible}
      />
    </>
  );
};

export default BatchClaimTableFooterToolbar;
