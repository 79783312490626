import { CheckOutlined, CloseOutlined, DownOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Dropdown,
  Form,
  Menu,
  Popconfirm,
  Row,
  Space,
  Tag,
  Typography,
} from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { actionPermissions } from '../../../constants/actionPermissions';
import { BLACK, GREEN1, PRIMARY, RED1 } from '../../../constants/color';
import { dashboardRoute } from '../../../constants/pathname';
import { useTab } from '../../../hooks/useTab';
import {
  OrderClaimData,
  OrderClaimPrintoutData,
  PrintType,
} from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../../utils/axiosRequest';
import { hasPermission } from '../../../utils/hasPermission';
import PrintModal from '../../PrintModal';
import getDashboardStyle from '../../../utils/getDashboardStyle';

type ClaimBasicInfoProps = {
  orderClaimDetail: OrderClaimData;
  onRefresh: () => void;
};

/**
 * Displays Claim Information
 *
 */
const ClaimBasicInfo = ({
  orderClaimDetail,
  onRefresh,
}: ClaimBasicInfoProps) => {
  const { t } = useTranslation();
  const isSubscribed = useRef(true);
  const { addTab, removeTab } = useTab();
  const [modalLoading, setModalLoading] = useState(false);
  const [printModalVisible, setPrintModalVisible] = useState(false);
  const [morePopoverVis, setMorePopoverVis] = useState(false);
  const [printoutData, setPrintoutData] = useState<PrintType | undefined>(
    undefined
  );

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  const getPrintoutContents = () => {
    setModalLoading(true);
    getDataWithAuthToken('claim/print', {
      params: {
        claimIds: [orderClaimDetail.id].toString(),
      },
    }).then((response) => {
      if (response && response.goodStatus) {
        let responseData: OrderClaimPrintoutData[] = response.data.list;
        if (responseData.length > 0) {
          setPrintoutData({ orderClaimData: responseData[0] });
          setModalLoading(false);
          setPrintModalVisible(true);
        } else {
          setModalLoading(false);
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      } else {
        setModalLoading(false);
        alertMessage(
          'error',
          response?.msg || t('general.noResponse'),
          response?.data || undefined
        );
      }
    });
  };

  const handleClaim = (type: 'trash' | 'restore' | 'delete') => {
    postDataWithAuthToken(`claim/${type}`, {
      claimId: orderClaimDetail.id,
    })
      .then((response) => {
        if (response && response.goodStatus) {
          const message =
            type === 'trash'
              ? t('order.alerts.orderClaimArchived')
              : type === 'restore'
              ? t('order.alerts.orderClaimRestored')
              : t('order.alerts.orderClaimDeleted');
          alertMessage('success', message);

          if (type === 'delete') {
            // close tab
            removeTab(
              `${dashboardRoute.order.orderClaimDetail}?claim_id=${orderClaimDetail.id}`
            );
          } else {
            onRefresh();
          }
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleUseClaim = (isUsed: boolean) => {
    postDataWithAuthToken(`claim/batch/edit`, {
      claimIds: [orderClaimDetail.id],
      isUsed: isUsed,
    })
      .then((response) => {
        if (response && response.goodStatus) {
          alertMessage('success', t('order.alerts.orderClaimUpdateUseStatus'));
          onRefresh();
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Form layout="vertical">
        <Row gutter={[16, 24]}>
          <Col
            span={16}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {
              <Typography.Text strong style={{ fontSize: 16 }}>
                {t('order.claimListColumns.overviewTitle')}
              </Typography.Text>
            }
          </Col>
          <Col span={8}>
            <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                type="primary"
                onClick={() => {
                  getPrintoutContents();
                }}
              >
                {t('actionsColumn.print')}
              </Button>
              <Dropdown
                // getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
                trigger={['click']}
                onVisibleChange={(flag) => {
                  setMorePopoverVis(flag);
                }}
                visible={morePopoverVis}
                overlay={
                  <Menu>
                    {getDashboardStyle().isWholeSaleAppType && (
                      <Menu.Item key="isUsed">
                        <Button
                          type="link"
                          style={{
                            padding: 0,
                            color: orderClaimDetail.isUsed ? BLACK : PRIMARY,
                          }}
                          onClick={() =>
                            handleUseClaim(
                              orderClaimDetail.isUsed ? false : true
                            )
                          }
                        >
                          {orderClaimDetail.isUsed
                            ? t('order.claimListColumns.setUnused')
                            : t('order.claimListColumns.setIsUsed')}
                        </Button>
                      </Menu.Item>
                    )}
                    {orderClaimDetail.isDelete && (
                      <Menu.Item key="restore">
                        <Popconfirm
                          title={t('actionsColumn.restoreWarning')}
                          onConfirm={() => {
                            handleClaim('restore');
                          }}
                          okText={t('actionsColumn.confirmation.yes')}
                          cancelText={t('actionsColumn.confirmation.no')}
                          placement="leftBottom"
                          disabled={
                            !hasPermission(
                              actionPermissions.orderGroup.claimManage
                            )
                          }
                        >
                          <Typography.Text>
                            {t('actionsColumn.restore')}
                          </Typography.Text>
                        </Popconfirm>
                      </Menu.Item>
                    )}
                    <Menu.Item key="deleteArchive">
                      <Popconfirm
                        title={
                          orderClaimDetail.isDelete
                            ? t('actionsColumn.deleteWarning')
                            : t('actionsColumn.archiveWarning')
                        }
                        onConfirm={() => {
                          handleClaim(
                            orderClaimDetail.isDelete ? 'delete' : 'trash'
                          );
                        }}
                        okText={t('actionsColumn.confirmation.yes')}
                        cancelText={t('actionsColumn.confirmation.no')}
                        placement="leftBottom"
                        disabled={
                          !hasPermission(
                            actionPermissions.orderGroup.claimManage
                          )
                        }
                      >
                        <Typography.Text type="danger">
                          {orderClaimDetail.isDelete
                            ? t('actionsColumn.delete')
                            : t('actionsColumn.archive')}
                        </Typography.Text>
                      </Popconfirm>
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button onClick={() => setMorePopoverVis(true)}>
                  {t('actionsColumn.more')}
                  <DownOutlined />
                </Button>
              </Dropdown>
            </Space>
          </Col>
          {orderClaimDetail.id && (
            <Col span={24} md={12} lg={8} xl={6}>
              {
                <Form.Item
                  style={{ marginBottom: 0 }}
                  label={`${t('order.claimListColumns.id')}: `}
                >
                  {orderClaimDetail.id}
                </Form.Item>
              }
            </Col>
          )}
          {orderClaimDetail.user && orderClaimDetail.user.userName && (
            <Col span={24} md={12} lg={8} xl={6}>
              {
                <Form.Item
                  style={{ marginBottom: 0 }}
                  label={`${t('order.claimListColumns.username')}: `}
                >
                  <Space style={{ flexDirection: 'column' }}>
                    <Button
                      type="link"
                      style={{ padding: 0 }}
                      disabled={
                        !hasPermission(actionPermissions.userGroup.userView)
                      }
                      onClick={() =>
                        addTab(
                          '',
                          `${dashboardRoute.users.detail}?user_id=${
                            orderClaimDetail.user
                              ? orderClaimDetail.user.userId
                              : ''
                          }`
                        )
                      }
                    >
                      {orderClaimDetail.user
                        ? orderClaimDetail.user.userName
                        : ''}
                    </Button>
                    {(orderClaimDetail.user?.userRank ||
                      orderClaimDetail.user?.member) && (
                      <Space>
                        {orderClaimDetail.user?.userRank && (
                          <Tag color="geekblue">
                            {orderClaimDetail.user?.userRank.rankName}
                          </Tag>
                        )}
                        {orderClaimDetail.user?.member && (
                          <Tag color="red">
                            {orderClaimDetail.user?.member.rankName}
                          </Tag>
                        )}
                      </Space>
                    )}
                  </Space>
                </Form.Item>
              }
            </Col>
          )}
          {orderClaimDetail.seller && orderClaimDetail.seller.shopName && (
            <Col span={24} md={12} lg={8} xl={6}>
              {
                <Form.Item
                  style={{ marginBottom: 0 }}
                  label={`${t('order.claimListColumns.shopName')}: `}
                >
                  <Button
                    type="link"
                    style={{ padding: 0 }}
                    onClick={() => {
                      if (
                        hasPermission(
                          actionPermissions.sellerGroup.sellerMerchants
                        )
                      )
                        addTab(
                          '',
                          `${dashboardRoute.sellers.detail}?seller_id=${orderClaimDetail.seller.sellerId}`
                        );
                    }}
                  >
                    {orderClaimDetail.seller.shopName}
                  </Button>
                </Form.Item>
              }
            </Col>
          )}
          <Col span={24} md={12} lg={8} xl={6}>
            {
              <Form.Item
                style={{ marginBottom: 0 }}
                label={`${t('order.claimListColumns.deleteStatus')}: `}
              >
                {orderClaimDetail.isDelete ? (
                  <CloseOutlined style={{ color: RED1 }} />
                ) : (
                  <CheckOutlined style={{ color: GREEN1 }} />
                )}
              </Form.Item>
            }
          </Col>
          {orderClaimDetail.adminUser &&
            orderClaimDetail.adminUser.adminUserName && (
              <Col span={24} md={12} lg={8} xl={6}>
                {
                  <Form.Item
                    style={{ marginBottom: 0 }}
                    label={`${t('order.claimListColumns.adminUserName')}: `}
                  >
                    <div style={{ wordBreak: 'break-all' }}>
                      {orderClaimDetail.adminUser.adminUserName}
                    </div>
                  </Form.Item>
                }
              </Col>
            )}
          {orderClaimDetail.claimSn && (
            <Col span={24} md={12} lg={8} xl={6}>
              {
                <Form.Item
                  style={{ marginBottom: 0 }}
                  label={`${t('order.claimListColumns.claimSn')}: `}
                >
                  <div style={{ wordBreak: 'break-all' }}>
                    {orderClaimDetail.claimSn}
                  </div>
                </Form.Item>
              }
            </Col>
          )}
          {orderClaimDetail.claimSn && (
            <Col span={24} md={12} lg={8} xl={6}>
              {
                <Form.Item
                  style={{ marginBottom: 0 }}
                  label={`${t('order.claimListColumns.claimInvoiceSn')}: `}
                >
                  <div style={{ wordBreak: 'break-all', color: PRIMARY }}>
                    {orderClaimDetail.claimInvoiceSn}
                  </div>
                </Form.Item>
              }
            </Col>
          )}
          {orderClaimDetail.claimAmount && (
            <Col span={24} md={12} lg={8} xl={6}>
              {
                <Form.Item
                  style={{ marginBottom: 0 }}
                  label={`${t('order.claimListColumns.claimAmount')}: `}
                >
                  {orderClaimDetail.claimAmount}
                </Form.Item>
              }
            </Col>
          )}
          {orderClaimDetail.finalAmount && (
            <Col span={24} md={12} lg={8} xl={6}>
              {
                <Form.Item
                  style={{ marginBottom: 0 }}
                  label={`${t('order.claimListColumns.finalAmount')}: `}
                >
                  {orderClaimDetail.finalAmount}
                </Form.Item>
              }
            </Col>
          )}
          {orderClaimDetail.claimType && (
            <Col span={24} md={12} lg={8} xl={6}>
              {
                <Form.Item
                  style={{ marginBottom: 0 }}
                  label={`${t('order.claimListColumns.claimType')}: `}
                >
                  <div style={{ wordBreak: 'break-word' }}>
                    {orderClaimDetail.claimType.description}
                  </div>
                </Form.Item>
              }
            </Col>
          )}
          {orderClaimDetail.refundMethod && (
            <Col span={24} md={12} lg={8} xl={6}>
              {
                <Form.Item
                  style={{ marginBottom: 0 }}
                  label={`${t('order.claimListColumns.refundMethod')}: `}
                >
                  <div style={{ wordBreak: 'break-word' }}>
                    {orderClaimDetail.refundMethod.description}
                  </div>
                </Form.Item>
              }
            </Col>
          )}
          {orderClaimDetail.refundTargetId > 0 && (
            <Col span={24} md={12} lg={8} xl={6}>
              {
                <Form.Item
                  style={{ marginBottom: 0 }}
                  label={`${t('order.orderDetail.refundTargetId')}: `}
                >
                  <Typography.Text>
                    {orderClaimDetail.refundTargetId}
                  </Typography.Text>
                </Form.Item>
              }
            </Col>
          )}
          {orderClaimDetail.claimReason && (
            <Col span={24} md={12} lg={8} xl={6}>
              {
                <Form.Item
                  style={{ marginBottom: 0 }}
                  label={`${t('order.claimListColumns.claimReason')}: `}
                >
                  <div style={{ wordBreak: 'break-word' }}>
                    {orderClaimDetail.claimReason}
                  </div>
                </Form.Item>
              }
            </Col>
          )}
          <Col span={24} md={12} lg={8} xl={6}>
            {
              <Form.Item
                style={{ marginBottom: 0 }}
                label={`${t('order.claimListColumns.isRead')}: `}
              >
                {orderClaimDetail.isRead ? (
                  <CheckOutlined style={{ color: GREEN1 }} />
                ) : (
                  <CloseOutlined style={{ color: RED1 }} />
                )}
              </Form.Item>
            }
          </Col>
          {orderClaimDetail.readTime && (
            <Col span={24} md={12} lg={8} xl={6}>
              {
                <Form.Item
                  style={{ marginBottom: 0 }}
                  label={`${t('order.claimListColumns.readTime')}: `}
                >
                  <div style={{ wordBreak: 'break-word' }}>
                    {orderClaimDetail.readTime}
                  </div>
                </Form.Item>
              }
            </Col>
          )}

          {getDashboardStyle().isWholeSaleAppType && (
            <>
              <Col span={24} md={12} lg={8} xl={6}>
                <Form.Item
                  style={{ marginBottom: 0 }}
                  label={`${t('order.claimListColumns.isUsedStatus')}: `}
                >
                  {orderClaimDetail.isUsed ? (
                    <CheckOutlined style={{ color: GREEN1 }} />
                  ) : (
                    <CloseOutlined style={{ color: RED1 }} />
                  )}
                </Form.Item>
              </Col>
              {orderClaimDetail.isUsed && (
                <Col span={24} md={12} lg={8} xl={6}>
                  {
                    <Form.Item
                      style={{ marginBottom: 0 }}
                      label={`${t('order.claimListColumns.usedTime')}: `}
                    >
                      {orderClaimDetail.usedTime}
                    </Form.Item>
                  }
                </Col>
              )}
            </>
          )}
          {orderClaimDetail.readTime && (
            <Col span={24} md={12} lg={8} xl={6}>
              {
                <Form.Item
                  style={{ marginBottom: 0 }}
                  label={`${t('order.claimListColumns.readTime')}: `}
                >
                  <div style={{ wordBreak: 'break-word' }}>
                    {orderClaimDetail.readTime}
                  </div>
                </Form.Item>
              }
            </Col>
          )}
          <Col span={24} md={12} lg={8} xl={6}>
            {
              <Form.Item
                style={{ marginBottom: 0 }}
                label={`${t('order.claimListColumns.addTime')}: `}
              >
                <div style={{ wordBreak: 'break-word' }}>
                  {orderClaimDetail.addTime}
                </div>
              </Form.Item>
            }
          </Col>
          {orderClaimDetail.remark && (
            <Col span={24} md={12} lg={8} xl={6}>
              {
                <Form.Item
                  style={{ marginBottom: 0 }}
                  label={`${t('order.claimListColumns.remark')}: `}
                >
                  <div style={{ wordBreak: 'break-word' }}>
                    {orderClaimDetail.remark}
                  </div>
                </Form.Item>
              }
            </Col>
          )}
          {orderClaimDetail.udeskLink && (
            <Col span={24} md={12} lg={12} xl={12}>
              {
                <Form.Item
                  style={{ marginBottom: 0 }}
                  label={`${t('order.claimListColumns.udeskLink')}: `}
                >
                  <Button
                    type="link"
                    style={{ padding: 0 }}
                    onClick={() => window.open(orderClaimDetail.udeskLink)}
                  >
                    {orderClaimDetail.udeskLink}
                  </Button>
                </Form.Item>
              }
            </Col>
          )}
        </Row>
      </Form>
      <PrintModal
        modalLoading={modalLoading}
        mode="orderClaim"
        data={printoutData}
        setData={setPrintoutData}
        visible={printModalVisible}
        setVisible={setPrintModalVisible}
      />
    </>
  );
};

export default ClaimBasicInfo;
