import { Col, Input, InputNumber, Row, Typography } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DRIVER_PRINTOUT_DATA_FONT_SIZE,
  PRINTOUT_SUBTITLE_FONT_SIZE,
} from '../../../constants/printoutConstants';
import { ExpressOrderList, SpecialSummaryList } from '../../../types';
import frozenItem from '../../../assets/images/snow_icon.png';
import nonFrozenItem from '../../../assets/images/non_frozen.png';
import specialItem from '../../../assets/images/egg.png';
import iceItem from '../../../assets/images/ice.png';
import { QRCodeSVG } from 'qrcode.react';
import getDashboardStyle from '../../../utils/getDashboardStyle';

type DriverPrintoutTableProps = {
  expressData?: ExpressOrderList[][];
  specialData?: SpecialSummaryList[][];
  totalOrder?: number;
  sumTipsFee?: number;
  totalSpecialSummary?: string;
  routeName?: string;
  routeId?: number;
};

const DriverPrintoutTable = ({
  routeName,
  routeId,
  expressData,
  specialData,
  totalOrder,
  sumTipsFee,
  totalSpecialSummary,
}: DriverPrintoutTableProps) => {
  //General Components
  const { t } = useTranslation();
  //Table Components
  const [editValidator, setEditValidator] = useState<{
    data: ExpressOrderList | SpecialSummaryList;
    rowIndex: any;
    colIndex: any;
    type: 'ExpressOrderList' | 'SpecialSummaryList';
  }>();

  const rowHeight = '64px';
  const footerHeight = '64px';

  const columnsExpress: ColumnsType<ExpressOrderList> = [
    {
      title: (
        <div
          style={{
            textAlign: 'center',
            fontSize: DRIVER_PRINTOUT_DATA_FONT_SIZE,
          }}
        >
          {t('order.driverPrintoutColumns.expressOrderList.id')}
        </div>
      ),
      key: 'sequenceLabel',
      dataIndex: 'sequenceLabel',
      width: 70,
      render: (value: string, record: ExpressOrderList, index) =>
        editValidator?.type === 'ExpressOrderList' &&
        editValidator?.rowIndex === record.orderId &&
        editValidator?.colIndex === 'sequenceLabel' ? (
          <Input
            autoFocus
            defaultValue={value}
            style={{
              fontSize: DRIVER_PRINTOUT_DATA_FONT_SIZE,
              width: '100%',
            }}
            onBlur={() => {
              setEditValidator(undefined);
            }}
            onPressEnter={(e) => {
              let target = e.target as HTMLInputElement;
              record.sequenceLabel = target.value;
              setEditValidator(undefined);
            }}
          />
        ) : (
          <div
            style={{
              height: rowHeight,
              whiteSpace: 'normal',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              wordBreak: 'break-all',
              overflow: 'hidden',
              fontSize: DRIVER_PRINTOUT_DATA_FONT_SIZE,
            }}
            onClick={() => {
              setEditValidator({
                data: record,
                rowIndex: record.orderId,
                colIndex: 'sequenceLabel',
                type: 'ExpressOrderList',
              });
            }}
          >
            {value}
          </div>
        ),
    },
    {
      title: (
        <div
          style={{
            textAlign: 'center',
            fontSize: DRIVER_PRINTOUT_DATA_FONT_SIZE,
          }}
        >
          {t('order.driverPrintoutColumns.expressOrderList.consignee')}
        </div>
      ),
      key: 'consignee',
      dataIndex: 'consignee',
      width: getDashboardStyle().isWholeSaleAppType ? 400 : 85,
      render: (value: string, record: ExpressOrderList, index) =>
        /**
         * The main difference with the extra record.key requirement is because that is the main difference
         * between a blank line and filled line from the data
         */
        record.key &&
        //Need the first Check because two tables have the same columns, prevents conflict in editable cells
        editValidator?.type === 'ExpressOrderList' &&
        editValidator?.rowIndex === record.orderId &&
        editValidator?.colIndex === 'consignee' ? (
          <Input
            autoFocus
            defaultValue={value}
            style={{
              fontSize: DRIVER_PRINTOUT_DATA_FONT_SIZE,
              width: '100%',
            }}
            onBlur={() => {
              setEditValidator(undefined);
            }}
            onPressEnter={(e) => {
              let target = e.target as HTMLInputElement;
              record.consignee = target.value;
              setEditValidator(undefined);
            }}
          />
        ) : (
          <div
            style={{
              height: rowHeight,
              display: 'flex',
              alignContent: 'center',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                textAlign: 'center',
                wordBreak: 'break-all',
                overflow: 'hidden',
                fontSize: DRIVER_PRINTOUT_DATA_FONT_SIZE,
              }}
              onClick={() => {
                setEditValidator({
                  data: record,
                  rowIndex: record.orderId,
                  colIndex: 'consignee',
                  type: 'ExpressOrderList',
                });
              }}
            >
              {value ? value : ' '}
            </div>
            <div
              style={{
                textAlign: 'center',
              }}
            >
              {record.combineCount > 0 ? record.combineOrderInfo : ''}
            </div>
          </div>
        ),
    },
    {
      title: (
        <div
          style={{
            textAlign: 'center',
            fontSize: DRIVER_PRINTOUT_DATA_FONT_SIZE,
          }}
        >
          {t('order.driverPrintoutColumns.expressOrderList.mobile')}
        </div>
      ),
      key: 'mobile',
      dataIndex: 'mobile',
      width: 110,
      render: (value: string, record: ExpressOrderList, index) =>
        /**
         * The main difference with the extra record.key requirement is because that is the main difference
         * between a blank line and filled line from the data
         */
        record.key &&
        //Need the first Check because two tables have the same columns, prevents conflict in editable cells
        editValidator?.type === 'ExpressOrderList' &&
        editValidator?.rowIndex === record.orderId &&
        editValidator?.colIndex === 'mobile' ? (
          <Input
            autoFocus
            defaultValue={value}
            style={{
              fontSize: DRIVER_PRINTOUT_DATA_FONT_SIZE,
              width: '100%',
            }}
            onBlur={() => {
              setEditValidator(undefined);
            }}
            onPressEnter={(e) => {
              let target = e.target as HTMLInputElement;
              record.mobile = target.value;
              setEditValidator(undefined);
            }}
          />
        ) : (
          <div
            style={{
              height: rowHeight,
              wordBreak: 'break-all',
              overflow: 'hidden',
              fontSize: DRIVER_PRINTOUT_DATA_FONT_SIZE,
            }}
            onClick={() => {
              setEditValidator({
                data: record,
                rowIndex: record.orderId,
                colIndex: 'mobile',
                type: 'ExpressOrderList',
              });
            }}
          >
            {value ? value : ''}
          </div>
        ),
    },
    {
      title: (
        <div style={{ fontSize: DRIVER_PRINTOUT_DATA_FONT_SIZE }}>
          {t('order.driverPrintoutColumns.expressOrderList.fullAddress')}
        </div>
      ),
      key: 'fullAddress',
      dataIndex: 'fullAddress',
      render: (value: string, record: ExpressOrderList, index) =>
        editValidator?.rowIndex === record.orderId &&
        editValidator?.colIndex === 'fullAddress' ? (
          <Input
            autoFocus
            defaultValue={value}
            style={{
              fontSize: DRIVER_PRINTOUT_DATA_FONT_SIZE,
              width: '100%',
            }}
            onBlur={() => {
              setEditValidator(undefined);
            }}
            onPressEnter={(e) => {
              let target = e.target as HTMLInputElement;
              record.fullAddress = target.value;
              setEditValidator(undefined);
            }}
          />
        ) : (
          <div
            style={{
              width: '100%',
              height: rowHeight,
              wordBreak: 'break-all',
              textAlign: 'left',
              overflow: 'hidden',
              fontSize: DRIVER_PRINTOUT_DATA_FONT_SIZE,
            }}
            onClick={() => {
              setEditValidator({
                data: record,
                rowIndex: record.orderId,
                colIndex: 'fullAddress',
                type: 'ExpressOrderList',
              });
            }}
          >
            {value ? value : ' '}
          </div>
        ),
    },
    {
      title: (
        <div
          style={{
            textAlign: 'center',
            fontSize: DRIVER_PRINTOUT_DATA_FONT_SIZE,
          }}
        >
          {t('order.driverPrintoutColumns.expressOrderList.totalAmount')}
        </div>
      ),
      key: 'totalAmount',
      dataIndex: 'totalAmount',
      width: 110,
      render: (value: string, record: ExpressOrderList, index) =>
        /**
         * The main difference with the extra record.key requirement is because that is the main difference
         * between a blank line and filled line from the data
         */
        record.key &&
        editValidator?.rowIndex === record.orderId &&
        editValidator?.colIndex === 'totalAmount' ? (
          <InputNumber<string>
            autoFocus
            step="0.01"
            defaultValue={value}
            style={{
              fontSize: DRIVER_PRINTOUT_DATA_FONT_SIZE,
              width: '100%',
            }}
            onBlur={() => {
              setEditValidator(undefined);
            }}
            onPressEnter={(e) => {
              let target = e.target as HTMLInputElement;
              record.totalAmount = target.value;
              setEditValidator(undefined);
            }}
          />
        ) : (
          <div
            style={{
              textAlign: 'center',
              height: rowHeight,
              wordBreak: 'break-all',
              overflow: 'hidden',
              fontSize: DRIVER_PRINTOUT_DATA_FONT_SIZE,
            }}
            onClick={() => {
              setEditValidator({
                data: record,
                rowIndex: record.orderId,
                colIndex: 'totalAmount',
                type: 'ExpressOrderList',
              });
            }}
          >
            {`${value ? value : ''}`}
          </div>
        ),
    },
    {
      title: (
        <div
          style={{
            textAlign: 'center',
            fontSize: DRIVER_PRINTOUT_DATA_FONT_SIZE,
          }}
        >
          {t('order.driverPrintoutColumns.expressOrderList.tipsFee')}
        </div>
      ),
      key: 'tipsFee',
      dataIndex: 'tipsFee',
      width: 60,
      render: (value: string, record: ExpressOrderList, index) =>
        /**
         * The main difference with the extra record.key requirement is because that is the main difference
         * between a blank line and filled line from the data
         */
        record.key &&
        editValidator?.rowIndex === record.orderId &&
        editValidator?.colIndex === 'tipsFee' ? (
          <InputNumber<string>
            autoFocus
            step="0.01"
            defaultValue={value}
            style={{
              fontSize: DRIVER_PRINTOUT_DATA_FONT_SIZE,
              width: '100%',
            }}
            onBlur={() => {
              setEditValidator(undefined);
            }}
            onPressEnter={(e) => {
              let target = e.target as HTMLInputElement;
              record.tipsFee = target.value;
              setEditValidator(undefined);
            }}
          />
        ) : (
          <div
            style={{
              textAlign: 'center',
              height: rowHeight,
              wordBreak: 'break-all',
              overflow: 'hidden',
              fontSize: DRIVER_PRINTOUT_DATA_FONT_SIZE,
            }}
            onClick={() => {
              setEditValidator({
                data: record,
                rowIndex: record.orderId,
                colIndex: 'tipsFee',
                type: 'ExpressOrderList',
              });
            }}
          >
            {`${value ? value : ' '}`}
          </div>
        ),
    },
    {
      title: (
        <div
          style={{
            textAlign: 'center',
            fontSize: DRIVER_PRINTOUT_DATA_FONT_SIZE,
          }}
        >
          {t('order.driverPrintoutColumns.expressOrderList.checkAmount')}
        </div>
      ),
      key: 'shippingFee',
      dataIndex: 'shippingFee',
      width: 110,
      render: (value: string, record: ExpressOrderList, index) => (
        <div
          style={{
            wordBreak: 'break-all',
            textAlign: 'center',
            overflow: 'hidden',
            height: rowHeight,
            fontSize: DRIVER_PRINTOUT_DATA_FONT_SIZE,
          }}
        >
          {``}
        </div>
      ),
    },
    {
      title: (
        <div
          style={{
            textAlign: 'center',
            fontSize: DRIVER_PRINTOUT_DATA_FONT_SIZE,
          }}
        >
          {t('order.driverPrintoutColumns.expressOrderList.sellerRemark')}
        </div>
      ),
      key: 'specialItem',
      width: 110,
      render: (record: ExpressOrderList) => (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {record.hasFrozenItem && (
            <img
              src={frozenItem}
              width={24}
              alt="frozen_item"
              style={{ margin: 5 }}
            />
          )}
          {record.hasIceItem && (
            <img
              src={iceItem}
              width={24}
              alt="ice_item"
              style={{ margin: 5 }}
            />
          )}
          {record.hasNonFrozenItem && (
            <img
              src={nonFrozenItem}
              width={24}
              alt="non_frozen_item"
              style={{ margin: 5 }}
            />
          )}
          {record.hasSpecialItem && (
            <img
              src={specialItem}
              width={24}
              alt="special_item"
              style={{ margin: 5 }}
            />
          )}
        </div>
      ),
    },
    {
      title: (
        <div
          style={{
            textAlign: 'center',
            fontSize: DRIVER_PRINTOUT_DATA_FONT_SIZE,
          }}
        >
          {t('order.driverPrintoutColumns.expressOrderList.deliveryRemark')}
        </div>
      ),
      key: 'deliveryRemark',
      dataIndex: 'deliveryRemark',
      width: 350,
      render: (value: string, record: ExpressOrderList, index) => {
        /**
         * The main difference with the extra record.key requirement is because that is the main difference
         * between a blank line and filled line from the data
         */
        // 包含 配送备注 + 客人备注
        const deliveryRemark =
          value !== undefined && value !== ''
            ? value.replaceAll('\n', ' | ')
            : '';
        const remark = record.buyerRemark
          ? deliveryRemark +
            `\n${t('order.printoutComponents.buyerRemarkTitle')}: ` +
            record.buyerRemark
          : deliveryRemark;

        return editValidator?.rowIndex === record.orderId &&
          editValidator?.colIndex === 'deliveryRemark' ? (
          <Input
            autoFocus
            step="0.01"
            defaultValue={remark}
            style={{
              fontSize: DRIVER_PRINTOUT_DATA_FONT_SIZE,
              width: '100%',
              whiteSpace: 'pre-line',
            }}
            onBlur={() => {
              setEditValidator(undefined);
            }}
            onPressEnter={(e) => {
              let target = e.target as HTMLInputElement;
              record.deliveryRemark = target.value;
              setEditValidator(undefined);
            }}
          />
        ) : (
          <div
            style={{
              lineHeight: 1.2,
              padding: 0,
              // whiteSpace: 'normal',
              width: '100%',
              textAlign: 'left',
              height: rowHeight,
              wordBreak: 'break-all',
              overflow: 'hidden',
              fontSize: DRIVER_PRINTOUT_DATA_FONT_SIZE - 5,
              whiteSpace: 'pre-line',
            }}
            onClick={() => {
              setEditValidator({
                data: record,
                rowIndex: record.orderId,
                colIndex: 'deliveryRemark',
                type: 'ExpressOrderList',
              });
            }}
          >
            {`${remark}`}
          </div>
        );
      },
    },
  ];
  const columnsSpecial: ColumnsType<SpecialSummaryList> = [
    {
      title: (
        <div
          style={{
            textAlign: 'center',
            fontSize: DRIVER_PRINTOUT_DATA_FONT_SIZE,
          }}
        >
          {t('order.driverPrintoutColumns.specialSummaryList.id')}
        </div>
      ),
      key: 'sequenceLabel',
      dataIndex: 'sequenceLabel',
      width: 20,
      render: (value: string, record: SpecialSummaryList) =>
        //Need the first Check because two tables have the same columns, prevents conflict in editable cells
        editValidator?.type === 'SpecialSummaryList' &&
        editValidator?.rowIndex === record.orderId &&
        editValidator?.colIndex === 'sequenceLabel' ? (
          <Input
            autoFocus
            defaultValue={value}
            style={{
              fontSize: DRIVER_PRINTOUT_DATA_FONT_SIZE,
              width: '100%',
            }}
            onBlur={() => {
              setEditValidator(undefined);
            }}
            onPressEnter={(e) => {
              let target = e.target as HTMLInputElement;
              record.sequenceLabel = target.value;
              setEditValidator(undefined);
            }}
          />
        ) : (
          <div
            style={{
              textAlign: 'center',
              height: rowHeight,
              wordBreak: 'break-all',
              overflow: 'hidden',
              fontSize: DRIVER_PRINTOUT_DATA_FONT_SIZE,
            }}
            onClick={() => {
              setEditValidator({
                data: record,
                rowIndex: record.orderId,
                colIndex: 'sequenceLabel',
                type: 'SpecialSummaryList',
              });
            }}
          >
            {value ? value : ' '}
          </div>
        ),
    },
    {
      title: (
        <div
          style={{
            textAlign: 'center',
            fontSize: DRIVER_PRINTOUT_DATA_FONT_SIZE,
          }}
        >
          {t('order.driverPrintoutColumns.specialSummaryList.consignee')}
        </div>
      ),
      key: 'consignee',
      dataIndex: 'consignee',
      width: 100,
      render: (value: string, record: SpecialSummaryList) =>
        /**
         * The main difference with the extra record.key requirement is because that is the main difference
         * between a blank line and filled line from the data
         */
        //Need the first Check because two tables have the same columns, prevents conflict in editable cells
        editValidator?.type === 'SpecialSummaryList' &&
        record.key &&
        editValidator?.rowIndex === record.orderId &&
        editValidator?.colIndex === 'consignee' ? (
          <Input
            autoFocus
            defaultValue={value}
            style={{
              fontSize: DRIVER_PRINTOUT_DATA_FONT_SIZE,
              width: '100%',
            }}
            onBlur={() => {
              setEditValidator(undefined);
            }}
            onPressEnter={(e) => {
              let target = e.target as HTMLInputElement;
              record.consignee = target.value;
              setEditValidator(undefined);
            }}
          />
        ) : (
          <div
            style={{
              textAlign: 'center',
              height: rowHeight,
              wordBreak: 'break-all',
              overflow: 'hidden',
              fontSize: DRIVER_PRINTOUT_DATA_FONT_SIZE,
            }}
            onClick={() => {
              setEditValidator({
                data: record,
                rowIndex: record.orderId,
                colIndex: 'consignee',
                type: 'SpecialSummaryList',
              });
            }}
          >
            {value ? value : ' '}
          </div>
        ),
    },
    {
      title: (
        <div
          style={{
            textAlign: 'center',
            fontSize: DRIVER_PRINTOUT_DATA_FONT_SIZE,
          }}
        >
          {t('order.driverPrintoutColumns.specialSummaryList.mobile')}
        </div>
      ),
      key: 'mobile',
      dataIndex: 'mobile',
      width: 120,
      render: (value: string, record: SpecialSummaryList) =>
        /**
         * The main difference with the extra record.key requirement is because that is the main difference
         * between a blank line and filled line from the data
         */
        //Need the first Check because two tables have the same columns, prevents conflict in editable cells
        editValidator?.type === 'SpecialSummaryList' &&
        record.key &&
        editValidator?.rowIndex === record.orderId &&
        editValidator?.colIndex === 'mobile' ? (
          <Input
            autoFocus
            defaultValue={value}
            style={{
              fontSize: DRIVER_PRINTOUT_DATA_FONT_SIZE,
              width: '100%',
            }}
            onBlur={() => {
              setEditValidator(undefined);
            }}
            onPressEnter={(e) => {
              let target = e.target as HTMLInputElement;
              record.mobile = target.value;
              setEditValidator(undefined);
            }}
          />
        ) : (
          <div
            style={{
              textAlign: 'left',
              height: rowHeight,
              wordBreak: 'break-all',
              overflow: 'hidden',
              fontSize: DRIVER_PRINTOUT_DATA_FONT_SIZE,
            }}
            onClick={() => {
              setEditValidator({
                data: record,
                rowIndex: record.orderId,
                colIndex: 'mobile',
                type: 'SpecialSummaryList',
              });
            }}
          >
            {value ? value : ' '}
          </div>
        ),
    },
    {
      title: (
        <div
          style={{
            textAlign: 'center',
            fontSize: DRIVER_PRINTOUT_DATA_FONT_SIZE,
          }}
        >
          {t('order.driverPrintoutColumns.specialSummaryList.remark')}
        </div>
      ),
      key: 'remark',
      dataIndex: 'remark',
      render: (value: string, record: SpecialSummaryList) =>
        //Need the first Check because two tables have the same columns, prevents conflict in editable cells
        editValidator?.type === 'SpecialSummaryList' &&
        editValidator?.rowIndex === record.orderId &&
        editValidator?.colIndex === 'remark' ? (
          <Input
            autoFocus
            defaultValue={value}
            style={{
              fontSize: DRIVER_PRINTOUT_DATA_FONT_SIZE,
              width: '100%',
            }}
            onBlur={() => {
              setEditValidator(undefined);
            }}
            onPressEnter={(e) => {
              let target = e.target as HTMLInputElement;
              record.remark = target.value;
              setEditValidator(undefined);
            }}
          />
        ) : (
          <div
            style={{
              textAlign: 'left',
              wordBreak: 'break-all',
              overflow: 'hidden',
              height: rowHeight,
              fontSize: DRIVER_PRINTOUT_DATA_FONT_SIZE,
            }}
            onClick={() => {
              setEditValidator({
                data: record,
                rowIndex: record.orderId,
                colIndex: 'remark',
                type: 'SpecialSummaryList',
              });
            }}
          >
            {value ? value : ' '}
          </div>
        ),
    },
  ];

  const filteredColumnExpress = columnsExpress.filter(
    (x) =>
      (!getDashboardStyle().isWholeSaleAppType || x.key !== 'tipsFee') && // 如果是批发不显示这些
      (!getDashboardStyle().isWholeSaleAppType || x.key !== 'shippingFee') &&
      (!getDashboardStyle().isWholeSaleAppType || x.key !== 'specialItem') &&
      (!getDashboardStyle().isWholeSaleAppType || x.key !== 'specialItem')
  );

  return (
    <>
      {expressData &&
        expressData.map((expressList, index) => {
          return (
            <div key={index} style={{ marginBottom: 5 }}>
              <div>
                <Row gutter={[8, 8]}>
                  <Col
                    span={8}
                    style={{
                      height: 80,
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <div style={{ width: '50%' }} /**Route name label & Text */>
                      <Typography.Text
                        style={{ fontSize: PRINTOUT_SUBTITLE_FONT_SIZE }}
                      >
                        {`${t('order.print.driverName')}`}
                      </Typography.Text>
                      <Typography.Text
                        style={{ fontSize: PRINTOUT_SUBTITLE_FONT_SIZE }}
                      >
                        {`${routeName ? routeName : ''}`}
                      </Typography.Text>
                    </div>
                    <div
                      style={{
                        width: '50%',
                      }} /**Route ID QR Code */
                    >
                      {routeId && (
                        <QRCodeSVG
                          value={JSON.stringify({
                            routeId: routeId,
                            taskType: 'NORMAL',
                            taskStage: 'SPECIAL_SORT',
                          })}
                          size={70}
                        />
                      )}
                    </div>
                  </Col>
                  <Col span={8}>
                    <Typography.Text
                      style={{ fontSize: PRINTOUT_SUBTITLE_FONT_SIZE }}
                    >
                      {t('order.print.driverExpressListTitle1')}
                    </Typography.Text>
                  </Col>
                  <Col span={8}>
                    <Typography.Text
                      style={{ fontSize: PRINTOUT_SUBTITLE_FONT_SIZE }}
                    >
                      {t('order.print.driverExpressListTitle2')}
                    </Typography.Text>
                  </Col>
                </Row>
              </div>
              <Table //Express Table
                className="tableDriverPrintout"
                key={index}
                dataSource={expressList}
                rowKey={(data) => data.orderId}
                columns={filteredColumnExpress}
                pagination={{ pageSize: 11, hideOnSinglePage: true }}
                size="small"
                bordered={true}
                summary={(pageData) => {
                  return (
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0} colSpan={10}>
                        <div
                          style={{
                            height: footerHeight,
                            overflow: 'hidden',
                            textAlign: 'center',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-evenly',
                          }}
                        >
                          <Typography.Text
                            style={{
                              fontSize: 17,
                            }}
                          >{`${t(
                            'order.driverPrintoutColumns.totalOrder'
                          )}: ${totalOrder}`}</Typography.Text>
                          <Typography.Text
                            style={{
                              fontSize: 17,
                            }}
                          >{`${t(
                            'order.driverPrintoutColumns.sumTipsFee'
                          )}: $${sumTipsFee}`}</Typography.Text>
                        </div>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  );
                }}
              />
              <div style={{ height: 30 }} />
            </div>
          );
        })}
        {/****  批发端不显示 特殊商品 */}
      {!getDashboardStyle().isWholeSaleAppType && specialData &&
        specialData.map((specialList, index) => {
          return (
            <div key={index} style={{ marginBottom: 5 }}>
              <div>
                <Row gutter={[8, 8]}>
                  <Col span={8}>
                    <Typography.Text
                      style={{ fontSize: PRINTOUT_SUBTITLE_FONT_SIZE }}
                    >
                      {t('order.print.driverSpecialListTitle')}
                    </Typography.Text>
                  </Col>
                </Row>
              </div>
              <Table //Express Table
                className="tableDriverPrintout"
                key={index}
                dataSource={specialList}
                rowKey={(data) => data.orderId}
                columns={columnsSpecial}
                pagination={{ pageSize: 12, hideOnSinglePage: true }}
                size="small"
                bordered={true}
                summary={(pageData) => {
                  return (
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0} colSpan={10}>
                        <div
                          style={{
                            height: footerHeight,
                            overflow: 'hidden',
                            width: '100%',
                          }}
                        >
                          <Typography.Text
                            style={{
                              fontSize: 17,
                            }}
                          >
                            {totalSpecialSummary}
                          </Typography.Text>
                        </div>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  );
                }}
              />
              <div style={{ height: 30 }} />
            </div>
          );
        })}
    </>
  );
};

export default DriverPrintoutTable;
