import { Button, Col, Form, Row, Space, Tag, Typography } from 'antd';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { actionPermissions } from '../../../constants/actionPermissions';
import { dashboardRoute } from '../../../constants/pathname';
import { useTab } from '../../../hooks/useTab';
import { OrderClaimData } from '../../../types';
import { hasPermission } from '../../../utils/hasPermission';
import { PRIMARY } from '../../../constants/color';

type ClaimOrderInfoProps = {
  orderClaimDetail: OrderClaimData;
};

/**
 * Displays Order Information
 *
 */
const ClaimOrderInfo = ({ orderClaimDetail }: ClaimOrderInfoProps) => {
  const { t } = useTranslation();
  const isSubscribed = useRef(true);
  const { addTab } = useTab();

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  return (
    <Form layout="vertical">
      <Row gutter={[16, 24]}>
        <Col span={24}>
          {
            <Typography.Text strong style={{ fontSize: 16 }}>
              {t('order.claimListColumns.orderInfoTitle')}
            </Typography.Text>
          }
        </Col>
        {orderClaimDetail.orderInfo && (
          <>
            <Col span={24} md={12} lg={8} xl={6}>
              {
                <Form.Item
                  style={{ marginBottom: 0 }}
                  label={`${t('order.claimListColumns.orderId')}: `}
                >
                  <Button
                    type="link"
                    style={{ padding: 0 }}
                    disabled={
                      !hasPermission(actionPermissions.orderGroup.orderView)
                    }
                    onClick={() => {
                      addTab(
                        '',
                        `${dashboardRoute.order.detail}?order_id=${orderClaimDetail.orderInfo.orderId}`
                      );
                    }}
                  >
                    {orderClaimDetail.orderInfo.orderId}
                  </Button>
                </Form.Item>
              }
            </Col>
            <Col span={24} md={12} lg={8} xl={6}>
              {
                <Form.Item
                  style={{ marginBottom: 0 }}
                  label={`${t('order.claimListColumns.payName')}: `}
                >
                  <div
                    style={{
                      wordBreak: 'break-word',
                    }}
                  >
                    {orderClaimDetail.orderInfo.payName}
                  </div>
                </Form.Item>
              }
            </Col>
            <Col span={24} md={12} lg={8} xl={6}>
              {
                <Form.Item
                  style={{ marginBottom: 0 }}
                  label={`${t('order.claimListColumns.orderSn')}: `}
                >
                  <div
                    style={{
                      wordBreak: 'break-word',
                    }}
                  >
                    {orderClaimDetail.orderInfo.orderSn}
                  </div>
                </Form.Item>
              }
            </Col>
            <Col span={24} md={12} lg={8} xl={6}>
              <Form.Item
                style={{ marginBottom: 0 }}
                label={`${t('order.claimListColumns.invoiceSn')}: `}
              >
                <div style={{ wordBreak: 'break-word', color: PRIMARY }}>
                  {orderClaimDetail.orderInfo.invoiceSn}
                </div>
              </Form.Item>
            </Col>
            <Col span={24} md={12} lg={8} xl={6}>
              <Form.Item
                style={{ marginBottom: 0 }}
                label={`${t('order.claimListColumns.shippingName')}: `}
              >
                <div style={{ wordBreak: 'break-word' }}>
                  {orderClaimDetail.orderInfo.orderShipping.shippingName}
                </div>
              </Form.Item>
            </Col>
            <Col span={24} md={12} lg={8} xl={6}>
              <Form.Item
                style={{ marginBottom: 0 }}
                label={`${t('order.claimListColumns.expectShippingTimeName')}: `}
              >
                <div style={{ wordBreak: 'break-word' }}>
                  {orderClaimDetail.orderInfo.orderShipping.expectShippingTimeName}
                </div>
              </Form.Item>
            </Col>
            <Col span={24} md={12} lg={8} xl={6}>
              {
                <Form.Item
                  style={{ marginBottom: 0 }}
                  label={`${t('order.claimListColumns.totalAmount')}: `}
                >
                  <div style={{ wordBreak: 'break-word' }}>
                    {orderClaimDetail.orderInfo.orderAmount.totalAmount}
                  </div>
                </Form.Item>
              }
            </Col>
            <Col span={24} md={12} lg={8} xl={6}>
              <Form.Item
                style={{ marginBottom: 0 }}
                label={`${t('order.claimListColumns.orderStatus')} | ${t(
                  'order.claimListColumns.shippingStatus'
                )} | ${t('order.claimListColumns.payStatus')} : `}
              >
                <Space>
                  {orderClaimDetail.orderInfo.orderStatus && (
                    <Tag
                      color={orderClaimDetail.orderInfo.orderStatus.tagColor}
                    >
                      {orderClaimDetail.orderInfo.orderStatus.description}
                    </Tag>
                  )}
                  {orderClaimDetail.orderInfo.shippingStatus && (
                    <Tag
                      color={orderClaimDetail.orderInfo.shippingStatus.tagColor}
                    >
                      {orderClaimDetail.orderInfo.shippingStatus.description}
                    </Tag>
                  )}
                  {orderClaimDetail.orderInfo.payStatus && (
                    <Tag color={orderClaimDetail.orderInfo.payStatus.tagColor}>
                      {orderClaimDetail.orderInfo.payStatus.description}
                    </Tag>
                  )}
                </Space>
              </Form.Item>
            </Col>
            <Col span={24} md={12} lg={8} xl={6}>
              {
                <Form.Item
                  style={{ marginBottom: 0 }}
                  label={`${t('order.claimListColumns.addTime')}: `}
                >
                  <div
                    style={{
                      wordBreak: 'break-word',
                    }}
                  >
                    {orderClaimDetail.orderInfo.addTime}
                  </div>
                </Form.Item>
              }
            </Col>
          </>
        )}
      </Row>
    </Form>
  );
};

export default ClaimOrderInfo;
