import {
  CheckOutlined,
  CloseOutlined,
  DownOutlined,
  FilterOutlined,
} from '@ant-design/icons';
import {
  Badge,
  Button,
  Col,
  DatePicker,
  Drawer,
  Dropdown,
  Form,
  Grid,
  Input,
  InputNumber,
  Menu,
  Popconfirm,
  Popover,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Tabs,
  Tag,
  Typography,
} from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ColumnsType } from 'antd/lib/table/interface';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useVT } from 'virtualizedtableforantd4';
import { actionPermissions } from '../../../constants/actionPermissions';
import { dashboardRoute } from '../../../constants/pathname';
import {
  DATE_FORMAT,
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
  EXTENDED_TIMEOUT,
  GENERAL_TIMEOUT,
} from '../../../constants/systemConstants';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { useTab } from '../../../hooks/useTab';
import {
  ClaimGoodLists,
  FontSizeType,
  OrderClaimData,
  OrderClaimPrintoutData,
  OrderEnum,
  PrintType,
} from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../../utils/axiosRequest';
import { compare, setFont } from '../../../utils/colComponents';
import getDashboardStyle from '../../../utils/getDashboardStyle';
import { hasPermission } from '../../../utils/hasPermission';
import FiveHundred from '../../FiveHundred';
import FourZeroThree from '../../FourZeroThree';
import PrintModal from '../../PrintModal';
import ClaimGoodsList from '../../order/orderClaimDetail/ClaimGoodsList';
import SellersDropdown from '../../sellers/SellersDropdown';
import TableFooterToolbar from '../../table/TableFooterToolbar';
import TableToolbar from '../../table/TableToolbar';
import UsersDropdown from '../common/UsersDropdown';
import AdminDropdown from '../../admin/AdminDropdown';
import SellerAdminDropdown from '../../sellers/SellerAdminDropdown';
import {
  mergeParamsToString,
  tableScrollToTop,
} from '../../../utils/helperFunction';
import { BLACK, GREEN1, PRIMARY, RED1 } from '../../../constants/color';

type OrderClaimTableProps = {
  id?: string;
  simplified?: {
    simplifiedForm: boolean;
    orderClaimList: OrderClaimData[];
    loading: boolean;
    callBack: Function;
  };
};
const OrderClaimTable = ({ id, simplified }: OrderClaimTableProps) => {
  //General Components
  const { TabPane } = Tabs;
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const { t } = useTranslation();
  const isSubscribed = useRef(true);
  const [fourZeroThree, setFourZeroThree] = useState(false);
  const [fiveHundred, setFiveHundred] = useState<boolean>(false);
  const screens = Grid.useBreakpoint();
  const [isLoading, setIsLoading] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);
  const [isClaimGoodsListLoading, setIsClaimGoodsListLoading] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [morePopoverVis, setMorePopoverVis] = useState<{
    [key: number]: boolean;
  }>({});
  const [isSeller, setIsSeller] = useState(false);
  const [claimNav, setClaimNav] = useState('ALL');
  const [keyword, setKeyword] = useState('');
  const [advance, setAdvance] = useState<{
    [key: string]: any;
  }>({});
  const { addTab } = useTab();
  const { RangePicker } = DatePicker;
  const [printModalVisible, setPrintModalVisible] = useState(false);
  const [printoutData, setPrintoutData] = useState<PrintType | undefined>(
    undefined
  );
  //Data Components
  const [claimData, setClaimData] = useState<OrderClaimData[]>([]);
  const [claimEnum, setClaimEnum] = useState<OrderEnum>();
  const [claimGoodsList, setClaimGoodsList] = useState<ClaimGoodLists[]>([]);
  //Text Components
  const ellipsis = useState(true);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  //Table Components

  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useLocalStorage('orderPageSize', '20');
  const [total, setTotal] = useState(0);
  const columnKeys = [
    'id',
    'claimSn|claimInvoiceSn|username|shopName',
    'orderSn|invoiceSn|shippingName|expectShippingTimeName',
    'totalAmount|claimAmount|finalAmount',
    'claimType|claimReason|refundMethod',
    'reviewStatus|reviewContent|reviewTime|remark',
    'addTime',
    'isDelete|isUsed',
    'action',
  ];

  // Initialize selectedColumns based on columnKeys
  const [selectedColumns, setSelectedColumns] = useState(
    columnKeys.filter((col: string) => col !== 'id' && col !== 'addTime')
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<OrderClaimData[]>([]);
  // Generate export config url
  const getExportUrl = (params: { [key: string]: any }) => {
    return mergeParamsToString('claim/export/excel?', params);
  };
  // Get Params from form (get/export list params)
  const getFormParams = useCallback(() => {
    return {
      claimNav: claimNav,
      keyword: keyword ? keyword : undefined,
      isSeller: isSeller,
      sellerId:
        formRef.current && form.getFieldValue('sellerId')
          ? form.getFieldValue('sellerId')
          : undefined,
      page: page,
      size: pageSize,
      userId: id
        ? id
        : formRef.current && form.getFieldValue('userId')
        ? form.getFieldValue('userId')
        : undefined,
      adminId:
        formRef.current && form.getFieldValue('adminId')
          ? form.getFieldValue('adminId')
          : undefined,
      reviewAdminId:
        formRef.current && form.getFieldValue('reviewAdminId')
          ? form.getFieldValue('reviewAdminId')
          : undefined,
      claimSn:
        formRef.current && form.getFieldValue('claimSn')
          ? form.getFieldValue('claimSn')
          : undefined,
      claimId:
        formRef.current && form.getFieldValue('claimId')
          ? form.getFieldValue('claimId')
          : undefined,
      orderSn:
        formRef.current && form.getFieldValue('orderSn')
          ? form.getFieldValue('orderSn')
          : undefined,
      reviewStatus:
        formRef.current && form.getFieldValue('status')
          ? form.getFieldValue('status') === 'all'
            ? undefined
            : form.getFieldValue('status')
          : undefined,
      // 订单配送时间
      startExpectShippingTime:
        formRef.current && form.getFieldValue('expectShippingTimeInterval')
          ? moment(form.getFieldValue('expectShippingTimeInterval')[0]).format(
              DATE_FORMAT
            )
          : undefined,
      endExpectShippingTime:
        formRef.current && form.getFieldValue('expectShippingTimeInterval')
          ? moment(form.getFieldValue('expectShippingTimeInterval')[1]).format(
              DATE_FORMAT
            )
          : undefined,
      startAddTime:
        formRef.current && form.getFieldValue('byAddTime')
          ? moment(form.getFieldValue('byAddTime')[0]).format(DATE_FORMAT)
          : undefined,
      endAddTime:
        formRef.current && form.getFieldValue('byAddTime')
          ? moment(form.getFieldValue('byAddTime')[1]).format(DATE_FORMAT)
          : undefined,

      startReviewTime:
        formRef.current && form.getFieldValue('byReviewTime')
          ? moment(form.getFieldValue('byReviewTime')[0]).format(DATE_FORMAT)
          : undefined,
      endReviewTime:
        formRef.current && form.getFieldValue('byReviewTime')
          ? moment(form.getFieldValue('byReviewTime')[1]).format(DATE_FORMAT)
          : undefined,
      startAmount:
        formRef.current && form.getFieldValue('startAmount')
          ? form.getFieldValue('startAmount')
          : undefined,
      endAmount:
        formRef.current && form.getFieldValue('endAmount')
          ? form.getFieldValue('endAmount')
          : undefined,
      claimType:
        formRef.current && form.getFieldValue('claimType')
          ? form.getFieldValue('claimType') === 'all'
            ? undefined
            : form.getFieldValue('claimType')
          : undefined,
      refundMethod:
        formRef.current && form.getFieldValue('refundMethod')
          ? form.getFieldValue('refundMethod') === 'all'
            ? undefined
            : form.getFieldValue('refundMethod')
          : undefined,
      isUsed:
        formRef.current && typeof form.getFieldValue('isUsed') === 'boolean'
          ? form.getFieldValue('isUsed')
          : undefined,
    };
  }, [id, page, pageSize, form, isSeller, claimNav, keyword]);

  //Makes a request and set the claim list data
  const getOrderClaimData = useCallback(() => {
    setIsLoading(true);
    getDataWithAuthToken('claim/list', {
      params: getFormParams(),
    })
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            if (isSubscribed.current) {
              setClaimData(response.data.list);
              setTotal(response.data.totalItem);
              setSelectedRowKeys([]);
              // Scroll to top when data changes
              tableScrollToTop();
            }
          } else if (response.returnCode === 403) {
            if (isSubscribed.current) setFourZeroThree(true);
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        } else isSubscribed.current && setFiveHundred(true);
        if (isSubscribed.current) setIsLoading(false);
      })
      .catch((err) => {
        if (isSubscribed.current) setIsLoading(false);
        console.log(err);
      });
  }, [t, getFormParams]);

  //Makes a request and set the  enum list
  const getEnumList = useCallback(() => {
    getDataWithAuthToken('order/enum_list')
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            if (isSubscribed.current) {
              setClaimEnum(response.data);
            }
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [t]);

  //Makes a request and get claim printout list
  const getPrintoutContents = () => {
    setModalLoading(true);
    getDataWithAuthToken('claim/print', {
      params: {
        claimIds: selectedRowKeys.toString(),
      },
    }).then((response) => {
      if (response && response.goodStatus) {
        let responseData: OrderClaimPrintoutData[] = response.data.list;
        if (responseData.length > 0) {
          setPrintoutData({ orderClaimsData: responseData });
          setPrintModalVisible(true);
          setModalLoading(false);
        } else {
          setModalLoading(false);
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      } else {
        setModalLoading(false);
        alertMessage(
          'error',
          response?.msg || t('general.noResponse'),
          response?.data || undefined
        );
      }
    });
  };

  const getClaimGoodsList = async (claimId: number) => {
    if (isSubscribed.current) setIsClaimGoodsListLoading(true);
    try {
      const response = await getDataWithAuthToken('claim/goods_list', {
        params: {
          claimId: claimId,
        },
      });
      if (isSubscribed.current) setIsClaimGoodsListLoading(false);
      if (response && response.goodStatus) {
        setClaimGoodsList(response.data);
      } else {
        alertMessage(
          'error',
          response?.msg || t('general.noResponse'),
          response?.data || undefined
        );
      }
    } catch (err) {
      if (isSubscribed.current) setIsClaimGoodsListLoading(false);
      console.log(err);
      alertMessage('error', t('general.noResponse'));
    }
  };

  const handleClaim = (
    type: 'trash' | 'restore' | 'delete',
    claimId: number
  ) => {
    if (isSubscribed.current) setIsLoading(true);
    postDataWithAuthToken(`claim/${type}`, {
      claimId: claimId,
    })
      .then((response) => {
        if (response && response.goodStatus) {
          const message =
            type === 'trash'
              ? t('order.alerts.orderClaimArchived')
              : type === 'restore'
              ? t('order.alerts.orderClaimRestored')
              : t('order.alerts.orderClaimDeleted');
          alertMessage('success', message);

          simplified && simplified.callBack
            ? simplified.callBack()
            : getOrderClaimData();
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setMorePopoverVis({ [claimId]: false });
        if (isSubscribed.current) setIsLoading(false);
      });
  };

  const handleUseClaim = (isUsed: boolean, claimIds?: number[]) => {
    if (isSubscribed.current) setIsLoading(true);
    postDataWithAuthToken(`claim/batch/edit`, {
      claimIds: claimIds,
      isUsed: isUsed,
    })
      .then((response) => {
        if (response && response.goodStatus) {
          alertMessage('success', t('order.alerts.orderClaimUpdateUseStatus'));
          simplified && simplified.callBack
            ? simplified.callBack()
            : getOrderClaimData();
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const columns: ColumnsType<OrderClaimData> = [
    {
      title: setFont(t('order.claimListColumns.id'), fontSize),
      key: 'id',
      dataIndex: 'id',
      fixed: screens.lg ? 'left' : undefined,
      width: 70,
      sorter: (a: OrderClaimData, b: OrderClaimData) => compare(a.id, b.id),
      render: (text: string, record: OrderClaimData) => (
        <Button
          type="link"
          style={{ padding: 0, fontSize: fontSize }}
          onClick={() =>
            addTab(
              '',
              `${dashboardRoute.order.orderClaimDetail}?claim_id=${record.id}`
            )
          }
        >
          {record.id}
        </Button>
      ),
    },
    {
      title: setFont(
        `${t('order.claimListColumns.claimInfoColumn')}`,
        fontSize,
        `${t('order.claimListColumns.claimSn')}|${t(
          'order.claimListColumns.claimInvoiceSn'
        )}|${t('order.claimListColumns.username')}|${t(
          'order.claimListColumns.shopName'
        )}`
      ),
      key: 'claimSn|claimInvoiceSn|username|shopName',
      dataIndex: 'claimInfo',
      width: 250,
      render: (text: string, record: OrderClaimData) => (
        <Space direction="vertical" size={0}>
          <Popover
            mouseEnterDelay={0.5}
            overlayInnerStyle={{
              width: screens.lg ? 800 : screens.md ? 'auto' : undefined,
            }}
            placement="right"
            onVisibleChange={(visible: boolean) => {
              if (
                visible &&
                hasPermission(actionPermissions.orderGroup.orderClaimView)
              ) {
                getClaimGoodsList(record.id);
              } else {
                setClaimGoodsList([]);
              }
            }}
            content={
              <ClaimGoodsList
                claimGoodsList={claimGoodsList}
                loading={isClaimGoodsListLoading}
              />
            }
          >
            <Button
              disabled={!hasPermission(actionPermissions.orderGroup.orderView)}
              onClick={() => {
                addTab(
                  '',
                  `${dashboardRoute.order.orderClaimDetail}?claim_id=${record.id}`
                );
              }}
              type="link"
              style={{ padding: 0, fontSize: fontSize }}
            >
              <Typography.Text
                style={
                  ellipsis
                    ? { width: 180, textAlign: 'left', color: PRIMARY }
                    : undefined
                }
                ellipsis={ellipsis ? { tooltip: record.claimSn } : false}
              >
                {record.claimSn}
              </Typography.Text>
            </Button>
          </Popover>
          <Typography.Text
            style={ellipsis ? { width: 180, textAlign: 'left' } : undefined}
            ellipsis={ellipsis ? { tooltip: record.claimInvoiceSn } : false}
          >
            {`#${record.claimInvoiceSn}`}
          </Typography.Text>

          {record.user && (
            <Button
              type="link"
              style={{ padding: 0, fontSize: fontSize }}
              disabled={!hasPermission(actionPermissions.userGroup.userView)}
              onClick={() =>
                addTab(
                  '',
                  `${dashboardRoute.users.detail}?user_id=${record.user.userId}`
                )
              }
            >
              {record.user.userName}
            </Button>
          )}
          {record.seller && (
            <Button
              disabled={
                !hasPermission(actionPermissions.sellerGroup.sellerMerchants)
              }
              type="link"
              style={{ padding: 0, fontSize: fontSize }}
              onClick={() =>
                addTab(
                  '',
                  `${dashboardRoute.sellers.detail}?seller_id=${record.seller.sellerId}`
                )
              }
            >
              <Typography.Text
                style={ellipsis ? { width: 180, textAlign: 'left' } : undefined}
                ellipsis={
                  ellipsis ? { tooltip: record.seller.shopName } : false
                }
              >
                {record.seller.shopName}
              </Typography.Text>
            </Button>
          )}
        </Space>
      ),
    },
    {
      title: setFont(
        `${t('order.claimListColumns.orderInfoTitle')}`,
        fontSize,
        `${t('order.claimListColumns.orderSn')}|${t(
          'order.claimListColumns.invoiceSn'
        )}|${t('order.claimListColumns.shippingName')}|${t(
          'order.claimListColumns.expectShippingTimeName'
        )}`
      ),
      key: 'orderSn|invoiceSn|shippingName|expectShippingTimeName',
      dataIndex: 'orderInfoColumn',
      width: 250,
      render: (text: string, record: OrderClaimData) => (
        <Space direction="vertical">
          {record.orderInfo && (
            <>
              <Button
                onClick={() => {
                  addTab(
                    '',
                    `${dashboardRoute.order.detail}?order_id=${record.orderInfo.orderId}`
                  );
                }}
                type="link"
                disabled={
                  !hasPermission(actionPermissions.orderGroup.orderView)
                }
                style={{ padding: 0, fontSize: fontSize }}
              >
                {record.orderInfo.orderSn}
              </Button>
              <Typography.Text
                style={ellipsis ? { width: 180, textAlign: 'left' } : undefined}
                ellipsis={
                  ellipsis ? { tooltip: record.orderInfo.invoiceSn } : false
                }
              >
                {`#${record.orderInfo.invoiceSn}`}
              </Typography.Text>
              <Typography.Text
                style={{ fontSize: fontSize }}
              >{`${record.orderInfo.orderShipping.shippingName}`}</Typography.Text>
              {record.orderInfo.orderShipping.expectShippingTimeName && (
                <Tag
                  color={
                    record.orderInfo.orderShipping
                      ? record.orderInfo.orderShipping.tagColor
                      : undefined
                  }
                  style={{ fontSize: fontSize }}
                >
                  {`${
                    record.orderInfo.orderShipping
                      ? record.orderInfo.orderShipping.expectShippingTimeName
                      : undefined
                  }`}
                </Tag>
              )}
            </>
          )}
        </Space>
      ),
    },
    {
      title: setFont(
        `${t('order.claimListColumns.amountColumn')}`,
        fontSize,
        `${t('order.claimListColumns.totalAmount')}|${t(
          'order.claimListColumns.claimAmount'
        )}|${!simplified ? t('order.claimListColumns.finalAmount') : ''}`
      ),
      key: 'totalAmount|claimAmount|finalAmount',
      dataIndex: 'amountColumn',
      width: 200,
      render: (text: string, record: OrderClaimData) => (
        <Space direction="vertical">
          <Typography.Text style={{ fontSize: fontSize }}>
            {`${t('order.claimListColumns.claimAmount')}: ${
              record.claimAmount
            }`}
          </Typography.Text>
          <Typography.Text style={{ fontSize: fontSize }}>
            {`${t('order.claimListColumns.finalAmount')}: ${
              record.finalAmount
            }`}
          </Typography.Text>
          {record.orderInfo && (
            <Typography.Text style={{ fontSize: fontSize }}>
              {`${t('order.claimListColumns.totalAmount')}: ${
                record.orderInfo.orderAmount.totalAmount
              }`}
            </Typography.Text>
          )}
        </Space>
      ),
    },
    {
      title: setFont(
        `${t('order.claimListColumns.orderClaimDetail')}`,
        fontSize,
        `${t('order.claimListColumns.claimType')}|${t(
          'order.claimListColumns.claimReason'
        )}|${t('order.claimListColumns.refundMethod')}`
      ),
      key: 'claimType|claimReason|refundMethod',
      dataIndex: 'orderClaimDetailColumn',
      width: 200,
      ellipsis: true,
      render: (text: string, record: OrderClaimData) => (
        <Space direction="vertical">
          <Typography.Text style={{ fontSize: fontSize }}>
            {`${record.claimType ? record.claimType.description : ''}`}
          </Typography.Text>
          <Typography.Text
            style={ellipsis ? { width: 250 } : undefined}
            ellipsis={
              ellipsis
                ? { tooltip: record.claimReason ? record.claimReason : '' }
                : false
            }
          >
            {`${record.claimReason ? record.claimReason : ''}`}
          </Typography.Text>
          <Typography.Text style={{ fontSize: fontSize }}>
            {record && record.refundMethod
              ? record.refundMethod.description
              : ''}
          </Typography.Text>
        </Space>
      ),
    },
    {
      title: setFont(
        `${t('order.claimListColumns.reviewDetailColumn')}`,
        fontSize,
        `${t('order.claimListColumns.reviewStatus')}|${t(
          'order.claimListColumns.reviewContent'
        )}|${t('order.claimListColumns.reviewTime')}|${t(
          'order.claimListColumns.remark'
        )}`
      ),
      key: 'reviewStatus|reviewContent|reviewTime|remark',
      dataIndex: 'reviewDetailColumn',
      width: 200,
      ellipsis: true,
      render: (text: string, record: OrderClaimData) => (
        <Space direction="vertical">
          <Typography.Text
            style={ellipsis ? { width: 195 } : undefined}
            ellipsis={
              ellipsis ? { tooltip: record.remark ? record.remark : '' } : false
            }
          >
            {`${record.remark ? record.remark : ''}`}
          </Typography.Text>
          <Tag
            style={{ fontSize: fontSize }}
            color={
              record.reviewStatus ? record.reviewStatus.tagColor : undefined
            }
          >
            {`${record.reviewStatus ? record.reviewStatus.description : ''}`}
          </Tag>
          <Typography.Text
            style={ellipsis ? { width: 150 } : undefined}
            ellipsis={
              ellipsis
                ? { tooltip: record.reviewContent ? record.reviewContent : '' }
                : false
            }
          >
            {`${record.reviewContent ? record.reviewContent : ''}`}
          </Typography.Text>
          <Typography.Text style={{ fontSize: fontSize }}>
            {`${record && record.reviewTime ? record.reviewTime : ''}`}
          </Typography.Text>
        </Space>
      ),
    },
    {
      title: setFont(t('order.claimListColumns.addTime'), fontSize),
      key: 'addTime',
      dataIndex: 'addTime',
      width: 150,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('order.claimListColumns.deleteStatus'), fontSize),
      key: 'isDelete|isUsed',
      dataIndex: 'isDeleteColumn',
      width: 150,
      fixed: screens.lg ? 'right' : undefined,
      render: (value: boolean, record: OrderClaimData) => (
        <Space direction="vertical" size={0}>
          <Space>
            <Typography.Text style={{ fontSize: fontSize }}>{`${t(
              'order.claimListColumns.deleteStatus'
            )}: `}</Typography.Text>
            {record.isDelete ? (
              <CloseOutlined style={{ color: RED1, fontSize: fontSize }} />
            ) : (
              <CheckOutlined style={{ color: GREEN1, fontSize: fontSize }} />
            )}
          </Space>

          {/***** 只有批发才使用这个 */}
          {getDashboardStyle().isWholeSaleAppType && (
            <>
              <Space>
                <Typography.Text style={{ fontSize: fontSize }}>{`${t(
                  'order.claimListColumns.isUsedStatus'
                )}: `}</Typography.Text>
                {record.isUsed ? (
                  <CheckOutlined
                    style={{ color: GREEN1, fontSize: fontSize }}
                  />
                ) : (
                  <CloseOutlined style={{ color: RED1, fontSize: fontSize }} />
                )}
              </Space>

              {record.isUsed && (
                <Space>
                  <Typography.Text
                    style={{ fontSize: fontSize }}
                  >{`${record.usedTime}`}</Typography.Text>
                </Space>
              )}
            </>
          )}
        </Space>
      ),
    },
    {
      title: setFont(t('actionsColumn.title'), fontSize),
      width: 80,
      key: 'action',
      fixed: screens.lg ? 'right' : undefined,
      render: (record: OrderClaimData) => (
        <Space>
          <Button
            disabled={
              !hasPermission(actionPermissions.orderGroup.orderClaimView)
            }
            type="link"
            style={{ padding: 0, fontSize: fontSize }}
            onClick={() =>
              addTab(
                '',
                `${dashboardRoute.order.orderClaimDetail}?claim_id=${record.id}`
              )
            }
          >
            {t('actionsColumn.view')}
          </Button>
          <Dropdown
            // getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
            trigger={['click']}
            onVisibleChange={(flag) => {
              if (morePopoverVis[record.id])
                setMorePopoverVis({ [record.id]: flag });
            }}
            visible={morePopoverVis[record.id]}
            overlay={
              <Menu>
                {getDashboardStyle().isWholeSaleAppType && !record.isDelete && (
                  <Menu.Item key="isUsed">
                    <Button
                      type="link"
                      style={{
                        padding: 0,
                        color: record.isUsed ? BLACK : PRIMARY,
                      }}
                      onClick={() =>
                        handleUseClaim(record.isUsed ? false : true, [
                          record.id,
                        ])
                      }
                    >
                      {record.isUsed
                        ? t('order.claimListColumns.setUnused')
                        : t('order.claimListColumns.setIsUsed')}
                    </Button>
                  </Menu.Item>
                )}
                {record.isDelete && (
                  <Menu.Item key="restore" style={{ fontSize: fontSize }}>
                    <Popconfirm
                      title={t('actionsColumn.restoreWarning')}
                      onConfirm={() => {
                        handleClaim('restore', record.id);
                      }}
                      okText={t('actionsColumn.confirmation.yes')}
                      cancelText={t('actionsColumn.confirmation.no')}
                      placement="leftBottom"
                      disabled={
                        !hasPermission(actionPermissions.orderGroup.claimManage)
                      }
                    >
                      <Typography.Text>
                        {t('actionsColumn.restore')}
                      </Typography.Text>
                    </Popconfirm>
                  </Menu.Item>
                )}
                <Menu.Item key="deleteArchive" style={{ fontSize: fontSize }}>
                  <Popconfirm
                    title={
                      record.isDelete
                        ? t('actionsColumn.deleteWarning')
                        : t('actionsColumn.archiveWarning')
                    }
                    onConfirm={() => {
                      handleClaim(
                        record.isDelete ? 'delete' : 'trash',
                        record.id
                      );
                    }}
                    okText={t('actionsColumn.confirmation.yes')}
                    cancelText={t('actionsColumn.confirmation.no')}
                    placement="leftBottom"
                    disabled={
                      !hasPermission(actionPermissions.orderGroup.claimManage)
                    }
                  >
                    <Typography.Text type="danger">
                      {record.isDelete
                        ? t('actionsColumn.delete')
                        : t('actionsColumn.archive')}
                    </Typography.Text>
                  </Popconfirm>
                </Menu.Item>
              </Menu>
            }
          >
            <Button
              type="link"
              style={{ padding: 0, fontSize: fontSize }}
              onClick={() => setMorePopoverVis({ [record.id]: true })}
            >
              {t('actionsColumn.more')}
              <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  useEffect(() => {
    if (!simplified) {
      getOrderClaimData();
    } else {
      setIsLoading(simplified.loading);
    }
  }, [simplified, getOrderClaimData]);

  useEffect(() => {
    !simplified && getEnumList();
  }, [simplified, getEnumList]);

  const advancedSearch = (
    <Drawer
      visible={showDrawer}
      closable={true}
      onClose={() => setShowDrawer(false)}
      placement="right"
      headerStyle={{ padding: '10px 3px 10px 3px' }}
      width={screens.sm ? undefined : 300}
      bodyStyle={{ padding: 10 }}
      title={t('order.claimListColumns.advancedSearch.search')}
    >
      <Form
        form={form}
        ref={formRef}
        layout="vertical"
        initialValues={{
          claimType: 'all',
          refundMethod: 'all',
          status: 'all',
          isUsed: 'all',
        }}
      >
        <Form.Item style={{ marginBottom: 12 }}>
          <Space>
            <Button
              htmlType="submit"
              type="primary"
              onClick={() => {
                if (page !== 1) setPage(1);
                else {
                  if (typingTimeout) clearTimeout(typingTimeout);
                  setTypingTimeout(
                    setTimeout(() => getOrderClaimData(), GENERAL_TIMEOUT)
                  );
                }
              }}
            >
              {t('order.claimListColumns.advancedSearch.search')}
            </Button>
            <Button
              disabled={Object.values(advance).every(
                (value) => typeof value !== 'number' && !value
              )}
              onClick={() => {
                form.resetFields();
                setAdvance({});
                if (page !== 1) setPage(1);
                else getOrderClaimData();
              }}
            >
              {t('order.claimListColumns.advancedSearch.reset')}
            </Button>
          </Space>
        </Form.Item>
        {isSeller && (
          <Form.Item
            name="sellerId"
            label={t('order.orderListColumns.advancedSearch.sellerId')}
          >
            <SellersDropdown
              onChange={(value: string) => {
                setAdvance((prev) => ({
                  ...prev,
                  sellerId: value,
                }));
              }}
            />
          </Form.Item>
        )}
        {!id && (
          <Form.Item
            name="userId"
            label={t('order.claimListColumns.advancedSearch.userId')}
          >
            <UsersDropdown
              onChange={(value: string) => {
                setAdvance((prev) => ({
                  ...prev,
                  userId: value,
                }));
              }}
            />
          </Form.Item>
        )}
        <Form.Item name="claimSn" label={t('order.claimListColumns.claimSn')}>
          <Input
            allowClear
            onChange={(e) => {
              setAdvance((prev) => ({
                ...prev,
                claimSn: e.target.value,
              }));
            }}
          />
        </Form.Item>
        <Form.Item name="claimId" label={t('order.claimListColumns.id')}>
          <InputNumber
            style={{ width: '100%' }}
            min={1}
            step="1"
            formatter={(value) => `${value}`.replace(/\D/g, '')} // Ensures only digits are displayed
            onChange={(value) => {
              setAdvance((prev) => ({
                ...prev,
                claimId: value,
              }));
            }}
          />
        </Form.Item>
        <Form.Item
          name="orderSn"
          label={t('order.claimListColumns.advancedSearch.orderSn')}
        >
          <Input
            allowClear
            onChange={(e) => {
              setAdvance((prev) => ({
                ...prev,
                orderSn: e.target.value,
              }));
            }}
          />
        </Form.Item>
        {!getDashboardStyle().isSellersCoAppType && (
          <Form.Item
            name="adminId"
            label={t('order.claimListColumns.advancedSearch.adminId')}
          >
            {isSeller ? (
              <SellerAdminDropdown
                onChange={(value: string) => {
                  setAdvance((prev) => ({
                    ...prev,
                    adminId: value,
                  }));
                }}
              />
            ) : (
              <AdminDropdown
                onChange={(value: string) => {
                  setAdvance((prev) => ({
                    ...prev,
                    adminId: value,
                  }));
                }}
              />
            )}
          </Form.Item>
        )}
        {!getDashboardStyle().isSellersCoAppType && (
          <Form.Item
            name="reviewAdminId"
            label={t('order.claimListColumns.advancedSearch.reviewAdminId')}
          >
            {isSeller ? (
              <SellerAdminDropdown
                onChange={(value: string) => {
                  setAdvance((prev) => ({
                    ...prev,
                    reviewAdminId: value,
                  }));
                }}
              />
            ) : (
              <AdminDropdown
                onChange={(value: string) => {
                  setAdvance((prev) => ({
                    ...prev,
                    reviewAdminId: value,
                  }));
                }}
              />
            )}
          </Form.Item>
        )}
        <Form.Item
          name="expectShippingTimeInterval"
          label={t(
            'order.orderListColumns.advancedSearch.expectShippingTimeInterval'
          )}
        >
          <RangePicker
            getPopupContainer={(triggerNode) =>
              triggerNode.parentNode as HTMLElement
            }
            style={{ width: '100%' }}
            format={DATE_FORMAT}
            onChange={(values) => {
              values
                ? setAdvance((prev) => ({
                    ...prev,
                    startExpectShippingTime: moment(values[0]).format(
                      DATE_FORMAT
                    ),
                    endExpectShippingTime: moment(values[1]).format(
                      DATE_FORMAT
                    ),
                  }))
                : setAdvance((prev) => ({
                    ...prev,
                    startExpectShippingTime: undefined,
                    endExpectShippingTime: undefined,
                  }));
            }}
            placeholder={[
              t('order.orderListColumns.advancedSearch.startDate'),
              t('order.orderListColumns.advancedSearch.endDate'),
            ]}
          />
        </Form.Item>
        <Form.Item
          name="byAddTime"
          label={t('order.claimListColumns.advancedSearch.byAddTime')}
        >
          {
            <RangePicker
              getPopupContainer={(triggerNode) =>
                triggerNode.parentNode as HTMLElement
              }
              style={{ width: '100%' }}
              format={DATE_FORMAT}
              placeholder={[
                t('order.claimListColumns.advancedSearch.startDate'),
                t('order.claimListColumns.advancedSearch.endDate'),
              ]}
              onChange={(values) => {
                values
                  ? setAdvance((prev) => ({
                      ...prev,
                      startAddTime: moment(values[0]).format(DATE_FORMAT),
                      endAddTime: moment(values[1]).format(DATE_FORMAT),
                    }))
                  : setAdvance((prev) => ({
                      ...prev,
                      startAddTime: '',
                      endAddTime: '',
                    }));
              }}
            />
          }
        </Form.Item>
        <Form.Item
          name="byReviewTime"
          label={t('order.claimListColumns.advancedSearch.byReviewTime')}
        >
          {
            <RangePicker
              getPopupContainer={(triggerNode) =>
                triggerNode.parentNode as HTMLElement
              }
              style={{ width: '100%' }}
              format={DATE_FORMAT}
              placeholder={[
                t('order.claimListColumns.advancedSearch.startDate'),
                t('order.claimListColumns.advancedSearch.endDate'),
              ]}
              onChange={(values) => {
                values
                  ? setAdvance((prev) => ({
                      ...prev,
                      startReviewTime: moment(values[0]).format(DATE_FORMAT),
                      endReviewTime: moment(values[1]).format(DATE_FORMAT),
                    }))
                  : setAdvance((prev) => ({
                      ...prev,
                      startReviewTime: '',
                      endReviewTime: '',
                    }));
              }}
            />
          }
        </Form.Item>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Form.Item
              name="startAmount"
              label={t('order.claimListColumns.advancedSearch.amountRange')}
              style={{ marginBottom: 12 }}
            >
              <InputNumber
                type="number"
                onChange={(value) => {
                  setAdvance((prev) => ({
                    ...prev,
                    startAmount: value,
                  }));
                }}
                placeholder="start"
                style={{ width: '100%' }}
                min={0}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="endAmount" label=" " style={{ marginBottom: 12 }}>
              <InputNumber
                type="number"
                onChange={(value) => {
                  setAdvance((prev) => ({
                    ...prev,
                    endAmount: value,
                  }));
                }}
                placeholder="end"
                style={{ width: '100%' }}
                min={0}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="claimType"
          label={t('order.claimListColumns.advancedSearch.claimType')}
        >
          <Select
            getPopupContainer={(triggerNode: any) => triggerNode.parentNode}
            onSelect={(value: string) => {
              setAdvance((prev) => ({
                ...prev,
                claimType: value === 'all' ? '' : value,
              }));
            }}
          >
            <Select.Option key="all" value="all">
              {t('general.all')}
            </Select.Option>
            {claimEnum && claimEnum.claimType
              ? claimEnum.claimType.map((type) => (
                  <Select.Option key={type.description} value={type.code}>
                    {type.description}
                  </Select.Option>
                ))
              : undefined}
          </Select>
        </Form.Item>
        <Form.Item
          name="refundMethod"
          label={t('order.claimListColumns.advancedSearch.refundMethod')}
        >
          <Select
            getPopupContainer={(triggerNode: any) => triggerNode.parentNode}
            onSelect={(value: string) => {
              setAdvance((prev) => ({
                ...prev,
                refundMethod: value === 'all' ? '' : value,
              }));
            }}
          >
            <Select.Option key="all" value="all">
              {t('general.all')}
            </Select.Option>
            {claimEnum && claimEnum.refundMethod
              ? claimEnum.refundMethod.map((method) => (
                  <Select.Option key={method.description} value={method.code}>
                    {method.description}
                  </Select.Option>
                ))
              : undefined}
          </Select>
        </Form.Item>
        <Form.Item
          name="status"
          label={t('order.claimListColumns.advancedSearch.status')}
        >
          <Select
            getPopupContainer={(triggerNode: any) => triggerNode.parentNode}
            onSelect={(value: string) => {
              setAdvance((prev) => ({
                ...prev,
                reviewStatus: value === 'all' ? '' : value,
              }));
            }}
          >
            <Select.Option key="all" value="all">
              {t('general.all')}
            </Select.Option>
            {claimEnum && claimEnum.reviewStatus
              ? claimEnum.reviewStatus.map((status) => (
                  <Select.Option key={status.description} value={status.code}>
                    {status.description}
                  </Select.Option>
                ))
              : undefined}
          </Select>
        </Form.Item>
        <Form.Item
          name="isUsed"
          label={t('order.claimListColumns.isUsedStatus')}
        >
          <Select
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            onChange={(value: string) => {
              setAdvance((prev) => ({
                ...prev,
                isUsed: value === 'all' ? '' : value.toString(),
              }));
            }}
            filterOption={false}
            optionLabelProp="key"
            placeholder={`${t('general.pleaseSelect')}`}
          >
            <Select.Option key={`${t('general.all')}`} value="all">
              {t('general.all')}
            </Select.Option>
            <Select.Option
              key={`${t('general.booleanToStatus.true')}`}
              value={true}
            >
              {t('general.booleanToStatus.true')}
            </Select.Option>
            <Select.Option
              key={`${t('general.booleanToStatus.false')}`}
              value={false}
            >
              {t('general.booleanToStatus.false')}
            </Select.Option>
          </Select>
        </Form.Item>
        <Space>
          <Button
            htmlType="submit"
            type="primary"
            onClick={() => {
              if (page !== 1) setPage(1);
              else {
                if (typingTimeout) clearTimeout(typingTimeout);
                setTypingTimeout(
                  setTimeout(() => getOrderClaimData(), GENERAL_TIMEOUT)
                );
              }
            }}
          >
            {t('order.claimListColumns.advancedSearch.search')}
          </Button>
          <Button
            disabled={Object.values(advance).every(
              (value) => typeof value !== 'number' && !value
            )}
            onClick={() => {
              form.resetFields();
              setAdvance({});
              if (page !== 1) setPage(1);
              else getOrderClaimData();
            }}
          >
            {t('order.claimListColumns.advancedSearch.reset')}
          </Button>
        </Space>
      </Form>
    </Drawer>
  );

  const selectedRowData = claimData.map((orderClaim) => ({
    ...orderClaim,
    claimSn: orderClaim ? orderClaim.claimSn : '',
    claimInvoiceSn: orderClaim ? orderClaim.claimInvoiceSn : '',
    shopName:
      orderClaim.seller && orderClaim.seller.shopName
        ? orderClaim.seller.shopName
        : '',
    username:
      orderClaim.user && orderClaim.user.userName
        ? orderClaim.user.userName
        : '',
    orderSn:
      orderClaim && orderClaim.orderInfo ? orderClaim.orderInfo.orderSn : '',
    invoiceSn:
      orderClaim && orderClaim.orderInfo ? orderClaim.orderInfo.invoiceSn : '',
    shippingName: orderClaim.orderInfo?.orderShipping
      ? orderClaim.orderInfo?.orderShipping.shippingName
      : '',
    expectShippingTimeName: orderClaim.orderInfo?.orderShipping
      ? orderClaim.orderInfo?.orderShipping.expectShippingTimeName
      : '',
    totalAmount:
      orderClaim && orderClaim.orderInfo
        ? orderClaim.orderInfo.orderAmount.totalAmount
        : '',
    amount: orderClaim ? orderClaim.finalAmount : '',
    actualAmount: orderClaim ? orderClaim.finalAmount : '',
    claimType:
      orderClaim && orderClaim.claimType ? orderClaim.claimType.code : '',
    refundMethod:
      orderClaim && orderClaim.refundMethod ? orderClaim.refundMethod.code : '',
    claimReason:
      orderClaim && orderClaim.claimReason ? orderClaim.claimReason : '',
    reviewStatus:
      orderClaim && orderClaim.reviewStatus.code
        ? orderClaim.reviewStatus.code
        : '',
    reviewContent:
      orderClaim && orderClaim.reviewContent ? orderClaim.reviewContent : '',
    reviewTime:
      orderClaim && orderClaim.reviewTime ? orderClaim.reviewTime : '',
    remark: orderClaim && orderClaim.remark ? orderClaim.remark : '',
  }));

  return fourZeroThree ? (
    <FourZeroThree />
  ) : fiveHundred ? (
    <FiveHundred />
  ) : (
    <>
      {simplified && (
        <Space size="middle" style={{ marginBottom: 24 }}>
          <Typography.Text
            strong
            style={{
              fontSize: 16,
            }}
          >
            {t('order.orderClaimList')}
          </Typography.Text>
          {selectedRows && !!selectedRows.length && (
            <Space>
              <Button
                disabled={
                  !hasPermission(actionPermissions.orderGroup.orderClaimView)
                }
                type="primary"
                size="small"
                onClick={() => {
                  getPrintoutContents();
                }}
              >
                {t('actionsColumn.print')}
              </Button>
              {getDashboardStyle().isWholeSaleAppType && (
                <Dropdown
                  trigger={['click']}
                  overlay={
                    <Menu>
                      <Menu.Item>
                        <Button
                          disabled={
                            !hasPermission(
                              actionPermissions.orderGroup.orderClaimView
                            )
                          }
                          size="small"
                          type="link"
                          onClick={() => {
                            handleUseClaim(true, selectedRowKeys as number[]);
                          }}
                        >
                          {t('order.claimListColumns.setIsUsed')}
                        </Button>
                      </Menu.Item>
                      <Menu.Item>
                        <Button
                          disabled={
                            !hasPermission(
                              actionPermissions.orderGroup.orderClaimView
                            )
                          }
                          size="small"
                          type="link"
                          style={{ color: BLACK }}
                          onClick={() => {
                            handleUseClaim(false, selectedRowKeys as number[]);
                          }}
                        >
                          {t('order.claimListColumns.setUnused')}
                        </Button>
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Button
                    disabled={
                      !hasPermission(
                        actionPermissions.orderGroup.orderClaimView
                      )
                    }
                    size="small"
                  >
                    {t('order.claimListColumns.isUsedStatus')}
                  </Button>
                </Dropdown>
              )}
            </Space>
          )}
        </Space>
      )}
      {!simplified && (
        <>
          <Space>
            {id ? (
              <Typography.Title level={4} style={{ marginBottom: 12 }}>
                {t('users.userDetailPage.orderClaimList')}
              </Typography.Title>
            ) : (
              <Typography.Title level={3} style={{ fontWeight: 500 }}>
                {t('order.orderClaimList')}
              </Typography.Title>
            )}
            {getDashboardStyle().isSellerSwitch && (
              <Switch
                loading={isLoading}
                onChange={(checked) => {
                  if (typingTimeout) clearTimeout(typingTimeout);
                  setTypingTimeout(
                    setTimeout(() => {
                      setIsSeller(checked);
                      setPage(1);
                    }, EXTENDED_TIMEOUT)
                  );
                  // remove adminId & reviewAdminId
                  formRef.current && form.resetFields(['adminId']);
                  formRef.current && form.resetFields(['reviewAdminId']);
                  setAdvance((prev: any) => ({
                    ...prev,
                    adminId: undefined,
                    reviewAdminId: undefined,
                  }));
                }}
                style={{ marginBottom: 12 }}
                checkedChildren={t('goods.goodsListColumns.seller')}
                unCheckedChildren={t('goods.goodsListColumns.self')}
              />
            )}
          </Space>
          <Tabs
            onTabClick={(key) => {
              if (key !== claimNav) {
                setSelectedRowKeys([]);
                setSelectedRows([]);
                setClaimNav(key);
                setPage(1);
              }
            }}
          >
            {claimEnum &&
              claimEnum['claimNav'].length &&
              claimEnum['claimNav'].map((type) => (
                <TabPane key={type.code} tab={type.description} />
              ))}
          </Tabs>
          <TableToolbar
            setFontSize={setFontSize}
            fontSize={fontSize}
            tableSize={tableSize}
            setTableSize={setTableSize}
            refresh={() => getOrderClaimData()}
            totalItems={total}
            columns={columns}
            columnKeys={columnKeys}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            rows={selectedRowData}
            exportConfig={{
              fileName: 'ORDER_CLAIM_LIST',
              url: getExportUrl(getFormParams()),
            }}
            search={(keyword) => {
              setKeyword(keyword);
              setPage(1);
            }}
            searchPlaceholder={t('searchPlaceholders.searchOrderClaim')}
            advancedSearch={
              <Badge
                count={Object.keys(advance).reduce((accumulator, obj) => {
                  if (advance[obj as keyof typeof advance]) {
                    return accumulator + 1;
                  }

                  return accumulator;
                }, 0)}
              >
                <Button
                  icon={<FilterOutlined />}
                  onClick={() => setShowDrawer(showDrawer ? false : true)}
                >
                  {t('order.claimListColumns.advancedSearch.title')}
                </Button>
                {advancedSearch}
              </Badge>
            }
          />
        </>
      )}
      <Table<OrderClaimData>
        dataSource={
          simplified && simplified.simplifiedForm
            ? simplified.orderClaimList
            : claimData
        }
        rowKey={(orderClaim) => orderClaim.id}
        columns={
          simplified
            ? [...columns.slice(1, 2), ...columns.slice(3)].filter((x) =>
                selectedColumns.includes(x.key?.toString() ?? '')
              )
            : columns.filter((x) =>
                selectedColumns.includes(x.key?.toString() ?? '')
              )
        }
        components={!simplified ? vt : undefined}
        scroll={{
          y: 600,
          x: 1200,
        }}
        size={!simplified ? tableSize : 'small'}
        loading={isLoading}
        pagination={
          !simplified && {
            total: total,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (total, range) =>
              t('general.paginationTotal', {
                start: range[0],
                end: range[1],
                total: total,
              }),
            size: 'small',
            defaultPageSize: pageSize,
            onChange: (page, pSize) => {
              setPage(page);
              setPageSize(pSize || pageSize);
            },
            current: page,
            pageSizeOptions: ['10', '25', '50', '100', '150', '200'],
          }
        }
        rowSelection={{
          selectedRowKeys,
          onChange: (
            selectedRowKeys: React.Key[],
            selectedRows: OrderClaimData[]
          ) => {
            setSelectedRowKeys(selectedRowKeys);
            setSelectedRows(selectedRows);
          },
        }}
      />
      {!simplified && !!selectedRowKeys.length && (
        <TableFooterToolbar
          funct={{
            refresh: () => getOrderClaimData(),
            exportConfig: { fileName: 'ORDER_CLAIM_LIST' },
          }}
          selectedRows={selectedRowData}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          columns={columns.filter((x) =>
            selectedColumns.includes(x.key?.toString() ?? '')
          )}
          general={
            hasPermission(actionPermissions.orderGroup.orderClaimView)
              ? { advance: advance, setAdvance: setAdvance }
              : undefined
          }
          typeSpecificActions="orderClaim"
        />
      )}
      <PrintModal
        modalLoading={modalLoading}
        mode="orderClaim"
        data={printoutData}
        setData={setPrintoutData}
        visible={printModalVisible}
        setVisible={setPrintModalVisible}
      />
    </>
  );
};

export default OrderClaimTable;
